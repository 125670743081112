import React, { useState } from "react";

import { Modal, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { createUser } from "../../helpers/api";

import BeatLoader from "react-spinners/BeatLoader";

const NewUser = ({ loadUsers, clientId, clientName }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [role, setRole] = useState("OPERATOR");

  const [step, setStep] = useState("FORM");
  const [qr, setQr] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = async (evt, values) => {
    setLoading(true);
    setError("");

    console.log(values);
    console.log({
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      role: clientId ? "CLIENT" : role,
      client: clientId,
    });
    let res = await createUser({
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      role: clientId ? "CLIENT" : role,
      client: clientId,
    });

    setLoading(false);
    if (res.status === "SUCCESS") {
      setQr(res.user.qr);
      setStep("QR");

      loadUsers();
    } else {
      setError(res.error);
    }
  };

  const mOptions = [
    { label: "Administrator", value: "ADMIN" },
    { label: "Operator", value: "OPERATOR" },
    { label: "Reader", value: "READER" },
  ];

  return (
    <React.Fragment>
      <div
        className="btn btn-primary btn-sm w-md"
        onClick={() => {
          setStep("FORM");
          setQr("");
          setOpen(true);
        }}
      >
        <b>+</b> Create new User
      </div>
      <Modal isOpen={open} toggle={() => setOpen((prev) => !prev)}>
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add a user for {clientId ? clientName : "Lugh"}
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <BeatLoader
                    size={15}
                    margin={2}
                    loading={true}
                    color={"#2a3042"}
                  />
                </div>
              </div>
            )}
            {!loading && step === "FORM" && (
              <React.Fragment>
                {error !== "" ? <Alert color="danger">{error}</Alert> : null}

                <div className="form-group">
                  <AvField
                    name="firstname"
                    label="Firstname"
                    value=""
                    className="form-control"
                    placeholder="Firstname"
                    type="text"
                    required
                  />
                </div>

                <div className="form-group">
                  <AvField
                    name="lastname"
                    label="Lastname"
                    value=""
                    className="form-control"
                    placeholder="Lastname"
                    type="text"
                    required
                  />
                </div>

                <div className="form-group">
                  <AvField
                    name="email"
                    label="Email"
                    value=""
                    type="email"
                    required
                    placeholder="email@lugh.co"
                  />
                </div>

                {!clientId && (
                  <AvField
                    type="select"
                    name="role"
                    required
                    value={"OPERATOR"}
                    label="Role"
                    onChange={(event) => setRole(event.target.value)}
                  >
                    {mOptions.map((o) => {
                      return (
                        <option
                          value={o.value}
                          key={o.value}
                          selected={o.value === "OPERATOR"}
                        >
                          {o.label}
                        </option>
                      );
                    })}
                  </AvField>
                )}
              </React.Fragment>
            )}

            {!loading && step === "QR" && (
              <React.Fragment>
                <h5>
                  Scan this QR code in Google AUthenticator for 2FA
                  authentication
                </h5>
                <div
                  style={{ maxWidth: "100%" }}
                  dangerouslySetInnerHTML={{ __html: qr }}
                ></div>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>

            {step === "FORM" && (
              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
              >
                Create user
              </button>
            )}
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default NewUser;
