import React, { useState } from "react";

import { Modal, Alert, UncontrolledTooltip } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { updateUser2FA } from "../../helpers/api";

const Update2FA = ({ user }) => {
  const [open, setOpen] = useState(false);

  const [step, setStep] = useState("FORM");
  const [qr, setQr] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = async (evt, values) => {
    setError("");

    let res = await updateUser2FA(user.id);

    if (res.status === "SUCCESS") {
      setQr(res.user.qr);
      setStep("QR");
    } else {
      setError(res.error);
    }
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-lg"
        id={"project" + user.id}
        onClick={() => setOpen(true)}
      >
        <i className="bx bx-wrench"></i>
        <UncontrolledTooltip placement="top" target={"project" + user.id}>
          Reset 2FA
        </UncontrolledTooltip>
      </button>
      <Modal isOpen={open} toggle={() => setOpen((prev) => !prev)}>
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit} log>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Reset 2FA
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {step === "FORM" && (
              <React.Fragment>
                {error !== "" ? <Alert color="danger">{error}</Alert> : null}
                <h5>
                  If you click the reset button, the 2FA will be reset and the
                  old configuration will be expired.
                </h5>
                <button
                  className="btn btn-block btn-primary waves-effect waves-light"
                  type="submit"
                >
                  Confirm Reset
                </button>
              </React.Fragment>
            )}

            {step === "QR" && (
              <React.Fragment>
                <h5>
                  Scan this QR code in Google AUthenticator for 2FA
                  authentication
                </h5>
                <div
                  style={{ maxWidth: "100%" }}
                  dangerouslySetInnerHTML={{ __html: qr }}
                ></div>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default Update2FA;
