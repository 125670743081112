import React, { Component } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';

class RevealModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passPhrase: null,
    };
  }

  updatePassPhrase = (event) => {
    this.setState({ passPhrase: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          size="sm"
          isOpen={this.props.revealModal}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Reveal Wallet
            </h5>
          </div>
          {(this.props.selectedWallet.walletType === 'platform' ||
            this.props.selectedWallet.walletType === 'auto') && (
            <div className="modal-body">
              {this.props.error && (
                <p className="text-danger">{this.props.error}</p>
              )}
              {this.props.success && (
                <p className="text-success">{this.props.success}</p>
              )}
              <FormGroup>
                <Label>
                  Reveal <b>{this.props.selectedWallet.name}</b> wallet
                </Label>

                {this.props.selectedWallet.walletType === 'platform' && (
                  <Row>
                    <Col sm="12">
                      <InputGroup className="mb-2">
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="bx bx-lock"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          className="form-control"
                          placeholder="Passphrase"
                          onChange={this.updatePassPhrase}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                )}
              </FormGroup>
              <Row className="mt-3">
                <Col sm="4">
                  <button
                    className="btn btn-secondary btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => this.props.toggleRevealModal()}
                  >
                    Close
                  </button>
                </Col>
                <Col sm="8">
                  <button
                    className="btn btn-success btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() =>
                      this.props.revealWallet(
                        this.props.selectedWallet.publicKeyHash,
                        this.state.passPhrase,
                      )
                    }
                  >
                    Reveal wallet
                  </button>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default RevealModal;
