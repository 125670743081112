import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Media, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getConfig } from "../../helpers/api"
import TezosLogo from '../../components/Common/TezosLogo';
import EthereumLogo from '../../components/Common/EthereumLogo';
import PolygonLogo from '../../components/Common/PolygonLogo';
import { Link } from 'react-router-dom';

class Config extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: '',
            tezos: null,
            ethereum: null,
            polygon: null,
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount = async () => {
        const resp = await getConfig()
        this.setState({ tezos: resp.tezos, ethereum: resp.ethereum, polygon: resp.polygon, activeTab: 'tezos' })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Utility" breadcrumbItem="General configuration" />

                        <div className="checkout-tabs">
                            <Row>
                                <Col lg="2">
                                    <Nav className="flex-column" pills>
                                        {this.state.tezos && <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === 'tezos' })}
                                                onClick={() => { this.toggleTab('tezos'); }}
                                            >
                                                <div className="mt-4">
                                                    <TezosLogo />
                                                </div>
                                                <p className="font-weight-bold mb-4">Tezos</p>
                                            </NavLink>
                                        </NavItem>}
                                        {this.state.ethereum && <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === 'ethereum' })}
                                                onClick={() => { this.toggleTab('ethereum'); }}
                                            >
                                                <div className="mt-4">
                                                    <EthereumLogo />
                                                </div>
                                                <p className="font-weight-bold mb-4">Ethereum</p>
                                            </NavLink>
                                        </NavItem>}
                                        {this.state.polygon && <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === 'polygon' })}
                                                onClick={() => { this.toggleTab('polygon'); }}
                                            >
                                                <div className="mt-4">
                                                    <PolygonLogo />
                                                </div>
                                                <p className="font-weight-bold mb-4">Polygon</p>
                                            </NavLink>
                                        </NavItem>}
                                    </Nav>
                                </Col>
                                <Col lg="10">
                                    <Card>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activeTab}>
                                                {this.state.tezos && <TabPane tabId="tezos">
                                                    <CardTitle className="mb-5">Tezos configuration</CardTitle>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-transfer-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Network</h5>
                                                            <p className="text-muted">{this.state.tezos.network}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-network-chart font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">RPC Node</h5>
                                                            <p className="text-muted mb-1">{this.state.tezos.nodes[0]}</p>
                                                            <Link to={{ pathname: `${this.state.tezos.nodes[0]}/monitor/bootstrapped` }} target="_blank">Check node</Link>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-archive font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Explorer API</h5>
                                                            <p className="text-muted">{this.state.tezos.explorer[0]}</p>
                                                        </Media>
                                                    </Media>
                                                </TabPane>}
                                                {this.state.ethereum && <TabPane tabId="ethereum">
                                                    <CardTitle className="mb-5">Ethereum configuration</CardTitle>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-transfer-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Network</h5>
                                                            <p className="text-muted">{this.state.ethereum.network}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-network-chart font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">RPC Node</h5>
                                                            <p className="text-muted mb-1">{this.state.ethereum.infura_url}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-archive font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Explorer API</h5>
                                                            <p className="text-muted">{this.state.ethereum.etherscan}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-key font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Explorer API KEY</h5>
                                                            <p className="text-muted">{this.state.ethereum.api_key}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-lock-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">GNOSIS Safe Relay URL</h5>
                                                            <p className="text-muted">{this.state.ethereum.safe_relay_url}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-transfer-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">GNOSIS Transaction URL</h5>
                                                            <p className="text-muted">{this.state.ethereum.safe_tx_url}</p>
                                                        </Media>
                                                    </Media>
                                                </TabPane>}
                                                {this.state.polygon && <TabPane tabId="polygon">
                                                    <CardTitle className="mb-5">Polygon configuration</CardTitle>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-transfer-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Network</h5>
                                                            <p className="text-muted">{this.state.polygon.network}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-network-chart font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">RPC Node</h5>
                                                            <p className="text-muted mb-1">{this.state.polygon.infura_url}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-archive font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Explorer API</h5>
                                                            <p className="text-muted">{this.state.polygon.etherscan}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-key font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">Explorer API KEY</h5>
                                                            <p className="text-muted">{this.state.polygon.api_key}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-lock-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">GNOSIS Safe Relay URL</h5>
                                                            <p className="text-muted">{this.state.polygon.safe_relay_url}</p>
                                                        </Media>
                                                    </Media>
                                                    <Media className="faq-box mb-4">
                                                        <div className="faq-icon mr-3">
                                                            <i className="bx bx-transfer-alt font-size-20 text-success"></i>
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15">GNOSIS Transaction URL</h5>
                                                            <p className="text-muted">{this.state.polygon.safe_tx_url}</p>
                                                        </Media>
                                                    </Media>
                                                </TabPane>}
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Config;