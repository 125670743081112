import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import { Modal, FormGroup, Label } from "reactstrap";

class OpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

  render() {
      return (
        <React.Fragment>
          <Modal
            size="md"
            isOpen={this.props.pending}
            centered={true}
            backdrop="static"
            wrapClassName="modal-balance"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0 text-primary"
                id="myLargeModalLabel"
              >{this.props.passActive === 'ledger' ? 'SENDING PROPOSAL' : 'PLEASE WAIT' }</h5>
            </div>
            <div className="modal-body">
              {this.props.passActive === 'ledger' ?
                <FormGroup>
                    <Label>Validate operation</Label>
                    <p className="text-muted mb-2 text-break">
                        {this.props.info}
                    </p>
                    <Loader type="Puff" color="#545454" height={50} width={50}/>
                </FormGroup>
              :
                <FormGroup>
                    <Label>Processing</Label>
                    <p className="text-muted mb-2 text-break">
                        Sending proposal to Tezos network
                    </p>
                    <Loader type="Puff" color="#545454" height={50} width={50}/>
                    <p className="text-muted mt-2 text-break">
                        Please wait...
                    </p>
                </FormGroup>
              }
            </div>
          </Modal>
        </React.Fragment>
      )}
}

export default OpModal