import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Avatar from 'react-avatar';

// Pages Components
import { erc20Storage, dictAliases, listERC20Adresses, fetchERC20config } from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';

//i18n
import { withTranslation } from 'react-i18next';

class ERC20Structure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decimals: 100,
            modal: false,
            signers: [],
            multiSigs: [],
            storage: {},
            cfgEth: {},
            ops: [],
            user: {},
            loading: true,
            aliases: [],
        };
        this.togglemodal.bind(this);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount = () => {
        this._asyncRequest = listERC20Adresses().then(
            res => {
                if (res.status === 'SUCCESS') {
                    this.setState({ multiSigs: res.data.multisigs });
                } else {
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
        
        this._asyncRequest = dictAliases().then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({aliases: res.data, decimals: res.decimals });
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });

        this._asyncRequest = erc20Storage().then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({storage: res.data});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });

        this._asyncRequest = fetchERC20config().then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({cfgEth: res.data});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="4">
                                <Card>
                                    <div className="wcard-lugh">
                                        <Row>
                                            <Col xs="8">
                                                <div className="p-4 mb-4">
                                                    <h5 className="text-white">ERC20 Token</h5>
                                                </div>
                                            </Col>
                                            <Col xs="4" className="align-self-end">
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody>
                                        <div className="text-center mb-4">
                                            <div className="mx-auto">
                                                <Avatar src={logo} round size={82}/>
                                            </div>
                                            <p className="font-16 text-muted mb-2">Contract ID</p>
                                            <h5 className="font-size-14">
                                                {this.state.storage.token}
                                            </h5>
                                            <p className="font-16 text-muted mb-3"></p>
                                            <hr/>
                                            <h5>
                                                <Link to="#" className="text-dark">
                                                    Transfer status -
                                                    {this.state.storage.paused && this.state.storage.paused.toString() === 'true' ?
                                                        <span className="text-danger font-16 ml-1">Paused</span>
                                                    :
                                                        <span className="text-success font-16 ml-1">Active</span>
                                                    }
                                                </Link>
                                            </h5>
                                        </div>
                                        <Row className="mb-1">
                                                <Col xs="4">
                                                    <div className="social-source text-center mt-3">
                                                        <div className="avatar-xs mx-auto mb-3">
                                                            <span className="avatar-title rounded-circle bg-primary font-size-16">
                                                                <i className="mdi mdi-cog-outline text-white"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-12">Administrator</h5>
                                                        <p className="text-muted mb-0 font-size-10">{truncStringPortion(this.state.storage.admin, 7, 6)}</p>
                                                    </div>
                                                </Col>
                                                <Col xs="4">
                                                    <div className="social-source text-center mt-3">
                                                        <div className="avatar-xs mx-auto mb-3">
                                                            <span className="avatar-title rounded-circle bg-info font-size-16">
                                                                <i className="mdi mdi-stamper text-white"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-12">Master Minter</h5>
                                                        <p className="text-muted mb-0 font-size-10">{truncStringPortion(this.state.storage.master_minter, 7, 6)}</p>
                                                    </div>
                                                </Col>
                                                <Col xs="4">
                                                    <div className="social-source text-center mt-3">
                                                        <div className="avatar-xs mx-auto mb-3">
                                                            <span className="avatar-title rounded-circle bg-pink font-size-16">
                                                                <i className="mdi mdi-gavel text-white"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-12">Owner</h5>
                                                        <p className="text-muted mb-0 font-size-10">{truncStringPortion(this.state.storage.owner, 7, 6)}</p>
                                                    </div>
                                                </Col>
                                                <Col xs="4">
                                                    <div className="social-source text-center mt-3">
                                                        <div className="avatar-xs mx-auto mb-3">
                                                            <span className="avatar-title rounded-circle bg-dark font-size-16">
                                                                <i className="mdi mdi-stamper text-white"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-12">Delegatted minters</h5>
                                                        {this.state.storage.minters && Object.keys(this.state.storage.minters).map(minter => 
                                                            <p className="text-muted mb-0 font-size-10">{truncStringPortion(minter, 7, 6)}</p>
                                                        )}
                                                    </div>
                                                </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                            {this.state.multiSigs && this.state.multiSigs.map(multiSig => multiSig.publicKeyHash === this.state.storage.owner && 
                                <Card className="mini-stats-wid" key={multiSig.publicKeyHash}>
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <small className="text-muted font-weight-medium">{this.state.storage.owner}</small>
                                                <h4 className="mb-0">Owner <small>({multiSig.owners.length} signers)</small></h4>
                                                {multiSig.owners &&
                                                    <p>
                                                        {multiSig.owners.map(signer =>
                                                            <span className="d-block font-size-11 mt-1 mb-0" key={'sig_' + signer}>
                                                                <i className="mdi mdi-account mr-2"></i>
                                                                {this.state.aliases[signer.toLowerCase()] && this.state.aliases[signer.toLowerCase()].name ? this.state.aliases[signer.toLowerCase()].name : truncStringPortion(signer, 8, 6)} 
                                                                <small className="ml-2">({signer})</small>
                                                            </span>
                                                        )}
                                                    </p>
                                                }
                                                <h5>Operations</h5>
                                                <span className="badge badge-pink ml-2">Change Administrator</span>
                                                <span className="badge badge-pink ml-2">Change Master minter</span>
                                                <span className="badge badge-pink ml-2">Update contract</span>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                                <span className="avatar-title bg-pink">
                                                    <i className="mdi mdi-gavel font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.multiSigs && this.state.multiSigs.map(multiSig => multiSig.publicKeyHash === this.state.storage.admin && 
                                <Card className="mini-stats-wid" key={multiSig.publicKeyHash}>
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <small className="text-muted font-weight-medium">{this.state.storage.admin}</small>
                                                <h4 className="mb-0">Administrator <small>({multiSig.owners.length} signers)</small></h4>
                                                {multiSig.owners &&
                                                    <p>
                                                        {multiSig.owners.map(signer =>
                                                            <span className="d-block font-size-11 mt-1 mb-0" key={'sig_' + signer}>
                                                                <i className="mdi mdi-account mr-2"></i>
                                                                {this.state.aliases[signer.toLowerCase()] && this.state.aliases[signer.toLowerCase()].name ? this.state.aliases[signer.toLowerCase()].name : truncStringPortion(signer, 8, 6)} 
                                                                <small className="ml-2">({signer})</small>
                                                            </span>
                                                        )}
                                                    </p>
                                                }
                                                <h5>Operations</h5>
                                                <span className="badge badge-primary ml-2">Pause transfers</span>
                                                <span className="badge badge-primary ml-2">Force transfer</span>
                                                <span className="badge badge-primary ml-2">Blacklist/unBlacklist address</span>
                                                <span className="badge badge-primary ml-2">Change fees faucet</span>
                                                <span className="badge badge-primary ml-2">Update fees percentage</span>
                                                <span className="badge badge-primary ml-2">Update fees threshold</span>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                                <span className="avatar-title bg-primary">
                                                    <i className="mdi mdi-cog-outline font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            )}
                            </Col>
                            <Col xl="4">
                            {this.state.multiSigs && this.state.multiSigs.map(multiSig => multiSig.publicKeyHash === this.state.storage.master_minter && 
                                <Card className="mini-stats-wid" key={multiSig.publicKeyHash}>
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <small className="text-muted font-weight-medium">{this.state.storage.master_minter}</small>
                                                <h4 className="mb-0">Master minter <small>({multiSig.owners.length} signers)</small></h4>
                                                {multiSig.owners &&
                                                    <p>
                                                        {multiSig.owners.map(signer =>
                                                            <span className="d-block font-size-11 mt-1 mb-0" key={'sig_' + signer}>
                                                                <i className="mdi mdi-account mr-2"></i>
                                                                {this.state.aliases[signer.toLowerCase()] && this.state.aliases[signer.toLowerCase()].name ? this.state.aliases[signer.toLowerCase()].name : truncStringPortion(signer, 8, 6)} 
                                                                <small className="ml-2">({signer})</small>
                                                            </span>
                                                        )}
                                                    </p>
                                                }
                                                <h5>Operations</h5>
                                                <span className="badge badge-info ml-2">Mint</span>
                                                <span className="badge badge-info ml-2">Burn</span>
                                                <span className="badge badge-info ml-2">Add minter</span>
                                                <span className="badge badge-info ml-2">Remove minter</span>
                                                <span className="badge badge-info ml-2">Update allowance</span>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-dark align-self-center">
                                                <span className="avatar-title bg-info">
                                                    <i className="mdi mdi-stamper font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.multiSigs && this.state.multiSigs.map(multiSig => multiSig.name === 'Reserve' &&
                                <Card className="mini-stats-wid" key={multiSig.publicKeyHash}>
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <small className="text-muted font-weight-medium">{multiSig.publicKeyHash}</small>
                                                <h4 className="mb-0">Reserve <small>({multiSig.owners.length} signers)</small></h4>
                                                {multiSig.owners &&
                                                    <p>
                                                        {multiSig.owners.map(signer =>
                                                            <span className="d-block font-size-11 mt-1 mb-0" key={'sig_' + signer}>
                                                                <i className="mdi mdi-account mr-2"></i>
                                                                {this.state.aliases[signer.toLowerCase()] && this.state.aliases[signer.toLowerCase()].name ? this.state.aliases[signer.toLowerCase()].name : truncStringPortion(signer, 8, 6)} 
                                                                <small className="ml-2">({signer})</small>
                                                            </span>
                                                        )}
                                                    </p>
                                                }
                                                <h5>Operations</h5>
                                                <span className="badge badge-warning ml-2">Transfer</span>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                                <span className="avatar-title bg-warning">
                                                    <i className="mdi mdi-wallet font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            )}
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <p className="font-weight-medium">ERC20 Fees manager metrics</p>
                                        <div className="mb-0">
                                            Transfer: <b>{this.state.storage && this.state.storage.txFeeRate? this.state.storage.txFeeRate/10000 : '-'}%</b> <small className="mr-1">of amount</small>
                                            <hr/> 
                                            <span className={`font-size-11 font-weight-bold badge badge-success`}>
                                                €{this.state.storage && this.state.storage.gaslessBaseFee? this.state.storage.gaslessBaseFee : '-'}
                                            </span> 
                                            <small className="ml-1">min. amount for gasless transfer</small>
                                            <hr/>
                                            {this.state.storage && this.state.storage.estimation && <b>{this.state.storage.estimation.wei} wei = €{parseFloat(this.state.storage.estimation.eur).toFixed(2)}</b>}
                                        </div>
                                    </Media>
                                </Media>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ERC20Structure);
