
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'

//i18n
import { withTranslation } from 'react-i18next';

// users
import Avatar from 'react-avatar';

const ProfileMenu = ({ user, t }) => {
    const [menu, setMenu] = useState(false)

    const toggle = () => {
        setMenu(prevState => !prevState)
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <span className="rounded-circle header-profile-user">
                        <Avatar name={`${user.firstname || ''} ${user.lastname || ''}`} round size={36} textSizeRatio={2} color={'#446df6'}/>
                    </span>
                    <span className="d-none d-xl-inline-block ml-2 mr-1">{user ? `${user.firstname || ''} ${user.lastname || ''}` : ''}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    {/*}
                    <DropdownItem tag="a" href="#">
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        {t('Settings')}
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    */}
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{t('Logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(ProfileMenu)));
