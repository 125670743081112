import React, { Component } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { approveWhitelisting, rejectWhitelisting } from '../../helpers/api';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from 'react-i18next';
import Can from '../../components/Can';

class Whitelistings extends Component {
    constructor(props) { super(props); } //eslint-disable-line

    approve = (uuid) => {
        this._asyncRequest = approveWhitelisting(this.props.publicKeyHash, uuid).then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.props.fetchWhitelistings();
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    reject = (uuid) => {
        this._asyncRequest = rejectWhitelisting(this.props.publicKeyHash, uuid).then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.props.fetchWhitelistings();
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">
                        {this.props.clientName} Whitelistings
                    </CardTitle>
                    {this.props.whitelistings && this.props.whitelistings.length > 0 ? <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0 font-size-12">
                            <thead className="thead-light">
                                <tr>
                                    <th>Uuid</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.whitelistings && this.props.whitelistings.map(whitelisting =>
                                <tr key={whitelisting.userId}>
                                    <td>
                                        <Link to={`/oca-user/${this.props.publicKeyHash}/${whitelisting.userId}`}>
                                            {whitelisting.userId}
                                        </Link>
                                    </td>
                                    <td>
                                        {whitelisting.status === 'VALIDATED' && <span className="badge badge-success font-size-11">Approved</span>}
                                        {whitelisting.status === 'REJECTED' && <span className="badge badge-danger font-size-11">Unapproved</span>}
                                        {whitelisting.status === 'PENDING' && <span className="badge badge-warning font-size-11">Pending</span>}
                                    </td>
                                    <td>
                                        <Can role={this.props.user.role} perform="admin:access" yes={() => {
                                            return (
                                                <React.Fragment>
                                                    {['PENDING', 'REJECTED'].includes(whitelisting.status) && <Button className="mt-2 mr-2" type="button" color="success" onClick={() => {this.approve(whitelisting.userId)}}>Approve</Button>}
                                                    {['PENDING', 'VALIDATED'].includes(whitelisting.status) && <Button className="mt-2 mr-2" type="button" color="danger" onClick={() => {this.reject(whitelisting.userId)}}>Reject</Button>}
                                                </React.Fragment>
                                            )
                                        }} />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    :
                    <span className="text-muted">
                        No Uuid in whitelistings
                    </span>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(Whitelistings)));