import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  absClients,
  readyRequests,
  fetchPendingWhitelistings,
  getChains,
} from "../../helpers/api";
import { withTranslation } from "react-i18next";
import Can from "../Can";
import { connect } from "react-redux";
import SidebarFA2Proposals from "./SidebarFA2Proposals";
import SidebarERC20Proposals from "./SidebarERC20Proposals";
import SidebarPolygonProposals from "./SidebarPolygonProposals";
import SVGLogo from "../../components/Common/SVGLogo.js";
import TezosLogo from "../Common/TezosLogo";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      nbPendings: 0,
      whitelistings: {},
      totalPendings: 0,
      chains: [],
    };
  }

  componentDidMount = async () => {
    let resp = await getChains(),
      chains = [];
    for (let chain of resp.chains) chains.push(chain.slug);
    this.setState({ chains });
    this.initMenu();
    this._asyncRequest = fetchPendingWhitelistings()
      .then((res) => {
        if (res.status === "SUCCESS") {
          this.setState({
            whitelistings: res.data.whitelistings,
            totalPendings: res.data.totalPendings,
          });
        } else {
          console.log(res.error);
        }
        this._asyncRequest = absClients()
          .then((res) => {
            if (res.status === "SUCCESS") {
              this.setState({ clients: res.data.clients });
            } else {
              console.log(res.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    this.fetchPendingRequests();
  };

  fetchPendingRequests = () => {
    this._asyncRequest = readyRequests()
      .then((response) => {
        if (response.status === "SUCCESS") {
          this.setState({ nbPendings: response.data });
        } else {
          console.log(`Fetch failed : ${response.error}`);
        }
      })
      .catch((error) => {
        console.log(`Fetch failed : ${error}`);
      });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.type !== prevProps.type ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.initMenu(true);
    } else if (this.props.user !== prevProps.user) {
      this.initMenu();
    }
  }

  initMenu(reload) {
    if (!reload) {
      new MetisMenu("#side-menu");
    }

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");

    for (var i = 0; i < items.length; ++i) {
      if (
        (this.props.location.pathname.indexOf(items[i].pathname) === 0 &&
          items[i].pathname !== "/") ||
        items[i].pathname === this.props.location.pathname
      ) {
        matchingMenuItem = items[i];
      } else {
        this.deactivate(items[i]);
      }
    }

    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  deactivate = (item) => {
    item.classList.remove("active");
    return false;
  };

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <Can
              role={this.props.user.role}
              perform="reader:all"
              yes={() => {
                return (
                  <React.Fragment>
                    <li className="menu-title">{this.props.t("Home")}</li>
                    <li>
                      <Link to="/dashboard" className="waves-effect">
                        <i>
                          <SVGLogo width="24" height="24" />
                        </i>
                        <span>{this.props.t("Dashboard")}</span>
                      </Link>
                    </li>
                    {this.props.activeToken === "fa2" && (
                      <li>
                        <Link to="/fa2/structure" className="waves-effect">
                          <i className="bx bx-hive"></i>
                          <span>{this.props.t("FA2 Structure overview")}</span>
                        </Link>
                      </li>
                    )}
                    {this.props.activeToken === "erc20" && (
                      <li>
                        <Link to="/erc20/structure" className="waves-effect">
                          <i className="bx bx-hive"></i>
                          <span>
                            {this.props.t("ERC20 Structure overview")}
                          </span>
                        </Link>
                      </li>
                    )}
                    {this.props.activeToken === "polygon" && (
                      <li>
                        <Link to="/polygon/structure" className="waves-effect">
                          <i className="bx bx-hive"></i>
                          <span>{this.props.t("Polygon ERC20 Structure")}</span>
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="operator:financial"
              yes={() => {
                return (
                  <React.Fragment>
                    <li className="menu-title">
                      {this.props.t("Client requests")}
                    </li>
                    <li>
                      <Link to="/request/all-requests" className="waves-effect">
                        <i className="bx bx-list-check"></i>
                        <span>
                          {this.state.nbPendings > 0 && (
                            <span className="badge badge-pill badge-pink float-right">
                              {this.state.nbPendings}
                            </span>
                          )}
                          {this.props.t("Requests")}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/request/op-purchase" className="waves-effect">
                        <i className="bx bx-purchase-tag"></i>
                        <span>{this.props.t("Set purchase request")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/request/op-sale" className="waves-effect">
                        <i className="bx bx-euro"></i>
                        <span>{this.props.t("Set sale request")}</span>
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="reader:all"
              yes={() => {
                return (
                  <React.Fragment>
                    <li className="menu-title">{this.props.t("Menu")}</li>

                    <li>
                      <Link to="/transactions" className=" waves-effect">
                        <i className="bx bx-bar-chart-alt-2"></i>
                        <span>{this.props.t("EURL Transactions")}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/bank" className=" waves-effect">
                        <i className="bx bx-book-open"></i>
                        <span>{this.props.t("Bank operations")}</span>
                      </Link>
                    </li>
                    {this.state.chains.includes("fa2") && (
                      <SidebarFA2Proposals />
                    )}
                    {this.state.chains.includes("erc20") && (
                      <SidebarERC20Proposals />
                    )}
                    {this.state.chains.includes("polygon") && (
                      <SidebarPolygonProposals />
                    )}
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="operator:financial"
              yes={() => {
                return (
                  <React.Fragment>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-coin-stack"></i>
                        <span>{this.props.t("Financial operations")}</span>
                      </Link>
                      {this.props.activeToken === "fa2" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/transfer-xtz">
                              {this.props.t("Transfer")}{" "}
                              <TezosLogo width="13" height="13" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/transfer-lugh">
                              {this.props.t("Transfer")}{" "}
                              <SVGLogo width="13" height="13" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/reserve/reserve_transfer">
                              {this.props.t("Reserve Transfer")}{" "}
                              <SVGLogo width="13" height="13" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/minter/mint">
                              {this.props.t("Mint")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/minter/burn">
                              {this.props.t("Burn")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/minter/add_minter">
                              {this.props.t("Add minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/minter/remove_minter">
                              {this.props.t("Remove minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/minter/update_allowance">
                              {this.props.t("Update allowance")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/set_lock">
                              {this.props.t("Lock/unlock")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/set_pause">
                              {this.props.t("Pause transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/force_transfer">
                              {this.props.t("Force Transfer")}{" "}
                              <SVGLogo width="13" height="13" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/set_metadata">
                              {this.props.t("Set contract metadata")}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.props.activeToken === "erc20" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-erc20/minter/mint">
                              {this.props.t("Mint")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/minter/burnFrom">
                              {this.props.t("Burn")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/minter/addMinter">
                              {this.props.t("Add minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/minter/removeMinter">
                              {this.props.t("Remove minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/minter/updateMintingAllowance">
                              {this.props.t("Update allowance")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/reserve/transfer">
                              {this.props.t("Reserve transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/reserve/approve">
                              {this.props.t("Reserve approve")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/blacklist">
                              {this.props.t("Blacklist")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/unblacklist">
                              {this.props.t("Unblacklist")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/pause">
                              {this.props.t("Pause transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/unpause">
                              {this.props.t("Resume transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/forceTransfer">
                              {this.props.t("Force Transfer")}{" "}
                              <SVGLogo width="13" height="13" />
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.props.activeToken === "polygon" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-polygon/minter/mint">
                              {this.props.t("Mint")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/minter/burnFrom">
                              {this.props.t("Burn")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/minter/addMinter">
                              {this.props.t("Add minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/minter/removeMinter">
                              {this.props.t("Remove minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/minter/updateMintingAllowance">
                              {this.props.t("Update allowance")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/reserve/transfer">
                              {this.props.t("Reserve transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/reserve/approve">
                              {this.props.t("Reserve approve")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/blacklist">
                              {this.props.t("Blacklist")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/unblacklist">
                              {this.props.t("Unblacklist")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/pause">
                              {this.props.t("Pause transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/unpause">
                              {this.props.t("Resume transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/forceTransfer">
                              {this.props.t("Force Transfer")}{" "}
                              <SVGLogo width="13" height="13" />
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-transfer-alt"></i>
                        <span>{this.props.t("Fees management")}</span>
                      </Link>
                      {this.props.activeToken === "fa2" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-fa2/administrator/set_fees_faucet">
                              {this.props.t("Change fees faucet")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/updateThreshold">
                              {this.props.t("Update gasless base fee")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/updateGasFee">
                              {this.props.t("Update fee for gas transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/updateGaslessFee">
                              {this.props.t("Update fee for gasless transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-fa2/administrator/updateStorageFee">
                              {this.props.t("Update storage fees")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/change-feesmanager">
                              {this.props.t("Change fees manager")}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.props.activeToken === "erc20" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-erc20/administrator/setFeeFaucet">
                              {this.props.t("Change fees faucet")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/updateGaslessBasefee">
                              {this.props.t("Update gasless base fee")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/updateTxFeeRate">
                              {this.props.t("Update fee for transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/administrator/setTrustedForwarder">
                              {this.props.t("Set trusted forwarder")}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.props.activeToken === "polygon" && (
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-polygon/administrator/setFeeFaucet">
                              {this.props.t("Change fees faucet")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/updateGaslessBasefee">
                              {this.props.t("Update gasless base fee")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/updateTxFeeRate">
                              {this.props.t("Update fee for transfer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-polygon/administrator/setTrustedForwarder">
                              {this.props.t("Set trusted forwarder")}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    {this.props.activeToken === "erc20" && (
                      <li>
                        <Link to="/#" className="has-arrow waves-effect">
                          <i className="bx bx-pencil"></i>
                          <span>{this.props.t("Gnosis signers")}</span>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/gnosis/add/ETHsigner/owner">
                              {this.props.t("Add Owner signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/ETHsigner/minter">
                              {this.props.t("Add Minter signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/ETHsigner/administrator">
                              {this.props.t("Add Admin signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/ETHsigner/reserve">
                              {this.props.t("Add Reserve signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/ETHsigner/owner">
                              {this.props.t("Remove Owner signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/ETHsigner/minter">
                              {this.props.t("Remove Minter signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/ETHsigner/administrator">
                              {this.props.t("Remove Admin signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/ETHsigner/reserve">
                              {this.props.t("Remove Reserve signer")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    {this.props.activeToken === "polygon" && (
                      <li>
                        <Link to="/#" className="has-arrow waves-effect">
                          <i className="bx bx-pencil"></i>
                          <span>{this.props.t("Gnosis signers")}</span>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/gnosis/add/POLYGONsigner/owner">
                              {this.props.t("Add Owner signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/POLYGONsigner/minter">
                              {this.props.t("Add Minter signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/POLYGONsigner/administrator">
                              {this.props.t("Add Admin signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/add/POLYGONsigner/reserve">
                              {this.props.t("Add Reserve signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/POLYGONsigner/owner">
                              {this.props.t("Remove Owner signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/POLYGONsigner/minter">
                              {this.props.t("Remove Minter signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/POLYGONsigner/administrator">
                              {this.props.t("Remove Admin signer")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gnosis/remove/POLYGONsigner/reserve">
                              {this.props.t("Remove Reserve signer")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    {/* <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="bx bx-id-card"></i>
                                        <span>{this.props.t('Rights management')}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/change-rightsmanager">{this.props.t('Change rights manager')}</Link></li>
                                        <li><Link to="/financial-operation/administrator/set_rights_manager">{this.props.t('Remove rights manager')}</Link></li>
                                        <li><Link to="/rights-operation/administrator/addIssuer">{this.props.t('Add issuer')}</Link></li>
                                        <li><Link to="/rights-operation/administrator/removeIssuer">{this.props.t('Remove issuer')}</Link></li>
                                        <li><Link to="/rights-operation/administrator/grant">{this.props.t('Grant transfer rights')}</Link></li>
                                        <li><Link to="/rights-operation/administrator/revoke">{this.props.t('Revoke rights')}</Link></li>
                                    </ul>
                                </li> */}
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="reader:all"
              yes={() => {
                return (
                  <React.Fragment>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-wallet"></i>
                        <span>{this.props.t("Wallets")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={`/${this.props.activeToken}/wallets`}>
                            {this.props.t("Saved wallets")}
                          </Link>
                        </li>
                        <Can
                          role={this.props.user.role}
                          perform="operator:wallet"
                          yes={() => {
                            return (
                              <React.Fragment>
                                <li>
                                  <Link to="/create-wallet">
                                    {this.props.t("Create wallet")}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/reference-signer">
                                    {this.props.t("Reference signer")}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/restore-wallet">
                                    {this.props.t("Restore wallet")}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/keystore-utils">
                                    {this.props.t("Keystore utils")}
                                  </Link>
                                </li>
                              </React.Fragment>
                            );
                          }}
                        />
                      </ul>
                    </li>

                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-user-pin"></i>
                        <span>{this.props.t("Clients")}</span>
                        {this.state.totalPendings > 0 && (
                          <span className="badge badge-pill badge-success float-right">
                            {this.state.totalPendings}
                          </span>
                        )}
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/all-clients">
                            <i className="bx bx-list-ul"></i>{" "}
                            {this.props.t("Clients list")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/new-client">
                            <i className="bx bx-user-plus"></i>{" "}
                            {this.props.t("Reference Client")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/basefees">
                            <i className="bx bxs-hot"></i>{" "}
                            {this.props.t("Gas fees")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="operator:financial"
              yes={() => {
                return (
                  <React.Fragment>
                    <li>
                      <Link to="/quote/eur" className="waves-effect">
                        <i className="bx bx-euro"></i>
                        <span>{this.props.t("EUR quote")}</span>
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }}
            />

            <Can
              role={this.props.user.role}
              perform="admin:access"
              yes={() => {
                return (
                  <React.Fragment>
                    <li className="menu-title">Administration</li>
                    <li>
                      <Link to="/account">
                        <i className="bx bx-user-circle"></i>
                        <span>{this.props.t("Account management")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/alert">
                        <i className="bx bx-window-alt"></i>
                        <span>{this.props.t("Alerts management")}</span>
                      </Link>
                    </li>

                    {this.props.activeToken === "fa2" ? (
                      <li>
                        <Link to="/#" className="has-arrow waves-effect">
                          <i className="bx bx-revision"></i>
                          <span>{this.props.t("Change multisigs")}</span>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/change-minter/set_master_minter">
                              {this.props.t("Change Minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/change-multisig/set_administrator">
                              {this.props.t("Change Administrator")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li>
                        <Link to="/#" className="has-arrow waves-effect">
                          <i className="bx bx-revision"></i>
                          <span>{this.props.t("Change Gnosis safe")}</span>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/financial-erc20/owner/setMasterMinter">
                              {this.props.t("Change Master minter")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/financial-erc20/owner/setAdministrator">
                              {this.props.t("Change Administrator")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-list-ul"></i>
                        <span>{this.props.t("Audit logs")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/activity">
                            {this.props.t("Platform Operations")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/access">
                            {this.props.t("Platform Access")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/config">
                        <i className="bx bx-cog"></i>
                        <span>{this.props.t("General config")}</span>
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }}
            />
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  const { activeToken } = state.Token;
  return { user, activeToken };
};

export default withRouter(
  connect(mapStatetoProps, null)(withTranslation()(SidebarContent))
);
