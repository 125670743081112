import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Media, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { addressDetails, dictAliases } from "../../helpers/api";
import { truncStringPortion } from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js'

class AddressTx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenId: 0,
            decimals: 100,
            startDate: new Date(),
            activeTab: '1',
            transactions: [],
            limit: false,
            aliases: {},
            loading: true,
            details: {},
            token: {},
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.handleChange.bind(this);
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                publicKeyHash: null
            });
        }
    }

    componentDidMount = () => {
        this._asyncRequest = dictAliases().then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({aliases: res.data, decimals: res.decimals, tokenId: res.tokenId});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
        const publicKeyHash = this.props.match.params.publicKeyHash;
        this.setState({publicKeyHash: publicKeyHash});
        this._asyncRequest = addressDetails(publicKeyHash).then(
            res => {
                if (res.status === 'SUCCESS'){
                    let rows = [];
                    res.data.ops.forEach(op => {
                        if(op.parameters && op.is_success){
                            let params = op.parameters.value[op.parameters.entrypoint] || op.parameters.value;
                            rows.push({
                                operationId: truncStringPortion(op.hash, 8, 6),
                                operationType: op.parameters.entrypoint + " " + truncStringPortion(op.receiver, 8, 6),
                                date: new Date(op.time).toLocaleDateString() + " " + new Date(op.time).toLocaleTimeString(),
                                parameters:
                                    <span className="badge font-size-9 text-left">
                                    {["string", "integer", "boolean"].includes(typeof(params))?
                                        ["mint", "burn"].includes(op.parameters.entrypoint)?
                                            <span>{parseFloat(params/this.state.decimals)} <SVGLogo width="13" height="13"/></span>
                                        :
                                            params.toString()
                                    :
                                        <>{Object.keys(params).map((e, i) =>
                                            <div key={i} className="mt-1">
                                                {e} :
                                                <span className="ml-1">{["amount", "balance", "value"].includes(e)?
                                                    <span>{parseFloat(params[e]/this.state.decimals)} <SVGLogo width="13" height="13"/></span>
                                                :
                                                    this.state.aliases[params[e].toString()] && this.state.aliases[params[e].toString()].name?
                                                        this.state.aliases[params[e].toString()].name
                                                    :
                                                        params[e].toString()
                                                }</span>
                                            </div>
                                         )}</>
                                    }
                                    </span>,
                                caller: this.state.aliases[op.sender] && this.state.aliases[op.sender].name? this.state.aliases[op.sender].name : truncStringPortion(op.sender, 8, 6),
                                more : <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/transaction-details/${op.hash}`}>View Details</Link>
                            })
                        }else if(op.is_success){
                            rows.push({
                                operationId: truncStringPortion(op.hash, 8, 6),
                                operationType: op.type,
                                date: new Date(op.time).toLocaleDateString() + " " + new Date(op.time).toLocaleTimeString(),
                                parameters: <span>{op.volume} <TezosLogo width="13" height="13"/></span>,
                                caller: this.state.aliases[op.sender] && this.state.aliases[op.sender].name? this.state.aliases[op.sender].name : truncStringPortion(op.sender, 8, 6),
                                more : <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/transaction-details/${op.hash}`}>View Details</Link>
                            })
                        }
                    });
                    this.setState({transactions: {
                        columns: [
                          {
                            label: "Operation ID",
                            field: "operationId",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Operation Type",
                            field: "operationType",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Date",
                            field: "date",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Parameters",
                            field: "parameters",
                            width: 160
                          },
                          {
                            label: "Caller",
                            field: "caller",
                            sort: "asc",
                            width: 104
                          },
                          {
                            label: "More",
                            field: "more",
                            sort: "asc",
                            width: 100
                          }
                        ],
                        rows: rows.reverse(),
                    }, loading: false,
                    details: res.data.details,
                    token: res.data.token,});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {!this.state.loading &&
                        <>
                            <h5>{this.state.publicKeyHash}</h5>
                            <Row>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Balance</p>
                                                    <h4 className="mb-0"><SVGLogo/> {this.state.token? parseFloat(this.state.token.balance / this.state.decimals).toLocaleString() : 0}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <SVGLogo/>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Provision</p>
                                                    <h4 className="mb-0"><TezosLogo/> {this.state.details.total_balance? this.state.details.total_balance.toLocaleString() : 0}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <TezosLogo/>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    {this.state.token && this.state.token.lock.toString() === 'true'? 
                                        <Card className="mini-stats-wid bg-danger">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p className="text-white font-weight-medium">Lock status</p>
                                                        <h4 className="text-white mb-0">locked</h4>
                                                    </Media>
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                                        <span className="avatar-title bg-white">
                                                            <i className="bx bx-lock font-size-24 text-danger"></i>
                                                        </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    :
                                        <Card className="mini-stats-wid bg-success">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p className="text-white font-weight-medium">Lock status</p>
                                                        <h4 className="text-white mb-0">Unlocked</h4>
                                                    </Media>
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                                        <span className="avatar-title bg-white">
                                                            <i className="bx bx-lock-open font-size-24 text-success"></i>
                                                        </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title">{this.state.aliases[this.state.publicKeyHash] && this.state.aliases[this.state.publicKeyHash].name? this.state.aliases[this.state.publicKeyHash].name : this.state.publicKeyHash} operations</h4>
                                            <TabContent activeTab={this.state.activeTab} className="p-3">
                                                <TabPane tabId="1" id="all-order">
                                                    <MDBDataTable responsive bordered data={this.state.transactions} order={['date', 'desc' ]} className="mt-1" />
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>}
                        {this.state.loading && <div className="text-center my-3">
                            <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Link>
                        </div>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AddressTx;
