import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Media, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { extractDetails, resolveExtract, cancelExtract, clientWhitelistings } from "../../helpers/api";
import SVGLogo from '../../components/Common/SVGLogo.js';

import CSV from '../../components/Common/CSV';

import Can from '../../components/Can';
import { connect } from 'react-redux';

class ExtractDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            activeTab: '1',
            transactions: [],
            client: {},
            extract: {},
            loading: true,
            csvRows: [],
            limitDate: null,
            fees: {},
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.handleChange.bind(this);
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    processResolveExtract = () => {
        this._asyncRequest = resolveExtract(this.state.extract.id).then(
            res => {
                window.location.reload();
            }
        ).catch(error => {
            console.log(error);
        });
    }

    processCancelExtract = () => {
        this._asyncRequest = cancelExtract(this.state.extract.id).then(
            res => {
                window.location.reload();
            }
        ).catch(error => {
            console.log(error);
        });
    }

    componentDidMount = () => {
        const extractId = this.props.match.params.extractId;
        this._asyncRequest = extractDetails(extractId).then(
            res => {
                if (res.status === 'SUCCESS'){
                    this._asyncRequest = clientWhitelistings(res.data.client.publicKeyHash).then(
                        response => {
                            let whitelistings = []
                            if (response.status === 'SUCCESS'){
                                whitelistings = response.data.whitelistings;
                            }
                            let startDate = new Date(res.data.extract.createdAt);
                            startDate.setDate(startDate.getDate() - 5);
                            let startStamp = res.data.limitDate? Date.parse(res.data.limitDate).toString() : Date.parse(startDate).toString();
                            this.setState({client: res.data.client, extract: res.data.extract, startDate: startStamp, fees: res.data.fees});
                            let rows = [], csvRows = [];
                            res.data.transactions.forEach(op => {
                                let unauthorized = false;
                                if(op.sender_id){
                                    unauthorized = true;
                                    for(const wtlist of whitelistings){
                                        if(wtlist.userId === op.sender_id && wtlist.status === 'VALIDATED')
                                            unauthorized = false;
                                    }
                                }
                                if(op.action)
                                    op.operation = op.action
                                rows.push({
                                    operationId: op.op_id,
                                    operation: <>
                                        {op.operation === 'sell'? 
                                            <span className="badge badge-warning font-size-10">Sell</span>
                                        :
                                            op.operation === 'buy'? 
                                                <span className="badge badge-success font-size-10">Buy</span>
                                            :
                                                <span className="badge badge-dark font-size-10">Transfer</span>
                                        }
                                        {unauthorized && <><br/><span className="badge badge-danger font-size-10 mt-1">Unauthorized</span></>}
                                        {startStamp.toString() > op.timestamp.toString() && <><br/><span className="badge badge-warning font-size-10 mt-1">Out of range</span></> }
                                    </>,
                                    date: new Date(op.timestamp * 1000).toISOString(),
                                    change: op.operation === 'sell'? 'From EUR-L to ' + op.pair : 'From ' + op.pair + ' to EUR-L',
                                    amount: parseFloat(op.tokens.toString().replace(/,/, '.')),
                                    user : op.operation === 'transfer'? `${op.sender_id} transferred to ${op.user_id}` : op.user_id,
                                    fees: op.fees? parseFloat(op.fees.toString().replace(/,/, '.')) : 0,
                                    clickEvent: () => window.location.href=`/oca-user/${res.data.client.publicKeyHash}/${op.user_id}`
                                })

                                csvRows.push({
                                    operationId: op.op_id,
                                    operation: op.operation,
                                    date: new Date(op.timestamp * 1000).toISOString(),
                                    change: op.pair,
                                    amount: op.tokens,
                                    user : op.user_id,
                                    fees: op.fees? op.fees : 0
                                })
                            });
                            this.setState({transactions: {
                                columns: [
                                {
                                    label: "Operation ID",
                                    field: "operationId",
                                    sort: "asc",
                                    width: 136
                                },
                                {
                                    label: "Operation",
                                    field: "operation",
                                    sort: "asc",
                                    width: 136
                                },
                                {
                                    label: "Date",
                                    field: "date",
                                    sort: "asc",
                                    width: 136
                                },
                                {
                                    label: "Trading pair",
                                    field: "change",
                                    width: 160
                                },
                                {
                                    label: "EUR-L amount",
                                    field: "amount",
                                    sort: "asc",
                                    width: 104
                                },
                                {
                                    label: "User",
                                    field: "user",
                                    sort: "asc",
                                    width: 100
                                },
                                {
                                    label: "Fees (€)",
                                    field: "fees",
                                    sort: "asc",
                                    width: 100
                                }
                                ],
                                rows: rows
                            }, loading: false, csvRows, limitDate: res.data.limitDate});
                        }
                    ).catch(error => {
                        console.log(error);
                    });
                    
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {!this.state.loading &&
                        <>
                            <Row>
                                <Col md="12">
                                    <h4 className="card-title mb-2">{this.state.extract.createdAt.substring(0,19).replace('T', ' ').replace('Z', '') + ' GMT'} - {this.state.client.name}  operations</h4>
                                    {this.state.extract.operationId && <p className="text-muted mb-1">Chain operation <Link to="#" onClick={() => {window.open(`https://carthagenet.tzstats.com/${this.state.extract.operationId}`)}}>{this.state.extract.operationId}</Link></p>}
                                    <p className="text-muted mb-4">Digest - <b>{this.state.extract.digest}</b> - {this.state.startDate}</p>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Cash-flow</p>
                                                    <h4 className="mb-0"><SVGLogo/> {(Math.round((this.state.extract.dailyDispatch) * 100) / 100).toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-calendar-event font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Customer funds</p>
                                                    <h4 className="mb-0"><SVGLogo/> {(Math.round((this.state.extract.globalDispatch) * 100) / 100).toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-code-block font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Wallet balance</p>
                                                    <h4 className="mb-0"><SVGLogo/> {this.state.extract.lughBalance.toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-coin-stack font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                  {['VALIDATED', 'PUBLISHED'].includes(this.state.extract.status) &&
                                      <Card className="mini-stats-wid bg-success">
                                        <CardBody>
                                          <Media>
                                            <Media body>
                                              <p className="text-white font-weight-medium">Consistency</p>
                                              <h4 className="mb-0 text-white">Validated</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center text-white">
                                              <span className="avatar-title bg-white">
                                                <i className="bx bx-check-circle text-success font-size-24"></i>
                                              </span>
                                            </div>
                                          </Media>
                                        </CardBody>
                                      </Card>
                                  }
                                  {['REJECTED'].includes(this.state.extract.status) &&
                                      <Card className="mini-stats-wid bg-danger">
                                        <CardBody>
                                          <Media>
                                            <Media body>
                                              <p className="text-white font-weight-medium">Consistency</p>
                                              <h4 className="mb-0 text-white">Rejected</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center text-white">
                                              <span className="avatar-title bg-white">
                                                <i className="bx bx-x text-danger font-size-24"></i>
                                              </span>
                                            </div>
                                          </Media>
                                        </CardBody>
                                      </Card>
                                  }
                                  {['RESOLVED'].includes(this.state.extract.status) &&
                                     <Card className="mini-stats-wid bg-pink">
                                        <CardBody>
                                          <Media>
                                            <Media body>
                                              <p className="text-white font-weight-medium">Consistency</p>
                                              <h4 className="mb-0 text-white">Resolved</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center text-white">
                                              <span className="avatar-title bg-white">
                                                <i className="bx bx-refresh text-pink font-size-24"></i>
                                              </span>
                                            </div>
                                          </Media>
                                        </CardBody>
                                      </Card>
                                  }
                                  {['CANCELLED'].includes(this.state.extract.status) &&
                                     <Card className="mini-stats-wid bg-dark">
                                        <CardBody>
                                          <Media>
                                            <Media body>
                                              <p className="text-white font-weight-medium">Consistency</p>
                                              <h4 className="mb-0 text-white">Cancelled</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center text-white">
                                              <span className="avatar-title bg-white">
                                                <i className="bx bx-task-x text-dark font-size-24"></i>
                                              </span>
                                            </div>
                                          </Media>
                                        </CardBody>
                                      </Card>
                                  }
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Crypto fees</p>
                                                    <h4 className="mb-0">€ {this.state.fees.crypto.toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-transfer-alt font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Euro fees</p>
                                                    <h4 className="mb-0">€ {this.state.fees.eur.toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-transfer-alt font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activeTab} className="p-3">
                                                <TabPane tabId="1" id="all-order">
                                                    <MDBDataTable responsive bordered data={this.state.transactions} order={['date', 'desc' ]} className="mt-1 font-size-11" hover/>
                                                </TabPane>
                                            </TabContent>
                                            <Can role={this.props.user.role} perform="operator:wallet" yes={() => (<>
                                                {this.state.extract.status === "REJECTED" && <button className="btn btn-pink waves-effect waves-light mt-2" type="submit" onClick={() => {this.processResolveExtract()}}><i className="bx bx-refresh"></i> Reprocess</button>}
                                                {this.state.extract.status === "REJECTED" && <button className="btn btn-dark waves-effect waves-light mt-2 ml-4" type="submit" onClick={() => {if(window.confirm('Cancel extracts?')){this.processCancelExtract()};}}><i className="bx bx-task-x"></i> Cancel</button>}
                                                </>
                                            )} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        }
                        {this.state.loading?
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Link>
                            </div>
                        :
                            <FormGroup>
                                <Row className="mr-3">
                                    <Col sm="12">
                                        <CSV transactions={this.state.transactions} csvRows={this.state.csvRows} name="transactions" />
                                    </Col>
                                </Row>
                            </FormGroup>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default connect(mapStatetoProps, null)(ExtractDetails);
