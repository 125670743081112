import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button, Nav, TabContent, TabPane, InputGroup, InputGroupAddon } from "reactstrap";
import { dictAliases, financialOps, callEntrypoint, registerProposal, registerOperation, originateContract, publishCode, opDetails } from "../../helpers/api";
import { truncStringPortion, convertErrorCode } from '../../helpers/formatter.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import LoadingModal from "./Modals/LoadingModal.js";
import LoadingLedger from "./Modals/LoadingLedger.js";
import { TezosToolkit } from '@taquito/taquito';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';
import { TezosContractIntrospector } from 'conseiljs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const derivationPath = "44'/1729'/0'/0'/0'";

const descLimit = 140;

class ChangeMultisig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenId: 0,
            description: null,
            descCharLeft: descLimit,
            pending: false,
            error: null,
            activeTab: '1',
            limit: 2,
            restriction: this.props.match.params.op === 'setReserve' ? 20 : this.props.match.params.op === 'setMinter' ? 10 : 40,
            inputs: ['input-0', 'input-1'],
            contract: 'contract',
            keystore: null,
            connected: false,
            proposalId: '',
            callingPoint: 'createProposal',
            aliases: [],
            storage: {},
            details: {},
            entrypoint: {},
            signers: {},
            lughContract: null,
            passActive: null,
            selectedWallet: null,
            displayResult: false,
            contractId: null,
            operationContract: null,
            tezosNode: null,
            codeMichelson: null,
            explorer: null,
            instructions: 'Follow instruction on your ledger...'
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.passPhrase = React.createRef();
        this['input-0'] = React.createRef();
        this['input-1'] = React.createRef();
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateDescription = (event) => {
        this.setState({descCharLeft: descLimit - event.target.value.length, description: event.target.value});
    }

    updateLimit = (event) => {
        if(parseInt(event.target.value) > this.state.inputs.length)
            this.setState({limit: this.state.inputs.length});
        else if(parseInt(event.target.value) < 2)
            this.setState({limit: 2});
        else
            this.setState({limit: event.target.value});
    }

    updateRestriction = (event) => {
        if(parseInt(event.target.value) > 200)
            this.setState({restriction: 200});
        else if(parseInt(event.target.value) < 1)
            this.setState({restriction: 1});
        else
            this.setState({restriction: event.target.value});
    }

    buildStorage = (listSigners) => {
        let signers = '{';
        listSigners.forEach(inp => {
            signers = signers + '"' + inp + '"; ';
        });
        signers = signers.slice(0, -1) + '}';
        if(this.state.contract.toLowerCase() === 'administrator'){
            if(this.state.tokenId || this.state.tokenId === 0){
                return '(Pair (Pair {"addIssuer"; "grant"; "removeIssuer"; "revoke"; "set_fees_faucet"; "set_fees_manager"; "set_lock"; "set_rights_manager"; "updateGasFee"; "updateGaslessFee"; "updateStorageFee"; "updateThreshold"} (Pair ' + this.state.limit + ' 0)) (Pair {} (Pair ' + this.state.restriction + ' ' + signers + ')))';
            }else{
                this.setState({operation: 'setAdministrator'});
                return '(Pair (Pair {"setLock"; "setWhiteListing"} (Pair ' + this.state.limit + ' 0)) (Pair {} (Pair ' + this.state.restriction + ' ' + signers + ')))';
            }
        }else if(this.state.contract.toLowerCase() === 'reserve'){
            if(this.state.tokenId || this.state.tokenId === 0){
                return '(Pair (Pair ' + this.state.limit + ' 0) (Pair {} (Pair ' + this.state.restriction + ' ' + signers + ')))';
            }else{
                this.setState({operation: 'setReserve'});
                return '(Pair (Pair ' + this.state.limit + ' 0) (Pair {} (Pair ' + this.state.restriction + ' ' + signers + ')))';
            }
        }
    }

    genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    platformSendOp = () => {
        this.setState({error: null, success: null, displayResult: false});
        let listSigners = [];
        this.state.inputs.forEach(inp => {
            if(!this[inp].current.value || this[inp].current.value.length !== 36){
                this.setState({error: 'Invalid signers'})
                return false;
            }else
                listSigners.push(this[inp].current.value);
        });
        const storage = this.buildStorage(listSigners.sort());
        this.setState({pending: true});
        this._asyncRequest = originateContract(storage, this.state.contract, this.state.selectedWallet, this.passPhrase.current.value).then(
            res => {
                if(res.status === 'SUCCESS'){
                    var interval = null;
                    interval = setInterval(
                        function () {
                            this._asyncRequest = opDetails(res.data.opId).then(response => {
                                if(response.status === 'SUCCESS'){
                                    this.setState({contractId: response.data[response.data.length - 1].receiver, operationContract: res.data.opId, displayResult: true});
                                    const jsonParams = {address: response.data[response.data.length - 1].receiver, operation: this.state.operation, proposalId: this.state.proposalId};
                                    const params = ['"'+response.data[response.data.length - 1].receiver+'"', '"'+this.state.lughContract+'"', '"'+this.state.operation+'"', '"'+this.state.proposalId+'"'];
                                    const entryPoints = TezosContractIntrospector.generateEntryPointsFromParams(this.state.details.parameters);
                                    var i = 0;
                                    while(i < entryPoints.length){
                                        if(entryPoints[i].name === 'createProposal')
                                            break
                                        i++;
                                    }
                                    const genParams = entryPoints[i].generateInvocationPair(...params);
                                    this._asyncRequest = callEntrypoint(genParams, this.state.selectedWallet, this.passPhrase.current.value, 'owner').then(
                                        resp => {
                                            if (resp.status === 'SUCCESS'){
                                                this.setState({pending: false, success: `Proposal sent with operation ID ${resp.data}`});
                                                this._asyncRequest = registerProposal(this.state.proposalId, this.state.selectedWallet, 'owner', this.state.description, this.state.operation, jsonParams).then(
                                                    regResp => {
                                                        console.log(regResp.status);
                                                    }
                                                ).catch(error => {
                                                    console.log(error);
                                                });
                                                this.setState({displayResult: true});
                                                this._asyncRequest = registerOperation(resp.data, this.state.operation, `Proposal ID ${this.state.proposalId} sent from Owner`, this.state.selectedWallet, 'owner', this.state.proposalId).then(
                                                    regResp => {
                                                        console.log(regResp.status);
                                                    }
                                                ).catch(error => {
                                                    console.log(error);
                                                });
                                            }else{
                                                this.setState({pending: false, error: resp.error});
                                            }
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        if(error.toString().split(/[: ]+/).pop() &&  error.toString().split(/[: ]+/).pop() !== 'ciphertext'){
                                            const errorCode = convertErrorCode(this.props.match.params.contract, error.toString().split(/[: ]+/).pop().replace(')', ''));
                                            if(errorCode !== undefined)
                                                this.setState({pending: false, error: `Error : ${errorCode}`});
                                            else
                                                this.setState({pending: false, error: error.toString()});
                                        }else if(error.toString().split(/[: ]+/).pop() &&  error.toString().split(/[: ]+/).pop() === 'ciphertext'){
                                            this.setState({pending: false, error: 'Could not load wallet'});
                                        }else{
                                            this.setState({pending: false, error: error.toString()});
                                        }
                                    });
                                    clearInterval(interval);
                                }else{
                                    console.log(`Fetch failed : ${response.error}`);
                                }
                            }).catch(error => {
                                console.log(`Fetch failed : ${error}`);
                            });
                        }
                        .bind(this),
                        10000
                    );
                }else{
                    this.setState({pending: false, error: res.error, success: null, displayResult: false});
                }
            }
        ).catch(error => {
            console.log(error);
            if(error.toString().split(/[: ]+/).pop() &&  error.toString().split(/[: ]+/).pop() === 'ciphertext'){
                this.setState({pending: false, error: 'Could not load wallet'});
            }else{
                this.setState({pending: false, error: error.toString()});
            }
        });
    }


    ledgerSendOp = async() => {
        try{
            let listSigners = [];
            this.state.inputs.forEach(inp => {
                if(!this[inp].current.value || this[inp].current.value.length !== 36){
                    this.setState({error: 'Invalid signers'})
                    return false;
                }else
                    listSigners.push(this[inp].current.value);
            });
            this.setState({pending: true, info: `Connect your Ledger and approve connection request`})
            const Tezos = new TezosToolkit(this.state.tezosNode)
            const transport = await TransportWebHID.create();
            const ledgerSigner = new LedgerSigner(
                transport, //required
                derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
                true, // prompt optional
                DerivationType.ED25519 // derivationType optional
            );
            Tezos.setProvider({ signer: ledgerSigner });
            const publicKeyHash = await Tezos.signer.publicKeyHash();
            if(this.state.selectedWallet !== publicKeyHash){
                this.setState({error: 'Not connected with the right Ledger', pending: false, success: false})
                return;
            }
            const storage = this.buildStorage(listSigners.sort());
            let operation = await Tezos.contract.originate({ code: this.state.codeTaquito, 
                init: storage,});
            await operation.confirmation()
            const contractId = await (await operation.contract()).address
            this.setState({contractId: contractId, operationContract: operation.opHash, displayResult: true, instructions: 'Follow instruction on your ledger to send proposal...'});
            const jsonParams = {address: contractId, proposalId: this.state.proposalId};
            const params = [contractId, this.state.lughContract, this.state.operation, this.state.proposalId];
            const contract = await Tezos.wallet.at(this.state.details.contract);
            this.setState({info: `Follow the instruction from your Ledger to send operation`});
            operation = await contract.methods.createProposal(...params).send();
            this.setState({info: 'Awaiting confirmation. It may take up to 1 min...'})
            await operation.confirmation()
            this.setState({pending: false, success: `Proposal sent with operation ID ${operation.opHash}`});
            this._asyncRequest = registerProposal(this.state.proposalId, this.state.selectedWallet, 'owner', this.state.description, this.state.operation, jsonParams).then(
                regResp => {
                    console.log(regResp.status);
                }
            ).catch(error => {
                console.log(error);
            });
            this.setState({displayResult: true});
            this._asyncRequest = registerOperation(operation.opHash, this.state.operation, `Proposal ID ${this.state.proposalId} sent from Owner`, this.state.selectedWallet, 'owner', this.state.proposalId).then(
                regResp => {
                    console.log(regResp.status);
                }
            ).catch(error => {
                console.log(error);
            });
            await transport.close();
        }catch(e){
            this.setState({pending: false, info: null, success: false, error: JSON.stringify(e)})
        }
    }

    changePassActive = (wType, publicKeyHash=null) => {
        this.setState({passActive: wType})
        if(publicKeyHash)
            this.setState({selectedWallet: publicKeyHash});
    }

    componentDidMount = () => {
        const op = this.props.match.params.op;
        this.setState({contract: op.replace('set_', '')});
        this._asyncRequest = dictAliases().then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({aliases: res.data, tokenId: res.tokenId});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });

        this.handleFetch(op)
    }

    handleFetch = (op) => {
        this.setState({operation: op, proposalId: `${op}${this.genRanHex(6)}`});
        this._asyncRequest = financialOps('owner').then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({storage: res.data.storage, details: res.data.details, signers: res.data.signers, tezosNode: res.data.tezosNode, lughContract: res.data.lughContract, explorer: res.data.explorer});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
        this._asyncRequest = publishCode(op.replace('set_', '').toLowerCase()).then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({codeMichelson: res.data.michelson, codeTaquito: res.data.taquito});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    buildParameters = (newAddress) => {
        const params = ['"'+newAddress+'"'];
        return this.entryParsing(this.state.details.parameters, this.state.callingPoint, params);
    }

    appendInput() {
        const newInput = `input-${this.state.inputs.length}`;
        this.setState({ inputs: this.state.inputs.concat([newInput]) });
        this[newInput] = React.createRef();
    }

    removeInput() {
        this.setState({ inputs: this.state.inputs.slice(0, -1) });
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <LoadingModal pending={this.state.pending}
                            passActive={this.state.passActive}
                            contract={this.state.contract} />
                    <LoadingLedger pending={this.state.pending}
                            instructions={this.state.instructions}
                            passActive={this.state.passActive}
                            contract={this.state.contract} />
                </React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Wallet" breadcrumbItem={`Create & propose new ${this.state.contract} contract`} />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4"><i className="bx bx-wallet"></i> Create a new {this.state.contract} with signers and send proposal</h4>
                                        <div className="crypto-buy-sell-nav">
                                            <Nav tabs className="nav-tabs-custom" role="tablist">
                                            </Nav>

                                            <TabContent activeTab={this.state.activeTab} className="crypto-buy-sell-nav-content p-4">
                                                <TabPane tabId="1" id="create">
                                                        {this.state.error && <p className="text-danger">{this.state.error}</p>}

                                                        <FormGroup>
                                                            <Label>Set a proposal description</Label>

                                                            <Row>
                                                                <Col sm="12">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">{this.state.descCharLeft} char. left</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control"  maxLength={descLimit} onChange={this.updateDescription} autoComplete="off"/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Set signers</Label>
                                                            {this.state.inputs.map(input =>
                                                                <Col sm="8" key={input}>
                                                                    <InputGroup className="mb-2">
                                                                        <input type="text" className="form-control" ref={this[input]} placeholder="tz1 Signer" autoComplete="off"/>
                                                                    </InputGroup>
                                                                </Col>
                                                            )}
                                                            <br/>
                                                            <Button type="button" color="primary"  onClick={ () => this.appendInput() }>+ Add signer</Button>
                                                            {this.state.inputs.length > 2 && <Button type="button" color="danger"  className="ml-3" onClick={ () => this.removeInput() }>Remove signer</Button>}
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label className="mt-3">Minimum signers required <span className="font-size-11">(* max nb signers required cannot exceed the number of referenced signers)</span></Label>
                                                            <Row>
                                                                <Col sm="2">
                                                                    <Input type="number" className="form-control"  max={this.state.inputs.length} onChange={this.updateLimit} autoComplete="off" value={this.state.limit} />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Limit of simultaneous open proposals <span className="font-size-11">(* max 200)</span></Label>
                                                            <Row>
                                                                <Col sm="2">
                                                                    <Input type="number" className="form-control"  min={1} onChange={this.updateRestriction} autoComplete="off" value={this.state.restriction} />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <br/><hr/><br/>
                                                        <div className="mb-2">
                                                            <Label>Select a signer to publish {this.state.contract} Multisig and send proposal</Label>
                                                            <Row>
                                                                {this.state.storage.value && this.state.storage.value.signers.map(signer =>
                                                                    <Col xl="2" sm="4" key={signer}>
                                                                        <div className="mb-3">
                                                                            <label className="card-radio-label mb-2" onClick={() => {this.state.aliases[signer] && this.state.aliases[signer].walletType === 'ledger'? this.changePassActive('ledger', signer) : this.changePassActive('platform', signer)}}>
                                                                                    <input type="radio" name="currency" id={signer} className="card-radio-input" readOnly/>

                                                                                    <div className="card-radio">
                                                                                        <div>
                                                                                            {this.state.aliases[signer] && this.state.aliases[signer].walletType === 'ledger'?
                                                                                                <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                                                            :
                                                                                                <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                                                            }
                                                                                            <span>{this.state.aliases[signer] && this.state.aliases[signer].name? this.state.aliases[signer].name : truncStringPortion(signer, 8, 6)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                            </label>
                                                                            <div>
                                                                                <p className="text-muted font-size-11 mb-1">{truncStringPortion(signer, 8, 6)}</p>
                                                                                <h5 className="font-size-16 mb-1">{this.state.signers[signer]? Math.round((this.state.signers[signer].total_balance) * 100) / 100 : 0} <TezosLogo width="16" height="16"/></h5>
                                                                                <span className="text-muted">~{this.state.signers[signer] && this.state.signers[signer].total_balance > 0.12? Math.round(this.state.signers[signer].total_balance / 0.12) : 0} operations</span>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </div>
                                                        {this.state.passActive === 'platform' &&
                                                            <FormGroup>
                                                                <Label>Passphrase :</Label>
                                                                <input type="password" ref={this.passPhrase} className="form-control"/>
                                                            </FormGroup>
                                                        }

                                                        {this.state.error && <p className="badge badge-danger font-size-12">{this.state.error}</p>}
                                                        {this.state.success && <p className="badge badge-success font-size-12">{this.state.success}</p>}
                                                        {this.state.displayResult &&
                                                            <>
                                                                <br/>
                                                                <p className="font-size-14 mt-1">
                                                                    <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                                                                    Contract ID <b className="mr-1">{this.state.contractId}</b>
                                                                    is published for <b className="mr-1">{this.state.operation}</b>
                                                                    with operation ID <b className="font-size-12 mr-1">{this.state.operationContract}</b> sent from
                                                                    <b className="ml-1">Owner</b> with signer
                                                                    <b className="ml-1">{this.state.aliases[this.state.selectedWallet] && this.state.aliases[this.state.selectedWallet].name? this.state.aliases[this.state.selectedWallet].name : truncStringPortion(this.state.selectedWallet, 8, 6)}</b>
                                                                </p>
                                                            </>
                                                        }

                                                        {this.state.passActive === 'platform' &&
                                                            <>
                                                            {this.state.pending?
                                                                <p><b className="text-danger">Do not refresh!</b> Creating & propose Multisig. <b>It may take up to 1 min. Please wait...</b></p>
                                                            :
                                                                <div className="text-center mt-4">
                                                                    <Button type="button" disabled={this.state.success? true : false} color="success" onClick={() => {this.platformSendOp()}}>Create & publish new Multisig</Button>
                                                                    <p className="text-center font-size-10 mt-2">*Note it may need up to 1 min</p>
                                                                </div>
                                                            }
                                                            </>
                                                        }
                                                        {this.state.passActive === 'ledger' &&
                                                            <>
                                                            {this.state.pending?
                                                                <p><b className="text-danger">Do not refresh!</b> {this.state.instructions}.</p>
                                                            :
                                                                <div className="text-center mt-4">
                                                                    <Button type="button" disabled={this.state.success? true : false} color="primary" onClick={() => {this.ledgerSendOp()}}>Create & publish new Multisig</Button>
                                                                    <p className="text-center font-size-10 mt-2">*Note it may need up to 1 min</p>
                                                                </div>
                                                            }
                                                            </>
                                                        }
                                                </TabPane>
                                            </TabContent>
                                        </div>

                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ChangeMultisig;