import React, { useEffect, useCallback } from "react";
import { fetchProposals } from "../../helpers/api";
import { Link } from "react-router-dom";
import ProposalsStore from "../ProposalsStore";
import TezosLogo from "../Common/TezosLogo";

const CONTRACTS = ["minter", "administrator", "reserve", "owner"];
const SidebarFA2Proposals = () => {
  const store = ProposalsStore.useContainer();

  const getSum = () => {
    let sum = 0;
    CONTRACTS.forEach((a) => {
      sum += store.proposals[a] || 0;
    });
    return sum;
  };

  const loadProposals = useCallback(async () => {
    fetchProposals()
      .then((res) => {
        if (res.status === "SUCCESS") {
          store.setProposals((prev) => {
            return {
              ...prev,
              administrator: res.data.administrator,
              minter: res.data.minter,
              owner: res.data.owner,
              reserve: res.data.reserve,
              lastGet: Math.max(new Date().getTime(), prev.lastGet),
            };
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [CONTRACTS]); //eslint-disable-line

  useEffect(() => {
    let interval = setInterval(() => {
      loadProposals();
    }, 30000);
    return () => clearInterval(interval);
  }, [loadProposals]);

  useEffect(() => {
    if (new Date().getTime() - store.proposals.lastGet > 10 * 1000) {
      loadProposals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <Link to="/#" className="has-arrow  waves-effect">
        <i>
          <TezosLogo width="16" height="16" />
        </i>
        {getSum() > 0 && (
          <span className="badge badge-pill badge-success float-right">
            {getSum()}
          </span>
        )}
        <span>FA2 Proposals</span>
      </Link>
      <ul className="sub-menu">
        <li>
          <Link to="/proposals/administrator">
            {store.proposals.administrator > 0 && (
              <span className="badge badge-pill badge-success float-right">
                {store.proposals.administrator}
              </span>
            )}
            Administrator
          </Link>
        </li>
        <li>
          <Link to="/proposals/minter">
            {store.proposals.minter > 0 && (
              <span className="badge badge-pill badge-success float-right">
                {store.proposals.minter}
              </span>
            )}
            Minter
          </Link>
        </li>
        <li>
          <Link to="/proposals/reserve">
            {store.proposals.reserve > 0 && (
              <span className="badge badge-pill badge-success float-right">
                {store.proposals.reserve}
              </span>
            )}
            Reserve
          </Link>
        </li>
        <li>
          <Link to="/proposals/owner">
            {store.proposals.owner > 0 && (
              <span className="badge badge-pill badge-success float-right">
                {store.proposals.owner}
              </span>
            )}
            Owner
          </Link>
        </li>
      </ul>
    </li>
  );
};

export default SidebarFA2Proposals;
