import {
  dictAliases,
  listAdresses,
  rpcNode,
  transferXTZ,
  registerOperation,
} from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import TezosLogo from '../../components/Common/TezosLogo.js';
import LoadingTx from './Modals/LoadingTx.js';
import SuccessTx from './Modals/SuccessTx.js';
import { TezosToolkit } from '@taquito/taquito';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const derivationPath = "44'/1729'/0'/0'/0'";

class TransferXTZ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiSigs: [],
      wallets: [],
      aliases: [],
      loading: true,
      selectedWallet: {},
      keystore: null,
      connected: false,
      pending: false,
      success: null,
      error: null,
      tezosNode: null,
      receiver: null,
      currentStep: 1,
      walletName: null,
      receiverName: null,
      xtzAmount: 0,
      dropdownOpen: false,
      dropdownRcv: false,
    };
    this.passPhrase = React.createRef();
    this.amount = React.createRef();
    this.receiver = React.createRef();
    this.amount = React.createRef();
    this.cents = React.createRef();
  }

  componentDidMount = () => {
    this._asyncRequest = listAdresses()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let resWallets = res.data.wallets;
          resWallets.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
          this.setState({
            wallets: resWallets,
            multiSigs: res.data.multiSigs,
            loading: false,
          });
          this._asyncRequest = rpcNode()
            .then((result) => {
              this.setState({ tezosNode: result.data });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleDropdownRcv = () => {
    this.setState({ dropdownRcv: !this.state.dropdownRcv });
  };

  changePassActive = (wallet) => {
    this.setState({
      passActive: wallet.walletType,
      selectedWallet: wallet.publicKeyHash,
      walletName: wallet.name,
    });
    this.setState({ currentStep: 2 });
  };

  selectReceiver = (wallet) => {
    this.setState({
      receiver: wallet.publicKeyHash,
      receiverName: wallet.name,
      currentStep: 4,
    });
  };

  ledgerSendOp = async () => {
    try {
      this.setState({
        pending: true,
        info: `Connect your Ledger and approve connection request`,
      });
      const Tezos = new TezosToolkit(this.state.tezosNode);
      const transport = await TransportWebHID.create();
      const ledgerSigner = new LedgerSigner(
        transport, //required
        derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
        true, // prompt optional
        DerivationType.ED25519, // derivationType optional
      );
      Tezos.setProvider({ signer: ledgerSigner });
      const publicKeyHash = await Tezos.signer.publicKeyHash();
      if (this.state.selectedWallet !== publicKeyHash) {
        this.setState({
          error: 'Not connected with the right Ledger',
          pending: false,
          success: false,
        });
        return;
      }
      this.setState({
        info: `Follow the instruction from your Ledger to send operation`,
      });
      let operation = await Tezos.contract.transfer({
        to: this.state.receiver,
        amount: this.state.xtzAmount,
      });
      this.setState({
        info: 'Awaiting confirmation. It may take up to 1 min...',
      });
      await operation.confirmation();
      this.setState({
        pending: false,
        success: `Operation ${operation.opHash} sent`,
      });
      this._asyncRequest = registerOperation(
        operation.opHash,
        'ꜩ Transfer',
        `Transfer ${this.state.xtzAmount}tz to ${truncStringPortion(
          this.state.receiver,
          8,
          6,
        )}`,
        this.state.selectedWallet,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      await transport.close();
    } catch (e) {
      console.log(e);
      this.setState({
        pending: false,
        info: null,
        success: false,
        error: JSON.stringify(e),
      });
    }
  };

  platformSendOp = () => {
    if (!this.passPhrase.current.value)
      this.setState({ error: 'Wrong password' });
    else if (!this.state.xtzAmount || !this.state.receiver)
      this.setState({ error: 'Missing parameters' });
    else {
      this.setState({ pending: true, error: null, success: null });
      this._asyncRequest = transferXTZ(
        this.state.selectedWallet,
        this.passPhrase.current.value,
        Math.round(parseFloat(this.state.xtzAmount) * 1000000),
        this.state.receiver,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            this.setState({
              pending: false,
              success: `Operation ${res.data} sent`,
            });
            this._asyncRequest = registerOperation(
              res.data,
              'ꜩ Transfer',
              `Transfer ${this.state.xtzAmount}tz to ${truncStringPortion(
                this.state.receiver,
                8,
                6,
              )}`,
              this.state.selectedWallet,
            )
              .then((response) => {
                console.log(response.status);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.setState({ pending: false, error: res.error });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ pending: false, error: error.toString() });
        });
    }
  };

  handleChangeInput = (event) => {
    this.cents.current.value = event.target.value.slice(0, 2);
  };

  setAmount = () => {
    this.setState({ currentStep: 3 });
    if (this.cents.current.value) {
      this.setState({
        xtzAmount: this.amount.current.value + '.' + this.cents.current.value,
      });
    } else {
      this.setState({ xtzAmount: this.amount.current.value });
    }
  };

  changeStep = (step) => {
    this.setState({ currentStep: step, success: null, error: null });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="mHeight">
            <LoadingTx
              pending={this.state.pending}
              info={this.state.info}
              passActive={this.state.passActive}
            />
            <SuccessTx
              success={this.state.success}
              passActive={this.state.passActive}
              amount={this.state.xtzAmount}
              currency={'XTZ'}
              sender={this.state.selectedWallet}
              receiver={this.state.receiver}
              aliases={this.state.aliases}
            />
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Wallets" breadcrumbItem="Transfer xtz" />
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading wallets...{' '}
                </Link>
              </div>
            ) : (
              <>
                {this.state.error && (
                  <p className="badge badge-danger font-size-12">
                    {this.state.error}
                  </p>
                )}
                {this.state.success && (
                  <p className="badge badge-success font-size-12">
                    {this.state.success}
                  </p>
                )}
                {this.state.currentStep !== 1 ? (
                  <div
                    className="media mb-2 mt-2"
                    onClick={() => {
                      this.changeStep(1);
                    }}
                  >
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        1
                      </span>
                    </div>
                    <div className="media-body">
                      <p className="mb-0">
                        Sender <b>{this.state.walletName}</b> (
                        {truncStringPortion(this.state.selectedWallet, 8, 6)})
                        selected
                      </p>
                      <span className="text-muted font-size-10 mt-0">
                        Click to change sender
                      </span>
                      <Row>
                        {this.state.wallets &&
                          this.state.wallets.map(
                            (wallet) =>
                              this.state.selectedWallet ===
                                wallet.publicKeyHash && (
                                <Col
                                  xl="4"
                                  sm="12"
                                  key={`sel-${wallet.publicKeyHash}`}
                                >
                                  <div className="mb-3">
                                    <label className="card-radio-label mb-2">
                                      <div className="card-radio p-2">
                                        <div>
                                          {wallet.walletType === 'ledger' ? (
                                            <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                          ) : (
                                            <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                          )}
                                          <span>{wallet.name}</span>
                                          <p className="text-muted font-size-11 mb-1">
                                            {wallet.publicKeyHash}
                                          </p>
                                          <p className="font-size-16 mb-1">
                                            {Math.round(
                                              wallet.details.total_balance *
                                                100,
                                            ) / 100}{' '}
                                            <TezosLogo width="14" height="14" />
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </Col>
                              ),
                          )}
                      </Row>
                    </div>
                  </div>
                ) : (
                  <div className="media mb-2 mt-2">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        1
                      </span>
                    </div>
                    <div className="media-body">
                      <p className="mt-1">
                        <b>Select sender</b>
                      </p>
                    </div>
                  </div>
                )}
                {this.state.currentStep === 1 && (
                  <Row className="mb-3">
                    <Col md="12">
                      {this.state.wallets.length > 0 && (
                        <Dropdown
                          isOpen={this.state.dropdownOpen}
                          toggle={this.toggleDropdown}
                        >
                          <DropdownToggle caret color="primary">
                            Select a sending wallet
                          </DropdownToggle>
                          <p className="text-muted font-size-10 mt-1">
                            You do not see the sending wallet you want? Make
                            sure it is revealed with a positive XTZ balance
                          </p>
                          <DropdownMenu>
                            {this.state.wallets &&
                              this.state.wallets.map(
                                (wallet) =>
                                  wallet.walletType !== 'client' &&
                                  wallet.details.total_balance > 0 && (
                                    <DropdownItem
                                      onClick={() => {
                                        this.changePassActive(wallet);
                                      }}
                                      key={wallet.publicKeyHash}
                                    >
                                      {wallet.walletType === 'ledger' ? (
                                        <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                      ) : (
                                        <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                      )}
                                      <span>{wallet.name}</span> |{' '}
                                      <span className="text-muted font-size-10 mr-1">
                                        {truncStringPortion(
                                          wallet.publicKeyHash,
                                          8,
                                          6,
                                        )}
                                      </span>
                                      |
                                      <span className="ml-1 mr-1">
                                        {wallet.details.total_balance
                                          ? Math.round(
                                              wallet.details.total_balance *
                                                100,
                                            ) / 100
                                          : 0}{' '}
                                        <TezosLogo width="14" height="14" /> |
                                        <span className="text-muted font-size-10 ml-1">
                                          ~
                                          {wallet.details.total_balance > 0.05
                                            ? Math.round(
                                                wallet.details.total_balance /
                                                  0.05,
                                              )
                                            : 0}{' '}
                                          operations
                                        </span>
                                      </span>
                                    </DropdownItem>
                                  ),
                              )}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
            {this.state.currentStep > 2 ? (
              <div
                className="media mb-2 mt-2"
                onClick={() => {
                  this.changeStep(2);
                }}
              >
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    2
                  </span>
                </div>
                <div className="media-body">
                  <p className="mb-0">
                    Set transfer for{' '}
                    <b>
                      {this.state.xtzAmount}
                      <TezosLogo width="13" height="13" />
                    </b>
                  </p>
                  <span className="text-muted font-size-10 mt-0">
                    Click to change amount
                  </span>
                </div>
              </div>
            ) : (
              <div className="media mb-2 mt-2">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    2
                  </span>
                </div>
                <div className="media-body">
                  <p className="mt-1">
                    <b>
                      Set <TezosLogo width="13" height="13" /> amount
                    </b>
                  </p>
                </div>
              </div>
            )}
            {this.state.currentStep === 2 && (
              <Row>
                <Col sm="8">
                  <InputGroup className="mb-2">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text">
                        Amount in <TezosLogo width="13" height="13" />
                      </span>
                    </InputGroupAddon>
                    <input
                      type="number"
                      ref={this.amount}
                      placeholder="XTZ Amount"
                      min="1"
                      className="form-control text-right w-50 mr-0"
                    />
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text">.</span>
                    </InputGroupAddon>
                    <input
                      type="number"
                      ref={this.cents}
                      placeholder="00"
                      min="0"
                      max="99"
                      maxLength="2"
                      className="form-control ml-0"
                      onChange={this.handleChangeInput}
                    />
                  </InputGroup>
                </Col>
                <Col sm="4">
                  <Button
                    type="button"
                    color="success"
                    onClick={() => {
                      this.setAmount();
                    }}
                  >
                    Set amount
                  </Button>
                </Col>
              </Row>
            )}
            {this.state.currentStep > 3 ? (
              <div
                className="media mb-2 mt-2"
                onClick={() => {
                  this.changeStep(3);
                }}
              >
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    3
                  </span>
                </div>
                <div className="media-body">
                  <p className="mb-0">
                    Receiver <b>{this.state.receiverName}</b> (
                    {truncStringPortion(this.state.receiver, 8, 6)}) selected
                  </p>
                  <span className="text-muted font-size-10 mt-0">
                    Click to change receiver
                  </span>
                  <Row>
                    {this.state.wallets &&
                      this.state.wallets.map(
                        (wallet) =>
                          this.state.receiver === wallet.publicKeyHash && (
                            <Col
                              xl="4"
                              sm="12"
                              key={`rcv-${wallet.publicKeyHash}`}
                            >
                              <div className="mb-3">
                                <label className="card-radio-label mb-2">
                                  <div className="card-radio p-2">
                                    <div>
                                      {wallet.walletType === 'ledger' ? (
                                        <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                      ) : (
                                        <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                      )}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: wallet.name.replace(
                                            '(',
                                            '<br/>(',
                                          ),
                                        }}
                                      ></span>
                                      <p className="text-muted font-size-11 mb-1">
                                        {wallet.publicKeyHash}
                                      </p>
                                      <p className="font-size-16 mb-1">
                                        {Math.round(
                                          wallet.details.total_balance * 100,
                                        ) / 100}{' '}
                                        <TezosLogo width="14" height="14" />
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          ),
                      )}
                  </Row>
                </div>
              </div>
            ) : (
              <div className="media mb-2 mt-2">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    3
                  </span>
                </div>
                <div className="media-body">
                  <p className="mt-1">
                    <b>Selected receiver</b>
                  </p>
                </div>
              </div>
            )}
            {this.state.currentStep === 3 && (
              <Row className="mb-3">
                <Col md="12">
                  {this.state.wallets.length > 0 && (
                    <Dropdown
                      isOpen={this.state.dropdownRcv}
                      toggle={this.toggleDropdownRcv}
                    >
                      <DropdownToggle caret color="primary">
                        Select a receiving wallet
                      </DropdownToggle>
                      <DropdownMenu>
                        {this.state.wallets &&
                          this.state.wallets.map(
                            (wallet) =>
                              wallet.walletType !== 'client' && (
                                <DropdownItem
                                  key={'rcv_' + wallet.publicKeyHash}
                                  onClick={() => {
                                    this.selectReceiver(wallet);
                                  }}
                                >
                                  {wallet.walletType === 'ledger' ? (
                                    <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                  ) : (
                                    <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                  )}
                                  <span>{wallet.name}</span> |{' '}
                                  <span className="text-muted font-size-10 mr-1">
                                    {truncStringPortion(
                                      wallet.publicKeyHash,
                                      8,
                                      6,
                                    )}
                                  </span>
                                  |
                                  <span className="ml-1 mr-1">
                                    {wallet.details.total_balance
                                      ? Math.round(
                                          wallet.details.total_balance * 100,
                                        ) / 100
                                      : 0}{' '}
                                    <TezosLogo width="14" height="14" /> |
                                    <span className="text-muted font-size-10 ml-1">
                                      ~
                                      {wallet.details.total_balance > 0.05
                                        ? Math.round(
                                            wallet.details.total_balance / 0.05,
                                          )
                                        : 0}{' '}
                                      operations
                                    </span>
                                  </span>
                                </DropdownItem>
                              ),
                          )}
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </Col>
              </Row>
            )}
            <div className="media mb-2 mt-2">
              {this.state.success ? (
                <div
                  className="media mb-2 mt-2"
                  onClick={() => {
                    this.changeStep(3);
                  }}
                >
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      4
                    </span>
                  </div>
                  <div className="media-body">
                    <p className="mt-1">
                      Operation <b>sent successfully</b>
                    </p>
                  </div>
                </div>
              ) : this.state.error ? (
                <div className="media mb-2 mt-2">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-danger rounded-circle font-size-16">
                      4
                    </span>
                  </div>
                  <div className="media-body">
                    <p className="mt-1">
                      <b>Operation could not be sent</b>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="media mb-2 mt-2">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      4
                    </span>
                  </div>
                  <div className="media-body">
                    <p className="mt-1">
                      <b>Send operation</b>
                    </p>
                  </div>
                </div>
              )}
            </div>
            {this.state.currentStep === 4 && (
              <>
                {this.state.passActive === 'platform' &&
                  !this.state.success && (
                    <FormGroup className="mt-4">
                      <InputGroup className="mb-2">
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">Passphrase</span>
                        </InputGroupAddon>
                        <input
                          type="password"
                          ref={this.passPhrase}
                          className="form-control"
                        />
                      </InputGroup>
                    </FormGroup>
                  )}
                {this.state.passActive === 'platform' && (
                  <>
                    {this.state.pending ? (
                      <p>Processing operation, please wait...</p>
                    ) : (
                      !this.state.success && (
                        <div className="text-center mt-4 mb-2">
                          <Button
                            type="button"
                            color="success"
                            onClick={() => {
                              this.platformSendOp();
                            }}
                          >
                            Send <TezosLogo width="13" height="13" />
                          </Button>
                        </div>
                      )
                    )}
                  </>
                )}
                {this.state.passActive === 'ledger' && (
                  <>
                    {this.state.pending ? (
                      <p>Follow further instructions on your ledger...</p>
                    ) : (
                      !this.state.success && (
                        <div className="text-center mt-4 mb-2">
                          <Button
                            type="button"
                            color="success"
                            onClick={() => {
                              this.ledgerSendOp();
                            }}
                          >
                            Send <TezosLogo width="13" height="13" />
                          </Button>
                        </div>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TransferXTZ;
