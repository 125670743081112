import React, { Component } from "react";
import { Modal, FormGroup, Button } from "reactstrap";
import { truncStringPortion } from '../../../helpers/formatter.js';

class SuccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true
        };
    }

  render() {
      return (
        <React.Fragment>
          <Modal
            size="lg"
            isOpen={this.props.displayResult && this.state.display}
            centered={true}
            backdrop="static"
            wrapClassName="modal-balance"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0 text-muted"
                id="myLargeModalLabel"
              >Proposal {this.props.isAccept? 'approval' : 'reject'} sent</h5>
            </div>
            {this.props.displayResult &&
            <div className="modal-body">
              <FormGroup>
                    <p className="font-size-14 mt-1">
                        <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                        Proposal ID <b className="font-size-12 mr-1">{this.props.proposalId}</b>
                        is <b>{this.props.isAccept? 'accepted' : 'rejected'}</b> by
                        <b className="ml-1">{this.props.aliases[this.props.selectedWallet] && this.props.aliases[this.props.selectedWallet].name? this.props.aliases[this.props.selectedWallet].name : truncStringPortion(this.props.selectedWallet, 8, 6)}</b>
                    </p>
                    <p className="text-muted mt-1">
                        It may take up to 1 min. before validation on the network
                    </p>
                    <Button className="mt-2" type="button" color="success"  onClick={() => {this.setState({display: false})}}>Close</Button>
              </FormGroup>
            </div>}
          </Modal>
        </React.Fragment>
      )}
}

export default SuccessModal