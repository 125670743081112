import React, { Component } from "react";

import { Modal, Row, Col, FormGroup, Label } from "reactstrap";
import { regenerateCredentials } from '../../../helpers/api';

class CredentialsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: null
        };
    }

    copyToken = () => {
        navigator.clipboard.writeText(this.props.client.token);
        this.setState({ copied: 'token' });
        setTimeout(
          function () {
            this.setState({ copied: null });
          }
            .bind(this),
          2000
        );
    }

    copySecret = () => {
        navigator.clipboard.writeText(this.props.client.secret);
        this.setState({ copied: 'secret' });
        setTimeout(
          function () {
            this.setState({ copied: null });
          }
            .bind(this),
          2000
        );
    }

    regenerateClientCredentials = () => {
      this._asyncRequest = regenerateCredentials(this.props.client.publicKeyHash).then(
          res => {
              if (res.status === 'SUCCESS') {
                  this.props.loadClient()
              } else {
                  this.setState({ error: res.error });
                  console.log(res.error);
              }
          }
      ).catch(error => {
          this.setState({ error: error.toString() });
          console.log(error);
      });
    }


  render() {
      return (
        <React.Fragment>
          <Modal
            size="md"
            isOpen={this.props.credentialsModal}
            centered={true}
            backdrop="static"
            wrapClassName="modal-balance"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="myLargeModalLabel"
              >Client credentials</h5>
            </div>
            <div className="modal-body">
              {this.props.error && <p className="text-danger">{this.props.error}</p>}
              {this.props.success && <p className="text-success">{this.props.success}</p>}
              <FormGroup>
                    <Label>X-Access-Token</Label>

                    <Row>
                        <Col sm="12">
                            <p className="text-muted mb-2 text-break">
                                {this.props.client.token}
                                {this.state.copied !== 'token' ?
                                      <span className="badge badge-primary clickable ml-2" onClick={() => { this.copyToken() }}>copy</span>
                                :
                                      <span className="badge text-success pointer ml-2">copied!</span>
                                }
                            </p>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Label>Secret</Label>

                    <Row>
                        <Col sm="12">
                            <p className="text-muted mb-2 text-break">
                                {this.props.client.secret}
                                {this.state.copied !== 'secret' ?
                                      <span className="badge badge-primary clickable ml-2" onClick={() => { this.copySecret() }}>copy</span>
                                :
                                      <span className="badge text-success pointer ml-2">copied!</span>
                                }
                            </p>
                        </Col>
                    </Row>
                </FormGroup>
                <Row className="mt-3">
                    <Col sm="4">
                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" onClick={() => this.props.toggleCredentialsModal()}>Close</button>
                    </Col>
                    <Col sm="6">
                        <button className="btn btn-warning btn-block waves-effect waves-light" type="submit" onClick={() => {if(window.confirm('Regenerate Secret and X-Access-Token?')){this.regenerateClientCredentials()};}}>Regenerate</button>
                        <p className="text-muted font-size-11">If you click Regenerate, actual credentials will not work anymore</p>
                    </Col>
                </Row>
            </div>
          </Modal>
        </React.Fragment>
      )}
}

export default CredentialsModal