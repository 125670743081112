import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody, CardFooter } from 'reactstrap';

import EditAlert from './edit-alert';

const CardNotification = ({ notification, loadNotifications }) => {
  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            <h5 className="font-size-15">
              <Link to={`/alert/${notification.id}`} className="text-dark">
                {notification.name}
              </Link>
            </h5>
            <p className="text-muted">{`${notification.cnt} emails`}</p>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <EditAlert
              loadNotifications={loadNotifications}
              notification={notification}
            />
            <Link to={`/alert/${notification.id}`} className="btn btn-lg">
              <i className="bx bx-search"></i>
            </Link>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardNotification;
