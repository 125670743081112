import React, { Component } from "react";
import SVGLogo from '../../../components/Common/SVGLogo.js';
import TezosLogo from '../../../components/Common/TezosLogo.js';
import EthereumLogo from '../../../components/Common/EthereumLogo';
import { Modal, FormGroup, Button } from "reactstrap";
import { truncStringPortion } from '../../../helpers/formatter.js';

class SuccessTx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true
        };
    }

  render() {
      return (
        <React.Fragment>
          <Modal
            size="md"
            isOpen={this.props.success && this.state.display}
            centered={true}
            backdrop="static"
            wrapClassName="modal-balance"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0 text-muted"
                id="myLargeModalLabel"
              >Transaction successfully sent</h5>
            </div>
            {this.props.success &&
            <div className="modal-body">
              <FormGroup>
                    <p className="mt-1 font-size-14">
                        <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                        Transaction of <b className="mr-1">{this.props.amount} {this.props.currency === 'XTZ'? <TezosLogo width="14" height="14"/> : this.props.currency === 'ETH'? <EthereumLogo width="14" height="14"/> : <SVGLogo width="14" height="14"/>}</b>
                        sent from <b className="mr-1">{this.props.aliases[this.props.sender] && this.props.aliases[this.props.sender].name? this.props.aliases[this.props.sender].name : truncStringPortion(this.props.sender, 8, 6)}</b>
                        to <b>{this.props.aliases[this.props.receiver] && this.props.aliases[this.props.receiver].name? this.props.aliases[this.props.receiver].name : truncStringPortion(this.props.receiver, 8, 6)}</b>
                    </p>
                    <p className="text-muted mt-1">
                        It may take up to 1 min. before validation on the network
                    </p>
                    <Button className="mt-2" type="button" color="success" onClick={() => {this.setState({display: false})}}>Close</Button>
              </FormGroup>
            </div>}
          </Modal>
        </React.Fragment>
      )}
}

export default SuccessTx