import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";

import BeatLoader from "react-spinners/BeatLoader";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Card
import CardContact from "./card-contact";

import { getUsers } from "../../helpers/api";

import NewUser from "./new-user";

const Account = ({ user: self, history }) => {
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);

  const [clientId, setClientId] = useState([]);
  const [clientName, setClientName] = useState([]);

  const loadUsers = async () => {
    setLoading(true);

    let search = history.location.search;
    let params = new URLSearchParams(search);
    let cId = params.get("clientId");
    setClientId(cId);
    let cName = params.get("clientName");
    setClientName(cName);

    let res = await getUsers();
    if (res.status === "SUCCESS") {
      setUsers(
        res.data.map((u) => {
          return {
            id: u.id,
            name: `${u.firstname} ${u.lastname}`,
            designation: u.email,
            color: "primary",
            skills: [{ name: u.role }],
            client: u.client,
          };
        })
      );
    } else {
    }

    setLoading(false);
  };

  // eslint-disable-next-line
  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Administration"
            breadcrumbItem={
              clientId ? `Account for client ${clientName}` : `Lugh accounts`
            }
          />
          <div style={{ marginBottom: "20px" }}>
            <NewUser
              loadUsers={loadUsers}
              clientId={clientId}
              clientName={clientName}
            />
          </div>

          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                minHeight: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <BeatLoader
                  size={15}
                  margin={2}
                  loading={true}
                  color={"#2a3042"}
                />
              </div>
            </div>
          )}

          {!loading && (
            <Row>
              {users.map(
                (user, key) =>
                  ((clientId === null && !user.client) ||
                    (clientId &&
                      user.client &&
                      clientId === user.client.publicKeyHash)) && (
                    <CardContact
                      user={user}
                      key={"_user_" + key}
                      loadUsers={loadUsers}
                      canDelete={user.id !== self.id}
                    />
                  )
              )}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default withRouter(connect(mapStatetoProps, null)(Account));
