import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media, Button, Label } from "reactstrap";
import Loader from 'react-loader-spinner';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Can from '../../components/Can';
import { connect } from 'react-redux';

//i18n
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import EthereumLogo from '../../components/Common/EthereumLogo.js';
import Idbank from './components/Idbank';
import Fees from './components/Fees';
import NewWallet from "./components/NewWallet";
import { detailsClient, deleteWalletClient } from '../../helpers/api';
import CredentialsModal from "./Modals/CredentialsModal.js";
import Logo from "./components/update/Logo";
import EmailModal from "./components/update/Email";
import NameModal from "./components/update/Name";
import VatNumberModal from "./components/update/VatNumber";
import ReceivingBankModal from "./components/update/ReceivingBank";
import IpAddressModal from "./components/update/IpAddress";
import PolygonLogo from "../../components/Common/PolygonLogo";
import logoLugh from "../../assets/images/logo-dark.png";

class ClientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            wallets: [],
            idbanks: [],
            credentialsModal: false,
            loading: true,
            emailModal: false,
            nameModal: false,
            vatNumberModal: false,
            receivingBankModal: false,
            ipAddressModal: false,
            walletModal: false,
            displayIban: false,
            displayFeesModel: false,
            volumes: {},
            oca: {},
        };
    }

    toggleCredentialsModal = () => {
        this.setState({ credentialsModal:  !this.state.credentialsModal})
    }

    toggleEmailModal = () => {
        this.setState({ emailModal:  !this.state.emailModal})
    }

    toggleNameModal = () => {
        this.setState({ nameModal:  !this.state.nameModal})
    }

    toggleVatNumberModal = () => {
        this.setState({ vatNumberModal:  !this.state.vatNumberModal})
    }

    toggleReceivingBankModal = () => {
        this.setState({ receivingBankModal:  !this.state.receivingBankModal})
    }

    toggleIpAddressModal = () => {
        this.setState({ ipAddressModal:  !this.state.ipAddressModal})
    }

    toggleWalletModal = () => {
        this.setState({ walletModal:  !this.state.walletModal})
    }

    toggleIban = () => {
        this.setState({ displayIban:  !this.state.displayIban})
    }

    toggleFeesModel = () => {
        this.setState({ displayFeesModel:  !this.state.displayFeesModel})
    }

    loadClient = async() => {
        this.setState({loading: true})
        const res = await detailsClient(this.props.match.params.publicKeyHash)
        this.setState({client: res.data.client, wallets: res.data.wallets, idbanks: res.data.idbanks, volumes: res.data.volumes, oca: res.data.oca, loading: false})
    }

    componentDidMount = () => {
        this.loadClient()
    }

    rmWalletClient = async(publicKeyHash) => {
        await deleteWalletClient(publicKeyHash)
        this.loadClient()
    }

    render() {
        return (
            <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            {this.state.loading?
                                <Loader type="Puff" color="#545454" height={50} width={50}/>
                            :
                                <Row>
                                    <Col xl="4">
                                        <Card className="overflow-hidden">
                                            <div className="wcard-client p-2">
                                                <Logo publicKeyHash={this.state.client.publicKeyHash}
                                                      loadClient={this.loadClient}/>
                                                <img style={{display: "block", maxWidth: "200px", maxHeight: "120px"}}
                                                    src={this.state.client.logo? `https://premiere.infura-ipfs.io/ipfs/${this.state.client.logo}` : logoLugh} 
                                                    alt="logo"/>
                                            </div>
                                            <CardBody className="pt-2">
                                                <Can role={this.props.user.role} perform="admin:access" yes={() => {
                                                    return (
                                                        <Link to={`/account?clientId=${this.state.client.publicKeyHash}&clientName=${this.state.client.name}`} className="badge badge-success font-size-14 py-2 px-4">
                                                            Manage {this.state.client.name} accounts
                                                        </Link>)
                                                }} />
                                                <Row>
                                                    <Col sm="6">
                                                        <h5 className="font-size-15 text-truncate mt-4 p-relative">
                                                            {this.state.client.name}
                                                            <small className="editor" onClick={this.toggleNameModal}><i className="bx bx-edit-alt"></i></small>
                                                        </h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">
                                                            {this.state.client.vatNumber? this.state.client.vatNumber : 'No VAT number provided'}
                                                            <small className="editor" onClick={this.toggleVatNumberModal}><i className="bx bx-edit-alt"></i></small>
                                                        </p>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <h5 className="font-size-15">Email</h5>
                                                                    <p className="text-muted mb-0 font-size-11 p-relative">
                                                                        {this.state.client.email}
                                                                        <span className="editor" onClick={this.toggleEmailModal}><i className="bx bx-edit-alt"></i></span>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col sm="12">
                                                        <h5 className="font-size-15 text-truncate mt-4">IP addresses</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            {this.state.client.ipAddress? this.state.client.ipAddress : 'No IP address'}
                                                            <span className="editor" onClick={this.toggleIpAddressModal}><i className="bx bx-edit-alt"></i></span>
                                                        </p>
                                                        <h5 className="font-size-15 text-truncate mt-4">Bank account on which client sends funds</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            {this.state.client.receivingBank? this.state.client.receivingBank : 'No bank account referenced'}
                                                            <span className="editor" onClick={this.toggleReceivingBankModal}><i className="bx bx-edit-alt"></i></span>
                                                        </p>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p className="text-muted mb-0 font-size-11 mb-2">Display API credentials</p>
                                                                    <Button type="button" color="info" onClick={this.toggleCredentialsModal}>API credentials</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p className="text-muted mb-0 font-size-11 mb-2">Add new wallet for client</p>
                                                                    <Button type="button" color="info" onClick={this.toggleWalletModal}>New Wallet</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p className="text-muted mb-0 font-size-11 mb-2">Add a new IBAN</p>
                                                                    <Button type="button" color="info" onClick={() => {this.toggleIban()}}>
                                                                        Add new IBAN
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p className="text-muted mb-0 font-size-11 mb-2">Add a new Fees model</p>
                                                                    <Button type="button" color="info" onClick={() => {this.toggleFeesModel()}}>
                                                                        Add new Fees model
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        {this.state.walletModal && <NewWallet publicKeyHash={this.state.client.publicKeyHash} loadClient={this.loadClient} />}
                                        {this.state.wallets.map(wallet =>
                                            <Card className="mini-stats-wid" key={`${wallet.chain}-${wallet.publicKeyHash}`}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">
                                                                {wallet.chain === 'ethereum'? 'Ethereum ERC20': wallet.chain === 'polygon'? 'Polygon ERC20' : 'Tezos FA2'} Balance
                                                                <br/>
                                                                <strong>
                                                                    {wallet.name}
                                                                </strong>
                                                                <br/>
                                                                <small>
                                                                    {wallet.publicKeyHash}
                                                                </small>
                                                            </p>
                                                            <h4 className="mb-0">
                                                                <SVGLogo/> {wallet.balance.toLocaleString()}
                                                            </h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title" style={{paddingLeft: "6px"}}>
                                                                {wallet.chain === 'ethereum'?
                                                                    <EthereumLogo width="20" height="20" />
                                                                :
                                                                    wallet.chain === 'tezos'?
                                                                    <TezosLogo width="20" height="20" />
                                                                    :
                                                                    <PolygonLogo width="20" height="20" />
                                                                }
                                                            </span>
                                                        </div>
                                                        {wallet.balance === 0 && <span className="text-secondary float-right" style={{cursor: "pointer"}}
                                                                onClick={() => {if(window.confirm('Remove wallet?')){this.rmWalletClient(wallet.publicKeyHash)};}}>
                                                            <i className="bx bx-trash"></i>
                                                        </span>}
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        )}
                                        <Idbank client={this.state.client} idbanks={this.state.idbanks} loadClient={this.loadClient} 
                                                displayIban={this.state.displayIban}/>
                                    </Col>
                                    <Col xl="8">
                                        <Row>
                                            <Col md="6">
                                                <Card className="mini-stats-wid bg-primary text-white">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <h4 className="text-white">
                                                                    Volumes
                                                                </h4>
                                                                <hr/>
                                                                {Object.keys(this.state.volumes).map((key , i) => 
                                                                    <div key={`v_${i}`}>
                                                                        <Label className="mb-0">{key}</Label>
                                                                        <p className="mt-0">
                                                                            Volume: € {this.state.volumes[key].volume.toLocaleString()} 
                                                                            <small className="ml-2">(Mint: {this.state.volumes[key].mint.toLocaleString()} | Burn: {this.state.volumes[key].burn.toLocaleString()})</small>
                                                                            <br/>
                                                                            Commission: € {this.state.volumes[key].fees.toLocaleString()}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-dark align-self-top">
                                                                <span className="avatar-title bg-dark">
                                                                    <i className="bx bx-chart font-size-24"></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                                {this.state.oca && this.state.oca.Total.volume > 0 &&
                                                    <Card className="mini-stats-wid bg-dark text-white">
                                                        <CardBody>
                                                            <Media>
                                                                <Media body>
                                                                    <h4 className="text-white">
                                                                        OCA Commission
                                                                    </h4>
                                                                    <hr/>
                                                                    {Object.keys(this.state.oca).map((key , i) => 
                                                                        <div key={`oca_${i}`}>
                                                                            <Label className="mb-0">{key}</Label>
                                                                            <p className="mt-0">
                                                                                Volume: € {this.state.oca[key].volume.toLocaleString()} 
                                                                                <small className="ml-2">(Euro: {this.state.oca[key].euro.toLocaleString()} | Crypto: {this.state.oca[key].crypto.toLocaleString()})</small>
                                                                                <br/>
                                                                                Fees: € {this.state.oca[key].fees.toLocaleString()}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </Media>
                                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-top">
                                                                    <span className="avatar-title">
                                                                        <i className="bx bx-trending-up font-size-24"></i>
                                                                    </span>
                                                                </div>
                                                            </Media>
                                                        </CardBody>
                                                    </Card>
                                                }
                                            </Col>
                                            <Col md="6">
                                                <Fees publicKeyHash={this.props.match.params.publicKeyHash} toggleFeesModel={this.toggleFeesModel} displayFeesModel={this.state.displayFeesModel}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <CredentialsModal credentialsModal={this.state.credentialsModal}
                                            toggleCredentialsModal={this.toggleCredentialsModal}
                                            client={this.state.client}
                                            loadClient={this.loadClient} />
                                    <EmailModal emailModal={this.state.emailModal}
                                            toggleEmailModal={this.toggleEmailModal}
                                            publicKeyHash={this.state.client.publicKeyHash}
                                            loadClient={this.loadClient} />
                                    <NameModal nameModal={this.state.nameModal}
                                            toggleNameModal={this.toggleNameModal}
                                            publicKeyHash={this.state.client.publicKeyHash}
                                            loadClient={this.loadClient} />
                                    <VatNumberModal vatNumberModal={this.state.vatNumberModal}
                                            toggleVatNumberModal={this.toggleVatNumberModal}
                                            publicKeyHash={this.state.client.publicKeyHash}
                                            loadClient={this.loadClient} />
                                    <ReceivingBankModal receivingBankModal={this.state.receivingBankModal}
                                            toggleReceivingBankModal={this.toggleReceivingBankModal}
                                            publicKeyHash={this.state.client.publicKeyHash}
                                            loadClient={this.loadClient} />
                                    <IpAddressModal ipAddressModal={this.state.ipAddressModal}
                                            toggleIpAddressModal={this.toggleIpAddressModal}
                                            publicKeyHash={this.state.client.publicKeyHash}
                                            loadClient={this.loadClient} />
                                </Row>
                            }
                        </Container>
                    </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(ClientDetails)));
