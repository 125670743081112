import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Label, Input, InputGroup, InputGroupAddon } from "reactstrap";
import Loader from 'react-loader-spinner';

//i18n
import { saveIban, deleteIban } from '../../../helpers/api';

const IBAN = require('iban');
const BIC = require('bic');

class Idbank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iban: null,
            bic: null,
            validBic: false,
            validIban: false,
            loading: false,
        };
    }

    toggleCredentialsModal = () => {
        this.setState({ credentialsModal: !this.state.credentialsModal })
    }

    registerIban = () => {
        this.setState({ error: null, success: null, loading: true });
        this._asyncRequest = saveIban(this.state.iban, this.state.bic, this.props.client.publicKeyHash).then(
            res => {
                if (res.status === 'SUCCESS') {
                    this.setState({ success: 'Successfully registered', loading: false });
                    this.props.loadClient()
                } else {
                    this.setState({ error: res.error, loading: false });
                }
            }
        ).catch(error => {
            this.setState({ error: error.toString(), loading: false });
        });
    }

    removeIban = (iban) => {
        this.setState({ error: null, success: null, loading: true });
        this._asyncRequest = deleteIban(iban).then(
            res => {
                if (res.status === 'SUCCESS') {
                    this.setState({ ibanSuccess: 'Successfully removed', loading: false });
                    this.props.loadClient()
                } else {
                    this.setState({ error: res.error, loading: false });
                }
            }
        ).catch(error => {
            this.setState({ error: error.toString(), loading: false });
        });
    }

    updateIban = (event) => {
        this.setState({ iban: event.target.value, country: null, validIban: false, selectedIban: null });
        if (IBAN.isValid(event.target.value)) {
            this.setState({ validIban: true });
        } else
            this.setState({ validIban: false });
    }

    updateBic = (event) => {
        this.setState({ bic: event.target.value });
        if (BIC.isValid(event.target.value))
            this.setState({ validBic: true });
        else
            this.setState({ validBic: false });
    }

    render() {
        return (
            <Card className="mini-stats-wid">
                <CardBody>
                    <Label>Saved IBANs</Label>
                    {this.state.error && <span className="badge badge-danger font-size-10">{this.state.error}</span>}
                    <Row>
                        {this.props.idbanks && this.props.idbanks.map(idbank =>
                            <Col xl="12" sm="12" key={idbank.iban}>
                                <div className="mb-3">
                                    <label className="card-radio-label mb-2" onClick={() => { this.setState({ selectedIban: idbank.iban }) }}>
                                        <div className="card">
                                            <div>
                                                {this.state.loading ?
                                                    <span className="float-right">
                                                        <Loader type="Puff" color="#50a5f1" height={20} width={20} />
                                                    </span>
                                                    :
                                                    <span className="text-danger float-right" style={{ cursor: "pointer" }}
                                                        onClick={() => { if (window.confirm('Remove IBAN?')) { this.removeIban(idbank.iban) }; }}>
                                                        <i className="bx bx-trash"></i>
                                                    </span>}
                                                <span className="text-muted font-size-12 mb-1">{idbank.iban}</span>
                                                <h5>{idbank.bic}</h5>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {this.props.displayIban &&
                        <Row className="mb-2">
                            <Col sm="12">
                                <Label>New IBAN</Label>
                                <Row>
                                    <Col sm="12">
                                        <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text">IBAN</span>
                                            </InputGroupAddon>
                                            <Input type="text" className="form-control" onChange={this.updateIban} autoComplete="off" />
                                            <InputGroupAddon addonType="append">
                                                {this.state.validIban ?
                                                    <h4 className="ml-2 text-success"><b className="bx bx-check-shield"></b></h4>
                                                    :
                                                    <h4 className="ml-2 text-danger"><b className="bx bx-shield-x"></b></h4>
                                                }
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="8">
                                        <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text">BIC/SWIFT</span>
                                            </InputGroupAddon>
                                            <Input type="text" className="form-control" onChange={this.updateBic} autoComplete="off" />
                                            <InputGroupAddon addonType="append">
                                                {this.state.validBic ?
                                                    <h4 className="ml-2 text-success"><b className="bx bx-check-shield"></b></h4>
                                                    :
                                                    <h4 className="ml-2 text-danger"><b className="bx bx-shield-x"></b></h4>
                                                }
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {this.state.validIban && this.state.validBic &&
                                    <React.Fragment>
                                        <p>
                                            <label>
                                                IBAN: {this.state.iban} validated<br />
                                                BIC / SWIFT CODE: {this.state.bic}
                                            </label>
                                        </p>
                                        {this.state.loading ?
                                            <React.Fragment>
                                                <Loader type="Puff" color="#50a5f1" height={20} width={20} />
                                                <p className="mt-2 text-info">
                                                    Notification sent onto your inWebo Authenticator mobile application. <strong>Set your pin code to save IBAN...</strong>
                                                </p>
                                            </React.Fragment>
                                            :
                                            <Button type="button" color="success" onClick={this.registerIban}>Save Iban</Button>
                                        }
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>}
                </CardBody>
            </Card>

        );
    }
}

export default Idbank;