import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  dictAliases,
  financialOps,
  registerProposal,
  registerOperation,
  opDetails,
  originateFeesManager,
  proposeFeesManager,
} from '../../helpers/api';
import {
  truncStringPortion,
  capitalizeFirstLetter,
} from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import LoadingModal from './Modals/LoadingModal.js';
import LoadingLedger from './Modals/LoadingLedger.js';
import { TezosToolkit } from '@taquito/taquito';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';

const derivationPath = "44'/1729'/0'/0'/0'";
const descLimit = 140;

class ChangeFeesManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: 0,
      activeTab: '1',
      storage: {},
      isMenu: false,
      passActive: null,
      aliases: [],
      details: {},
      entrypoint: {},
      signers: {},
      parameters: [],
      operation: null,
      keystore: null,
      callingPoint: null,
      tezosNode: null,
      lughContract: null,
      error: null,
      selectedWallet: null,
      pending: false,
      descCharLeft: descLimit,
      description: null,
      success: null,
      jsonParams: null,
      displayResult: null,
      contractScript: null,
      fileName: null,
      scriptPair: null,
      contractId: null,
      operationContract: null,
      explorer: null,
      instructions: 'Follow instruction on your ledger...',
    };
    this.passPhrase = React.createRef();
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changePassActive = (wType, publicKeyHash = null) => {
    this.setState({ passActive: wType });
    if (publicKeyHash) this.setState({ selectedWallet: publicKeyHash });
  };

  genRanHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');

  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      try {
        const data = event.target.result.split(';base64,').pop();
        const buff = new Buffer(data, 'base64');
        const filedata = buff.toString('ascii');
        this.setState({
          contractScript: filedata,
          error: null,
        });
      } catch (error) {
        console.log(error);
        this.setState({ error: error.toString() });
      }
    });
    this.setState({
      fileName: event.target.files[0].name,
      publicKeyHash: null,
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  updtScriptPair = (e) => {
    this.setState({ scriptPair: e.target.value });
  };

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, tokenId: res.tokenId });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.handleFetch();
  };

  handleFetch = () => {
    this.setState({
      operation: 'set_fees_manager',
      proposalId: `sfm${this.genRanHex(6)}`,
    });
    this._asyncRequest = financialOps('administrator')
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ callingPoint: 'createSetOptionProposal' });
          this.setState({
            storage: res.data.storage,
            details: res.data.details,
            signers: res.data.signers,
            tezosNode: res.data.tezosNode,
            lughContract: res.data.lughContract,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  ledgerSendOp = async () => {
    this.setState({
      pending: true,
      info: `Connect your Ledger and approve connection request`,
    });
    const Tezos = new TezosToolkit(this.state.tezosNode);
    const transport = await TransportWebHID.create();
    const ledgerSigner = new LedgerSigner(
      transport, //required
      derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
      true, // prompt optional
      DerivationType.ED25519, // derivationType optional
    );
    Tezos.setProvider({ signer: ledgerSigner });
    const publicKeyHash = await Tezos.signer.publicKeyHash();
    if (this.state.selectedWallet !== publicKeyHash) {
      this.setState({
        error: 'Not connected with the right Ledger',
        pending: false,
        success: false,
      });
      return;
    }
    let operation = await Tezos.contract.originate({
      code: JSON.parse(this.state.contractScript),
      init: this.state.scriptPair,
    });
    await operation.confirmation();
    const contractId = await (await operation.contract()).address;
    this.setState({
      contractId: contractId,
      operationContract: operation.opHash,
      displayResult: true,
      instructions: 'Follow instruction on your ledger to send proposal...',
    });
    const jsonParams = {
      address: contractId,
      proposalId: this.state.proposalId,
    };
    const params = [
      contractId,
      this.state.lughContract,
      this.state.operation,
      this.state.proposalId,
    ];
    const contract = await Tezos.wallet.at(this.state.details.contract);
    this.setState({
      info: `Follow the instruction from your Ledger to send operation`,
    });
    operation = await contract.methods
      .createSetOptionProposal(...params)
      .send();
    this.setState({
      info: 'Awaiting confirmation. It may take up to 1 min...',
    });
    await operation.confirmation();
    this.setState({
      pending: false,
      success: `Proposal sent with operation ID ${operation.opHash}`,
    });
    this._asyncRequest = registerProposal(
      this.state.proposalId,
      this.state.selectedWallet,
      'owner',
      this.state.description,
      this.state.operation,
      jsonParams,
    )
      .then((regResp) => {
        console.log(regResp.status);
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ displayResult: true });
    this._asyncRequest = registerOperation(
      operation.opHash,
      this.state.operation,
      `Proposal ID ${this.state.proposalId} sent from Owner`,
      this.state.selectedWallet,
      'owner',
      this.state.proposalId,
    )
      .then((regResp) => {
        console.log(regResp.status);
      })
      .catch((error) => {
        console.log(error);
      });
    await transport.close();
  };

  platformSendOp = () => {
    this.setState({
      pending: true,
      error: null,
      success: null,
      displayResult: false,
    });
    this._asyncRequest = originateFeesManager(
      this.state.scriptPair,
      this.state.contractScript,
      this.state.selectedWallet,
      this.passPhrase.current.value,
    )
      .then((res) => {
        this.setState({
          instructions:
            'Waiting for the new contract to be published. It may take up to 1 min...',
        });
        var interval = null;
        const opHash = res.data;
        console.log(opHash);
        interval = setInterval(
          function () {
            this._asyncRequest = opDetails(opHash)
              .then((response) => {
                if (response.status === 'SUCCESS') {
                  // Building proposal
                  this.setState({
                    contractId:
                      response.data[response.data.length - 1].receiver,
                    operationContract: opHash,
                    instructions:
                      'Follow instruction on your ledger to send proposal...',
                  });
                  this._asyncRequest = proposeFeesManager(
                    this.state.selectedWallet,
                    response.data[response.data.length - 1].receiver,
                    this.passPhrase.current.value,
                    this.state.proposalId,
                  )
                    .then((resp) => {
                      const opProp = resp.data.opProp;
                      this.setState({
                        pending: false,
                        success: `Proposal sent with operation ID ${opProp}`,
                      });
                      this._asyncRequest = registerProposal(
                        this.state.proposalId,
                        this.state.selectedWallet,
                        'owner',
                        this.state.description,
                        this.state.operation,
                        resp.data.jsonParams,
                      )
                        .then((regResp) => {
                          console.log(regResp.status);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                      this.setState({ displayResult: true });
                      this._asyncRequest = registerOperation(
                        opProp,
                        this.state.operation,
                        `Proposal ID ${this.state.proposalId} sent from Owner`,
                        this.state.selectedWallet,
                        'owner',
                        this.state.proposalId,
                      )
                        .then((regResp) => {
                          console.log(regResp.status);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      this.setState({
                        error: error.toString(),
                        pending: false,
                      });
                    });
                  //Close loop
                  clearInterval(interval);
                } else {
                  console.log(`Fetch failed : ${response.error}`);
                }
              })
              .catch((error) => {
                console.log(`Fetch failed : ${error}`);
              });
          }.bind(this),
          10000,
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ pending: false, error: error.toString() });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <LoadingModal
              pending={this.state.pending}
              passActive={this.state.passActive}
              contract={this.state.contract}
            />
            <LoadingLedger
              pending={this.state.pending}
              instructions={this.state.instructions}
              passActive={this.state.passActive}
              contract={this.state.contract}
            />
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Financial Operation"
              breadcrumbItem={
                this.state.operation &&
                capitalizeFirstLetter(this.state.operation.replace('_', ' '))
              }
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Set a new fees manager proposal
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="operation">
                          <form>
                            <div className="mb-2">
                              <Label>Select a signer to send proposal</Label>
                              {this.state.storage.value && (
                                <Row>
                                  {this.state.storage.value.signers.map(
                                    (signer) => (
                                      <Col xl="3" sm="12" key={signer}>
                                        <div className="mb-3">
                                          <label
                                            className="card-radio-label mb-2"
                                            onClick={() => {
                                              this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType !== 'auto' &&
                                                this.changePassActive(
                                                  this.state.aliases[signer]
                                                    .walletType,
                                                  signer,
                                                );
                                            }}
                                          >
                                            {this.state.aliases[signer] &&
                                              this.state.aliases[signer]
                                                .walletType !== 'auto' && (
                                                <input
                                                  type="radio"
                                                  name="currency"
                                                  id={signer}
                                                  className="card-radio-input"
                                                  readOnly
                                                />
                                              )}

                                            <div
                                              className={
                                                this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType !== 'auto'
                                                  ? 'card-radio'
                                                  : 'card'
                                              }
                                            >
                                              <div>
                                                {this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType === 'ledger' ? (
                                                  <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                ) : this.state.aliases[
                                                    signer
                                                  ] &&
                                                  this.state.aliases[signer]
                                                    .walletType ===
                                                    'platform' ? (
                                                  <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                ) : (
                                                  <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                )}
                                                <span>
                                                  {this.state.aliases[signer] &&
                                                  this.state.aliases[signer]
                                                    .name ? (
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          this.state.aliases[
                                                            signer
                                                          ].name.replace(
                                                            '(',
                                                            '<br/>(',
                                                          ),
                                                      }}
                                                    ></span>
                                                  ) : (
                                                    truncStringPortion(
                                                      signer,
                                                      8,
                                                      6,
                                                    )
                                                  )}
                                                </span>
                                              </div>
                                              <div>
                                                <p className="text-muted font-size-11 mb-1">
                                                  {truncStringPortion(
                                                    signer,
                                                    8,
                                                    6,
                                                  )}
                                                </p>
                                                <h5 className="font-size-16 mb-1">
                                                  {this.state.signers[signer]
                                                    ? Math.round(
                                                        this.state.signers[
                                                          signer
                                                        ].total_balance * 100,
                                                      ) / 100
                                                    : 0}{' '}
                                                  <TezosLogo
                                                    width="16"
                                                    height="16"
                                                  />
                                                </h5>
                                                <span className="text-muted">
                                                  ~
                                                  {this.state.signers[signer] &&
                                                  this.state.signers[signer]
                                                    .total_balance > 0.12
                                                    ? Math.round(
                                                        this.state.signers[
                                                          signer
                                                        ].total_balance / 0.12,
                                                      )
                                                    : 0}{' '}
                                                  operations
                                                </span>
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              )}
                            </div>
                            <FormGroup>
                              <Label>Set a proposal description</Label>
                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.descCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={descLimit}
                                      onChange={this.updateDescription}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col md="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        Setup parameters
                                      </span>
                                    </InputGroupAddon>
                                    <input
                                      type="text"
                                      placeholder="Michelson Pair format params"
                                      className="form-control"
                                      onChange={this.updtScriptPair}
                                    />
                                  </InputGroup>
                                </Col>
                                <Col md="6">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        Fees manager contract (.tz / .json)
                                      </span>
                                    </InputGroupAddon>
                                    <input
                                      type="file"
                                      className="form-control"
                                      onChange={this.onFileHandler}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            {this.state.passActive === 'platform' && (
                              <FormGroup>
                                <Label>Passphrase :</Label>
                                <input
                                  type="password"
                                  ref={this.passPhrase}
                                  className="form-control"
                                />
                              </FormGroup>
                            )}

                            {this.state.error && (
                              <p className="badge badge-danger font-size-12">
                                {this.state.error}
                              </p>
                            )}
                            {this.state.success && (
                              <p className="badge badge-success font-size-12">
                                {this.state.success}
                              </p>
                            )}
                            {this.state.displayResult && (
                              <>
                                <br />
                                <p className="font-size-14 mt-1">
                                  <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                                  Proposal ID{' '}
                                  <b className="font-size-12 mr-1">
                                    {this.state.proposalId}
                                  </b>
                                  for operation <b>setFeesManager</b> sent from
                                  <b className="ml-1">Administrator</b>{' '}
                                  {this.state.displayAmount > 0 && (
                                    <span>
                                      of <SVGLogo width="16" height="16" />{' '}
                                      <b>{this.state.displayAmount}</b>
                                    </span>
                                  )}{' '}
                                  with
                                  <b className="ml-1">
                                    {this.state.aliases[
                                      this.state.selectedWallet
                                    ] &&
                                    this.state.aliases[
                                      this.state.selectedWallet
                                    ].name
                                      ? this.state.aliases[
                                          this.state.selectedWallet
                                        ].name
                                      : truncStringPortion(
                                          this.state.selectedWallet,
                                          8,
                                          6,
                                        )}
                                  </b>
                                </p>
                              </>
                            )}

                            {this.state.passActive === 'platform' && (
                              <>
                                {this.state.pending ? (
                                  <p>Processing operation, please wait...</p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.platformSendOp();
                                      }}
                                    >
                                      Send{' '}
                                      {this.state.operation &&
                                        capitalizeFirstLetter(
                                          this.state.operation.replace(
                                            '_',
                                            ' ',
                                          ),
                                        )}{' '}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                            {this.state.passActive === 'ledger' && (
                              <>
                                {this.state.pending ? (
                                  <p>
                                    Follow further instructions on your
                                    ledger...
                                  </p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.ledgerSendOp();
                                      }}
                                    >
                                      Send{' '}
                                      {this.state.operation &&
                                        capitalizeFirstLetter(
                                          this.state.operation.replace(
                                            '_',
                                            ' ',
                                          ),
                                        )}{' '}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangeFeesManager;