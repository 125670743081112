import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

//Import Breadcrumb
import { listCompanies } from "../../helpers/api";

class Registrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            clients: {
                columns: [
                    {
                        label: "Name",
                        field: "name",
                        sort: "asc",
                        width: 136
                    },
                    {
                        label: "Address",
                        field: "address",
                        sort: "asc",
                        width: 136
                    },
                    {
                        label: "Owners",
                        field: "owners",
                        sort: "desc",
                        width: 136
                    },
                    {
                        label: "Date",
                        field: "date",
                        sort: "desc",
                        width: 136
                    },
                    {
                      label: "Status",
                      field: "status",
                      sort: "asc",
                      width: 104
                    },
                    {
                        label: "",
                        field: "details",
                        sort: "asc",
                        width: 100
                    },
                ],
                rows: []
            },
            loading: true
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount = async () => {
        const res = await listCompanies()
        if (res.status === 'SUCCESS') {
            for (let c of res.companies) {
                let row = {
                    name: <div><h5>{c.companyName}</h5><small>{c.registrationNumber}</small></div>,
                    address: c.fullAddress,
                    owners: c.owners? c.owners.length : 0,
                    date: new Date(c.createdAt).toLocaleString(),
                    status: c.status === 'REJECTED'? <span className="badge badge-danger font-size-10">Rejected</span> : c.status === 'VALIDATED'? <span className="badge badge-success font-size-10">Approved</span> : <span className="badge badge-warning font-size-10">Pending</span> ,
                    details: <Link as="span" className="badge badge-primary font-size-11" style={{ cursor: 'pointer' }} to={`/registration-details/${c.id}`}>View Details</Link>,
                };

                await this.setState({
                    clients: { columns: [...this.state.clients.columns], rows: [...this.state.clients.rows, row] },
                });
            }
            this.setState({ loading: false });
        } else {
            console.log(res.error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {!this.state.loading &&
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title">List of all registrations (KYB)</h4>
                                            <TabContent activeTab={this.state.activeTab} className="p-3">
                                                <TabPane tabId="1" id="registrations">
                                                    <MDBDataTable responsive bordered data={this.state.clients} className="mt-1" hover />
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                        {this.state.loading && <div className="text-center my-3">
                            <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Link>
                        </div>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Registrations;