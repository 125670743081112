import React, { Component } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import SVGLogo from '../../components/Common/SVGLogo.js';

class TransactionList extends Component {
    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            {this.props.client.name} Extracts
                        </CardTitle>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0 font-size-12">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Post Date</th>
                                        <th>Wallet balance</th>
                                        <th>Customers funds</th>
                                        <th>Available funds</th>
                                        <th>Cash-flow</th>
                                        <th>Volume</th>
                                        <th>Nb Tx</th>
                                        <th>Fees</th>
                                        <th>Consistency</th>
                                        <th>View Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.extracts.map(extract =>
                                            <>
                                            {['VALIDATED', 'PUBLISHED'].includes(extract.status) &&
                                                <tr key={extract.id}>
                                                    <td>
                                                        {extract.createdAt.substring(0,19).replace('T', ' ').replace('Z', '')} GMT
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        {extract.globalDispatch < 0?
                                                            <strong className="text-danger">
                                                                <SVGLogo width="16" height="16"/> {(Math.round((extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                            </strong>
                                                            :
                                                            <span>
                                                                <SVGLogo width="16" height="16"/> {(Math.round((extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {extract.globalDispatch < 0?
                                                            <strong className="text-danger">
                                                                <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                            </strong>
                                                            :
                                                            <span>
                                                                <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.dailyDispatch.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.volume.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        {extract.nbTx}
                                                    </td>
                                                    <td>
                                                        € {extract.fees.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-success">{extract.status}</span>
                                                        {extract.outOfRange > 0 && <><br/><span className="badge badge-warning"><b>{extract.outOfRange}</b> out of range</span></>}
                                                        {extract.globalDispatch < 0 && <><br/><span className="badge badge-danger">Negative customer funds</span></>}
                                                        {extract.unauthorized > 0 && <><br/><span className="badge badge-danger"><b>{extract.unauthorized}</b> unauthorized transfers</span></>}
                                                    </td>
                                                    <td>
                                                        <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/extract/${extract.id}`}>View Details</Link>
                                                    </td>
                                                </tr>
                                            }
                                            {['RESOLVED'].includes(extract.status) &&
                                                <tr key={extract.id}>
                                                    <td>
                                                        {extract.createdAt.substring(0,19).replace('T', ' ').replace('Z', '')} GMT
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    {(extract.lughBalance - extract.globalDispatch) > 0 ?
                                                        <td className="text-primary">
                                                            <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                        </td>
                                                    :
                                                        <td className="text-pink">
                                                            <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                        </td>
                                                    }
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.dailyDispatch.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.volume.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        {extract.nbTx}
                                                    </td>
                                                    <td>
                                                        € {extract.fees.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-pink">{extract.status}</span>
                                                        {extract.outOfRange > 0 && <><br/><span className="badge badge-warning"><b>{extract.outOfRange}</b> out of range</span></>}
                                                    </td>
                                                    <td>
                                                        <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/extract/${extract.id}`}>View Details</Link>
                                                    </td>
                                                </tr>
                                            }
                                            {['REJECTED'].includes(extract.status) &&
                                                <tr key={extract.id} className="bg-danger text-white">
                                                    <td>
                                                        {extract.createdAt.substring(0,19).replace('T', ' ').replace('Z', '')} GMT
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.dailyDispatch.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.volume.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        {extract.nbTx}
                                                    </td>
                                                    <td>
                                                        € {extract.fees.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        REJECTED
                                                    </td>
                                                    <td>
                                                        <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/extract/${extract.id}`}>View Details</Link>
                                                    </td>
                                                </tr>
                                            }
                                            {['CANCELLED'].includes(extract.status) &&
                                                <tr key={extract.id} className="bg-secondary text-white">
                                                    <td>
                                                        {extract.createdAt.substring(0,19).replace('T', ' ').replace('Z', '')} GMT
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {(Math.round((extract.lughBalance - extract.globalDispatch) * 100) / 100).toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.dailyDispatch.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <SVGLogo width="16" height="16"/> {extract.volume.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        {extract.nbTx}
                                                    </td>
                                                    <td>
                                                        € {extract.fees.toLocaleString('fr')}
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dark">CANCELLED</span>
                                                    </td>
                                                    <td>
                                                        <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/extract/${extract.id}`}>View Details</Link>
                                                    </td>
                                                </tr>
                                            }
                                            </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default TransactionList;
