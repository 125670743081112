import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button, Nav, TabContent, TabPane, InputGroup, InputGroupAddon } from "reactstrap";
import { refClient } from '../../helpers/api';
import TezosLogo from "../../components/Common/TezosLogo";
import EthereumLogo from "../../components/Common/EthereumLogo";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
const nameLimit = 40;
const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

class NewClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            validEmail: false,
            validPhrase: false,
            nameCharLeft: nameLimit,
            pending: false,
            error: null,
            activeTab: '1',
            tezos: null,
            ethereum: null,
            ipAddress: null,
            keystore: null,
            vatNumber: null,
            receivingBank: null,
            processing: false,
            fileSize: 0,
            fileExtension: 'jpg',
            fileData: null,
      
        }
        this.hiddenFileInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
      this.setState({checked: !this.state.checked});
    }
  
    handleUpload = () => {
      this.hiddenFileInput.current.click()
    }
  
    onFileHandler = async(event) => {
      const reader = new FileReader()
      this.setState({processing: true, error: null, success: false, info: null})
      const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
      if(!extension || !['jpg', 'png', 'jpeg'].includes(extension)){
          this.setState({processing: false, error: 'Invalid image file'})
      }else if(event.target.files[0].size > 12000000){
          this.setState({processing: false, error: 'Image exceeding 10Mo'})
      }else{
          this.setState({fileSize: event.target.files[0].size, fileExtension: extension})
          reader.onloadend = () => this.processFile(reader)
          reader.readAsArrayBuffer(event.target.files[0])
      }
    }
  
    processFile = async(reader) => {
      const buffer = Buffer.from(reader.result)
      const base64data = buffer.toString('base64')
      this.setState({processing: false, fileData: base64data})
    }

    updateName = (event) => {
        this.setState({nameCharLeft: nameLimit - event.target.value.length, name: event.target.value});
    }

    updateEmail = (event) => {
        this.setState({email: event.target.value, validEmail: regEmail.test(event.target.value)});
    }

    updateIpAddress = (event) => {
        this.setState({ipAddress: event.target.value});
    }

    updateTezos = (event) => {
        this.setState({tezos: event.target.value});
    }

    updateEthereum = (event) => {
        this.setState({ethereum: event.target.value});
    }

    updateVatNumber = (event) => {
        this.setState({vatNumber: event.target.value});
    }

    updateReceivingBank = (event) => {
        this.setState({receivingBank: event.target.value});
    }

    referenceClient = () => {
        if(!this.state.tezos && !this.state.ethereum)
            this.setState({error: 'Invalid public key hash / address', pending: false});
        else if(this.state.tezos && this.state.tezos.length !== 36)
            this.setState({error: 'Invalid Tezos address', pending: false});
        else if(this.state.ethereum && this.state.ethereum.length !== 42)
            this.setState({error: 'Invalid Ethereum address', pending: false});
        else if(this.state.name.length < 4)
            this.setState({error: 'Invalid wallet name!', pending: false});
        else if(!this.state.validEmail)
            this.setState({error: 'Invalid email!', pending: false});
        else if(!this.state.receivingBank)
            this.setState({error: 'Invalid IBAN!', pending: false});
        else{
            this.setState({pending: true, error: null});
            this._asyncRequest = refClient(this.state.tezos, this.state.ethereum, this.state.name, 
                                              this.state.email, this.state.ipAddress, this.state.fileData, 
                                              this.state.vatNumber, this.state.receivingBank, this.state.code).then(
                res => {
                    if (res.status === 'SUCCESS'){
                        window.location='/all-clients';
                    }else{
                        this.setState({error: res.error, pending: false});
                    }
                }
            ).catch(error => {
                this.setState({error: error.toString(), pending: false});
            });
        }

    }

    updateCode = (event) => {
        this.setState({code: event.target.value});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Wallet" breadcrumbItem="Reference a client" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4"><i className="bx bx-wallet"></i> Reference a client and its wallet on the platform</h4>
                                        <div className="crypto-buy-sell-nav">
                                            <Nav tabs className="nav-tabs-custom" role="tablist">
                                            </Nav>

                                            <TabContent activeTab={this.state.activeTab} className="crypto-buy-sell-nav-content p-4">
                                                <TabPane tabId="1" id="create">
                                                        {this.state.error && <p className="text-danger">{this.state.error}</p>}

                                                        <FormGroup>
                                                            <Label>Client logo <small>*optional (only jpg or png)</small></Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <input className="d-none" type="file" ref={this.hiddenFileInput}
                                                                        accept="image/x-png,image/gif,image/jpeg"
                                                                        onChange={(e) => this.onFileHandler(e)}/>
                                                                    {this.state.fileData &&
                                                                        <div className="m-2">
                                                                            <img style={{display: "block", maxWidth: "200px"}}
                                                                                 src={`data:image/${this.state.fileExtension};base64,${this.state.fileData}`} 
                                                                                 alt="logo"/>
                                                                        </div>
                                                                    }
                                                                    {this.state.processing?
                                                                        <p>Processing... Please wait</p>
                                                                    :
                                                                        <Button type="button" color="info" onClick={this.handleUpload}>Select logo</Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Client name</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">{this.state.nameCharLeft} char. left</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control"  maxLength={nameLimit} onChange={this.updateName} autoComplete="off"/>
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.name && this.state.name.length > 3 ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                            :
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Client email</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">@</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" onChange={this.updateEmail} autoComplete="off"/>
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.validEmail ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                            :
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label><TezosLogo width="16" height="16"/> Set a Tezos address</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <Input type="text" className="form-control" onChange={this.updateTezos} autoComplete="off" placeholder="tz1..."/>
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.tezos && this.state.tezos.length === 36 ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                            :
                                                                                this.state.tezos && this.state.tezos.length > 1 &&
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label><EthereumLogo width="16" height="16"/> Set a Ethereum address</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <Input type="text" className="form-control" onChange={this.updateEthereum} autoComplete="off" placeholder="0x..."/>
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.ethereum && this.state.ethereum.length === 42 ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                            :
                                                                                this.state.ethereum && this.state.ethereum.length > 1 &&
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Set Client IP addresses (if several seperate with comma) <small>*optional</small></Label>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">ip</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" onChange={this.updateIpAddress} placeholder='If several addresses seperate with comma'/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>VAT number <small>*optional</small></Label>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">VAT</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" onChange={this.updateVatNumber} placeholder='VAT number'/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label><strong>Bank account on which client will send funds to Lugh</strong></Label>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">IBAN</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" onChange={this.updateReceivingBank} placeholder='IBAN'/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup className="bg-light p-2 border border-secondary rounded mx-auto" style={{maxWidth: "300px"}}>
                                                            <Label>To validate this operation OTP is required</Label>
                                                            <InputGroup className="mb-2">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <span className="input-group-text">OTP</span>
                                                                </InputGroupAddon>
                                                                <Input type="text" className="form-control" placeholder="Set your Google Auth. OTP" onChange={this.updateCode} autoComplete="off"/>
                                                            </InputGroup>
                                                            <div className="text-center mt-2">
                                                                {this.state.pending?
                                                                    <p>Referencing wallet. please wait...</p>
                                                                :
                                                                    <Button type="button" color="success" onClick={this.referenceClient}>Reference Client</Button>
                                                                }
                                                            </div>
                                                        </FormGroup>
                                                </TabPane>
                                            </TabContent>
                                        </div>

                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default NewClient;