import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import SVGLogo from '../../components/Common/SVGLogo.js';


class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-2">
                            EURL Clients
                        </CardTitle>
                        <div className="text-center">
                            <div className="mb-2">
                                <i className="mdi mdi-account-circle-outline text-primary display-4"></i>
                            </div>
                            <h3>{this.props.clients.length}</h3>
                            <p>Referenced clients</p>
                        </div>

                        <div className="table-responsive mt-4">
                            <table className="table table-centered table-nowrap mb-2">
                                <tbody>
                                    {this.props.clients && this.props.clients.slice(0, 3).map(client =>
                                        <tr key={'w'+client.publicKeyHash}>
                                            <td>
                                                {client.logo && <img style={{display: "block", height: "20px"}}
                                                    src={`https://premiere.infura-ipfs.io/ipfs/${client.logo}`} 
                                                    alt="logo"/>}
                                            </td>
                                            <td>
                                                <p className="mb-0"><Link to={`/client-details/${client.publicKeyHash}`}>{client.name}</Link></p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0"><SVGLogo/> {parseFloat(client.funds).toLocaleString('fr')}</h5></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="text-center mt-4"><Link to="/all-clients" className="btn btn-primary waves-effect waves-light btn-sm">View More <i className="mdi mdi-arrow-right ml-1"></i></Link></div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default Clients;
