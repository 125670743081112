import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
} from "reactstrap";
import Avatar from "react-avatar";

import { deleteUser } from "../../helpers/api";

import Update2FA from "./reset-2fa";

const CardContact = ({ loadUsers, user, canDelete }) => {
  const handleDeleteUser = async (id) => {
    await deleteUser(id);
    loadUsers();
  };

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {
              <div className="avatar-sm mx-auto mb-4">
                <Avatar
                  name={`${user.name || ""}`}
                  round
                  size={48}
                  textSizeRatio={2}
                />
              </div>
            }

            <h5 className="font-size-15">
              <Link to="#" className="text-dark">
                {user.name}
              </Link>
            </h5>
            <p className="text-muted">{user.designation}</p>

            <div>
              {user.skills.map((skill, key) => (
                <Link
                  to="#"
                  className={`badge badge-${
                    skill.name === "CLIENT" ? "pink" : "primary"
                  } font-size-11 m-1`}
                  key={"_skill_" + key}
                >
                  {skill.name}
                  {user.client && (
                    <span className="ml-2">{user.client.name}</span>
                  )}
                </Link>
              ))}
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Update2FA user={user} />
              </div>
              <div className="flex-fill">
                {canDelete && (
                  <button
                    className="btn btn-lg"
                    id={"profile" + user.id}
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    <i className="bx bx-trash"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"profile" + user.id}
                    >
                      Delete
                    </UncontrolledTooltip>
                  </button>
                )}
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardContact;
