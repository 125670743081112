import React, { useState, useEffect } from 'react';

import { Card, CardBody, Media } from "reactstrap";

import { getEscrowAmount } from '../../helpers/api';

const PopupBalance = (props) => {
  const [error, setError] = useState(''); //eslint-disable-line
  const [amount, setAmount] = useState('-');
  const [amountDate, setAmountDate] = useState(null);

  const loadBalance = async () => {
    let amount = await getEscrowAmount()
    if (amount.status === 'ERROR') {
      setError(amount.error);
      return;
    }

    setAmount(amount.amount);
    setAmountDate(new Date(parseInt(amount.timestamp)));
  }

  useEffect(() => {
    loadBalance();
  }, []);

  return (
    <React.Fragment>
      <Card className={`mini-stats-wid bg-${props.totalSupply && props.totalSupply > amount ? 'danger' : 'success'}`}>
        <CardBody>
          <Media>
            <Media body>
              <p className="text-white font-weight-medium">Reserve bank account</p>
              <h4 className="mb-0 text-white">€ {amount.toLocaleString('fr')} ({amountDate ? amountDate.toLocaleDateString() : '-'})</h4>
              <hr/>
              <p className="text-white font-weight-medium mb-0">Available income</p>
              <h5 className="mb-0 text-white">€ {parseFloat(amount - props.totalSupply).toLocaleString('fr')}</h5>
            </Media>
            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top text-white">
              <span className="avatar-title bg-white">
                <i className={`bx bx-book-alt text-${props.totalSupply && props.totalSupply > amount ? 'danger' : 'success'} font-size-24`}></i>
              </span>
            </div>
          </Media>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default PopupBalance