import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { bytes2Char } from '@taquito/utils';

import { MDBDataTable } from 'mdbreact';
import './datatables.scss';

//Import Breadcrumb
import { contractProposals, dictAliases } from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import CSV from '../../components/Common/CSV';

import SVGLogo from '../../components/Common/SVGLogo.js';

class AdministratorProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 1000000,
      startDate: new Date(),
      activeTab: '1',
      aliases: [],
      csvRows: [],
      transactions: {
        columns: [
          {
            label: 'Proposal ID',
            field: 'proposalId',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Proposal Type',
            field: 'proposalType',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Parameters',
            field: 'parameters',
            width: 264,
          },
          {
            label: 'Approvals',
            field: 'approvals',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Rejects',
            field: 'rejects',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Actions',
            field: 'action',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: [],
      },
      limit: false,
      loading: true,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this._asyncRequest = contractProposals('administrator')
      .then((res) => {
        if (res.status === 'SUCCESS') {
          for (let proposal of res.data) {
            const rejects = proposal.value.rejects.length;
            const accepts = proposal.value.approvals.length;
            let row = {
              proposalId: proposal.key,
              proposalType: proposal.value.operation,
              date: (proposal?.time || '').replace('T', ' ').replace('Z', ''),
              parameters: (
                <span className="badge font-size-9 text-left">
                  {proposal.value.operation === 'transfer' &&
                    Object.keys(proposal.value).map(
                      (e, i) =>
                        ![
                          'contractAddr',
                          'operation',
                          'status',
                          'approvals',
                          'rejects',
                          'state',
                        ].includes(e) && (
                          <div key={i} className="mt-1">
                            {e === 'f' ? 'From' : e === 't' ? 'To' : e}:{' '}
                            {['amount', 'balance', 'value'].includes(e) ? (
                              <span>
                                {parseFloat(
                                  proposal.value[e] / this.state.decimals,
                                )}{' '}
                                <SVGLogo width="13" height="13" />
                              </span>
                            ) : this.state.aliases[
                                proposal.value[e].toString()
                              ] &&
                              this.state.aliases[proposal.value[e].toString()]
                                .name ? (
                              this.state.aliases[proposal.value[e].toString()]
                                .name
                            ) : proposal.value[e].toString().length > 14 ? (
                              truncStringPortion(
                                proposal.value[e].toString(),
                                8,
                                6,
                              )
                            ) : (
                              proposal.value[e].toString()
                            )}
                          </div>
                        ),
                    )}
                  {['set_pause', 'setPause'].includes(
                    proposal.value.operation,
                  ) && (
                    <div className="mt-1">
                      Status:{' '}
                      {proposal.value.state.toString() === 'true' ? (
                        <span className="badge badge-danger">
                          Transfers Paused
                        </span>
                      ) : (
                        <span className="badge badge-success">
                          Transfers Active
                        </span>
                      )}
                    </div>
                  )}
                  {proposal.value.operation === 'setActivation' && (
                    <div className="mt-1">
                      Status:{' '}
                      {proposal.value.state.toString() === 'false' ? (
                        <span className="badge badge-danger">
                          Rights manager deActivated
                        </span>
                      ) : (
                        <span className="badge badge-success">
                          Rights manager Activated
                        </span>
                      )}
                    </div>
                  )}
                  {proposal.value.operation === 'set_metadata' && (
                    <div className="mt-1">
                      Metadata: <br /> {bytes2Char(proposal.value.v)}
                    </div>
                  )}
                  {[
                    'updateThreshold',
                    'updateGasFee',
                    'updateGaslessFee',
                    'updateStorageFee',
                  ].includes(proposal.value.operation) && (
                    <div className="mt-1">
                      Value: {proposal.value.amount.toLocaleString()}
                    </div>
                  )}
                  {['set_lock', 'setLock'].includes(
                    proposal.value.operation,
                  ) && (
                    <>
                      <div className="mt-1">
                        Address:{' '}
                        {this.state.aliases[proposal.value.t] &&
                        this.state.aliases[proposal.value.t].name
                          ? this.state.aliases[proposal.value.t].name
                          : truncStringPortion(proposal.value.t, 8, 6)}
                        <br />
                      </div>
                      <div className="mt-1">
                        Status:{' '}
                        {proposal.value.state.toString() === 'true' ? (
                          <span className="badge badge-danger">Locked</span>
                        ) : (
                          <span className="badge badge-success">Unlocked</span>
                        )}
                      </div>
                    </>
                  )}
                  {['setWhiteListing'].includes(proposal.value.operation) && (
                    <>
                      <div className="mt-1">
                        Address:{' '}
                        {this.state.aliases[proposal.value.t] &&
                        this.state.aliases[proposal.value.t].name
                          ? this.state.aliases[proposal.value.t].name
                          : truncStringPortion(proposal.value.t, 8, 6)}
                        <br />
                      </div>
                      <div className="mt-1">
                        Status:{' '}
                        {proposal.value.state.toString() === 'true' ? (
                          <span className="badge badge-success">
                            Authorized
                          </span>
                        ) : (
                          <span className="badge badge-danger">
                            non Authorized
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  {[
                    'set_fees_manager',
                    'set_fees_faucet',
                    'set_rights_manager',
                  ].includes(proposal.value.operation) &&
                    (!this.state.aliases[proposal.value.t] ||
                      this.state.aliases[proposal.value.t].name !==
                        'Token') && (
                      <div className="mt-1">
                        Address:{' '}
                        {this.state.aliases[proposal.value.t] &&
                        this.state.aliases[proposal.value.t].name
                          ? this.state.aliases[proposal.value.t].name
                          : truncStringPortion(proposal.value.t, 8, 6)}
                        <br />
                      </div>
                    )}
                  {['set_fees_manager', 'set_rights_manager'].includes(
                    proposal.value.operation,
                  ) &&
                    this.state.aliases[proposal.value.t] &&
                    this.state.aliases[proposal.value.t].name === 'Token' && (
                      <div className="mt-1">
                        <span className="badge badge-danger">REMOVE</span>
                      </div>
                    )}
                </span>
              ),
              approvals: (
                <span className="badge font-size-9 text-left">
                  {proposal.value.approvals.map((approval) => (
                    <div key={approval} className="mt-1">
                      {this.state.aliases[approval] &&
                      this.state.aliases[approval].name
                        ? this.state.aliases[approval].name
                        : truncStringPortion(approval, 8, 6)}
                    </div>
                  ))}
                </span>
              ),
              rejects: (
                <span className="badge font-size-9 text-left">
                  {proposal.value.rejects.map((reject) => (
                    <div key={reject} className="mt-1">
                      {this.state.aliases[reject] &&
                      this.state.aliases[reject].name
                        ? this.state.aliases[reject].name
                        : truncStringPortion(reject, 8, 6)}
                    </div>
                  ))}
                </span>
              ),
              status: (
                <b>
                  {proposal.value.status.toString() === 'true'
                    ? 'Closed'
                    : 'Pending'}
                  <br />
                  {proposal.value.status.toString() === 'true' &&
                    rejects > 0 && (
                      <span className="badge badge-danger font-size-10">
                        Rejected
                      </span>
                    )}
                  {proposal.value.status.toString() === 'true' &&
                    accepts > rejects && (
                      <span className="badge badge-success font-size-10">
                        Accepted
                      </span>
                    )}
                  {proposal.value.status.toString() === 'false' && (
                    <span className="badge badge-warning font-size-10">
                      Open
                    </span>
                  )}
                </b>
              ),
              action:
                proposal.value.status.toString() === 'true' ? (
                  <Link
                    as="span"
                    className="badge badge-primary font-size-11"
                    style={{ cursor: 'pointer' }}
                    to={`/proposals/administrator/proposal-details?bigmap=${
                      proposal.value.operation ? 0 : proposal.value.t ? 2 : 1
                    }&contract=administrator&proposalId=${proposal.key}`}
                  >
                    View Details
                  </Link>
                ) : (
                  <Link
                    as="span"
                    className="badge badge-warning font-size-11"
                    style={{ cursor: 'pointer' }}
                    to={`/proposals/administrator/proposal-details?bigmap=${
                      proposal.value.operation ? 0 : proposal.value.t ? 2 : 1
                    }&contract=administrator&proposalId=${proposal.key}`}
                  >
                    Accept / Reject
                  </Link>
                ),
            };

            let csvRow = {
              proposalId: proposal.key,
              proposalType: proposal.value.operation
                ? proposal.value.operation
                : proposal.value.t
                ? 'transfer'
                : 'set_pause',
              date: proposal.time,
              parameters: ['string', 'integer', 'boolean'].includes(
                typeof proposal.value,
              )
                ? proposal.value.toString()
                : Object.keys(proposal.value).map((e, i) => {
                    return (
                      ![
                        'contractAddr',
                        'operation',
                        'status',
                        'approvals',
                        'rejects',
                      ].includes(e) &&
                      `${e}: ${
                        this.state.aliases[proposal.value[e].toString()] &&
                        this.state.aliases[proposal.value[e].toString()].name
                          ? this.state.aliases[proposal.value[e].toString()]
                              .name
                          : proposal.value[e].toString().length > 14
                          ? proposal.value[e].toString()
                          : proposal.value[e].toString()
                      }`
                    );
                  }),
              approvals: proposal.value.approvals.map((approval) =>
                this.state.aliases[approval] &&
                this.state.aliases[approval].name
                  ? this.state.aliases[approval].name
                  : approval,
              ),
              rejects: proposal.value.rejects.map((reject) =>
                this.state.aliases[reject] && this.state.aliases[reject].name
                  ? this.state.aliases[reject].name
                  : reject,
              ),
              status: `${
                proposal.value.status.toString() === 'true'
                  ? 'Closed'
                  : 'Pending'
              } ${
                proposal.value.status.toString() === 'true' && rejects > accepts
                  ? 'Rejected'
                  : ''
              }${
                proposal.value.status.toString() === 'true' && accepts > rejects
                  ? 'Accepted'
                  : ''
              }${proposal.value.status.toString() === 'false' ? 'Open' : ''}`,
              action: `https://${
                window.location.hostname
              }/proposals/administrator/proposal-details?bigmap=${
                proposal.value.operation ? 0 : proposal.value.t ? 2 : 1
              }&contract=administrator&proposalId=${proposal.key}`,
            };

            this.setState({
              transactions: {
                columns: [...this.state.transactions.columns],
                rows: [...this.state.transactions.rows, row],
              },
              csvRows: [...this.state.csvRows, csvRow],
            });
          }
          this.setState({ loading: false });
        } else {
          console.log(res.error);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">Administrator proposals</h4>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="p-3"
                      >
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.transactions}
                            className="mt-1"
                            hover
                          />
                          <CSV
                            name="Administrator_Proposals"
                            transactions={this.state.transactions}
                            csvRows={this.state.csvRows}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AdministratorProposals;
