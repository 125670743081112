import React, { Component } from 'react';

import {
  listAdresses,
  deleteWallet,
  updateWallet,
  revealWallet,
  dictAliases,
  rpcNode,
} from '../../helpers/api';

import { truncStringPortion } from '../../helpers/formatter.js';

import { Link } from 'react-router-dom';
import { Button, Container, Row, Col, Table } from 'reactstrap';

import DeleteModal from './DeleteModal.js';
import RevealModal from './RevealModal.js';
import UpdateModal from './UpdateModal.js';

import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Can from '../../components/Can';

import { connect } from 'react-redux';

class FA2Wallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      multiSigs: [],
      wallets: [],
      signers: [],
      loading: true,
      deleteModal: false,
      updateModal: false,
      revealModal: false,
      selectedWallet: {},
      success: null,
      error: null,
      aliases: [],
      filter: 'ledger',
      keystore: null,
      tezosNode: null,
      connected: false,
    };
  }

  toggleRevealModal = (revWallet) => {
    if (revWallet) {
      this.setState({ selectedWallet: revWallet, error: null, success: null });
    }
    this.setState({ revealModal: !this.state.revealModal, error: null });
  };

  toggleDeleteModal = (delWallet) => {
    if (delWallet) {
      this.setState({ selectedWallet: delWallet });
    }
    this.setState({ deleteModal: !this.state.deleteModal, error: null });
  };

  toggleUpdateModal = (updWallet) => {
    if (updWallet) {
      this.setState({ selectedWallet: updWallet });
    }
    this.setState({ updateModal: !this.state.updateModal, error: null });
  };

  revealWallet = (publicKeyHash, passPhrase) => {
    this._asyncRequest = revealWallet(publicKeyHash, passPhrase)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ success: 'Wallet revealed' });
        } else {
          this.setState({ error: res.error });
          console.log(res.error);
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString() });
        console.log(error);
      });
  };

  deleteWallet = (publicKeyHash, passPhrase = null) => {
    this._asyncRequest = deleteWallet(publicKeyHash, passPhrase)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          window.location.reload();
        } else {
          this.setState({ error: res.error });
          console.log(res.error);
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString() });
        console.log(error);
      });
  };

  updateWallet = (publicKeyHash, name, description = null) => {
    this._asyncRequest = updateWallet(publicKeyHash, name, description)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          window.location.reload();
        } else {
          this.setState({ error: res.error });
          console.log(res.error);
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString() });
        console.log(error);
      });
  };

  componentDidMount = () => {
    this._asyncRequest = listAdresses()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let resWallets = res.data.wallets;
          resWallets.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
          this.setState({
            wallets: resWallets,
            multiSigs: res.data.multiSigs,
            signers: res.data.signers,
            loading: false,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this._asyncRequest = rpcNode()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ tezosNode: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setFilter = (filter) => {
    this.setState({ filter: filter });
  };

  render() {
    return (
      <React.Fragment>
        <Can
          role={this.props.user.role}
          perform="operator:wallet"
          yes={() => (
            <React.Fragment>
              <DeleteModal
                deleteModal={this.state.deleteModal}
                toggleDeleteModal={this.toggleDeleteModal}
                selectedWallet={this.state.selectedWallet}
                error={this.state.error}
                deleteWallet={this.deleteWallet}
              />
              <RevealModal
                revealModal={this.state.revealModal}
                toggleRevealModal={this.toggleRevealModal}
                selectedWallet={this.state.selectedWallet}
                error={this.state.error}
                success={this.state.success}
                revealWallet={this.revealWallet}
              />
              <UpdateModal
                updateModal={this.state.updateModal}
                toggleUpdateModal={this.toggleUpdateModal}
                selectedWallet={this.state.selectedWallet}
                error={this.state.error}
                updateWallet={this.updateWallet}
              />
            </React.Fragment>
          )}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Wallets" breadcrumbItem="FA2 wallets" />
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading wallets...{' '}
                </Link>
              </div>
            ) : (
              <Row>
                {this.state.filter === 'ledger' ? (
                  <div className="btn btn-primary waves-effect waves-light btn-sm mr-2">
                    <i className="mdi mdi-shield-key mr-1"></i>Ledgers
                  </div>
                ) : (
                  <div
                    className="btn btn-secondary waves-effect waves-light btn-sm mr-2"
                    onClick={() => {
                      this.setFilter('ledger');
                    }}
                  >
                    <i className="mdi mdi-shield-key mr-1"></i>Ledgers
                  </div>
                )}
                {this.state.filter === 'platform' ? (
                  <div className="btn btn-primary waves-effect waves-light btn-sm mr-2">
                    <i className="mdi mdi-wallet mr-1"></i>Platforms
                  </div>
                ) : (
                  <div
                    className="btn btn-secondary waves-effect waves-light btn-sm mr-2"
                    onClick={() => {
                      this.setFilter('platform');
                    }}
                  >
                    <i className="mdi mdi-wallet mr-1"></i>Platforms
                  </div>
                )}
                {this.state.filter === 'multisig' ? (
                  <div className="btn btn-primary waves-effect waves-light btn-sm mr-2">
                    <i className="mdi mdi-wallet mr-1"></i>MultiSigs
                  </div>
                ) : (
                  <div
                    className="btn btn-secondary waves-effect waves-light btn-sm mr-2"
                    onClick={() => {
                      this.setFilter('multisig');
                    }}
                  >
                    <i className="mdi mdi-wallet mr-1"></i>MultiSigs
                  </div>
                )}
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      {this.state.filter !== 'multisig' ? (
                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: '100px' }}>
                                #
                              </th>
                              <th scope="col">Wallets</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Lock status</th>
                              <th scope="col">Reveal status</th>
                              <Can
                                role={this.props.user.role}
                                perform="operator:wallet"
                                yes={() => <th scope="col">Actions</th>}
                              />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.wallets &&
                              this.state.wallets.map(
                                (wallet) =>
                                  (wallet.walletType === this.state.filter ||
                                    (this.state.filter === 'platform' &&
                                      wallet.walletType === 'auto')) && (
                                    <tr key={wallet.publicKeyHash}>
                                      <td>
                                        {wallet.walletType === 'platform' && (
                                          <i className="mdi mdi-wallet text-primary h1"></i>
                                        )}
                                        {wallet.walletType === 'auto' && (
                                          <i className="mdi mdi-cogs text-primary h1"></i>
                                        )}
                                        {wallet.walletType === 'ledger' && (
                                          <i className="mdi mdi-shield-key text-primary h1"></i>
                                        )}
                                      </td>
                                      <td>
                                        <h5 className="text-truncate font-size-14">
                                          <Link to="#" className="text-dark">
                                            {wallet.name}
                                            {wallet.isFeesFaucet && (
                                              <span className="badge badge-pink ml-2">
                                                Fees faucet
                                              </span>
                                            )}
                                            {wallet.isMinter && (
                                              <span className="badge badge-warning ml-2">
                                                Delegatted minter
                                              </span>
                                            )}
                                          </Link>
                                        </h5>
                                        {wallet.email && (
                                          <p className="mb-2">{wallet.email}</p>
                                        )}
                                        <p className="text-muted mb-0">
                                          {wallet.publicKeyHash}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {wallet.description}
                                        </p>
                                      </td>
                                      <td>
                                        <SVGLogo />{' '}
                                        {wallet.value && wallet.value.balance
                                          ? parseFloat(
                                              wallet.value.balance /
                                                this.state.decimals,
                                            ).toLocaleString('fr')
                                          : 0}{' '}
                                        <br />
                                        <span className="badge badge-primary ml-1 align-bottom">
                                          {wallet.details &&
                                          wallet.details.total_balance
                                            ? wallet.details.total_balance.toLocaleString(
                                                'fr',
                                              )
                                            : 0}{' '}
                                          <TezosLogo width="10" height="10" />
                                        </span>
                                      </td>
                                      <td>
                                        {wallet.value.lock.toString() ===
                                        'true' ? (
                                          <span className="badge badge-success">
                                            Locked
                                          </span>
                                        ) : (
                                          <span className="badge badge-primary">
                                            Unlocked
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {wallet.details &&
                                        wallet.details.is_revealed ? (
                                          <span className="badge badge-success">
                                            Revealed
                                          </span>
                                        ) : (
                                          <Can
                                            role={this.props.user.role}
                                            perform="operator:wallet"
                                            yes={() => (
                                              <Button
                                                type="button"
                                                color="primary"
                                                size="sm"
                                                className="btn-rounded waves-effect waves-light"
                                                onClick={() => {
                                                  this.toggleRevealModal(
                                                    wallet,
                                                  );
                                                }}
                                              >
                                                Reveal
                                              </Button>
                                            )}
                                            no={() => (
                                              <span className="badge badge-danger">
                                                Unrevealed
                                              </span>
                                            )}
                                          />
                                        )}
                                      </td>
                                      {wallet.walletType !== 'auto' ? (
                                        <Can
                                          role={this.props.user.role}
                                          perform="operator:wallet"
                                          yes={() => (
                                            <td>
                                              <button
                                                className="btn btn-lg mr-1"
                                                onClick={() => {
                                                  this.toggleUpdateModal(
                                                    wallet,
                                                  );
                                                }}
                                              >
                                                <i className="bx bx-wrench"></i>
                                              </button>
                                              <button
                                                className="btn btn-lg"
                                                onClick={() => {
                                                  this.toggleDeleteModal(
                                                    wallet,
                                                  );
                                                }}
                                              >
                                                <i className="bx bx-trash"></i>
                                              </button>
                                            </td>
                                          )}
                                        />
                                      ) : (
                                        <td></td>
                                      )}
                                    </tr>
                                  ),
                              )}
                          </tbody>
                        </Table>
                      ) : (
                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: '100px' }}>
                                #
                              </th>
                              <th scope="col">MultiSigs</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Nb proposals</th>
                              <th scope="col">Signers</th>
                              <th scope="col">Proposals</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.multiSigs &&
                              this.state.multiSigs.map((multiSig) => (
                                <tr key={multiSig.publicKeyHash}>
                                  <td>
                                    <i className="mdi mdi-draw text-primary h1"></i>
                                  </td>
                                  <td>
                                    <h5 className="text-truncate font-size-14">
                                      <Link to="#" className="text-dark">
                                        {multiSig.name}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {multiSig.publicKeyHash}
                                    </p>
                                    <p className="text-muted mb-0">
                                      {multiSig.description}
                                    </p>
                                  </td>
                                  <td>
                                    <SVGLogo />{' '}
                                    {parseFloat(
                                      multiSig.value.balance /
                                        this.state.decimals,
                                    ).toLocaleString('fr')}
                                  </td>
                                  <td>
                                    <span className="badge badge-primary">
                                      {multiSig.proposals} proposals
                                    </span>
                                  </td>
                                  <td>
                                    {multiSig.storage &&
                                    multiSig.storage.signers ? (
                                      <>
                                        {multiSig.storage.signers.map(
                                          (signer) => (
                                            <p
                                              className="font-size-10 mt-1 mb-0"
                                              key={'sig_' + signer}
                                            >
                                              {this.state.aliases[signer] &&
                                              this.state.aliases[signer].name
                                                ? this.state.aliases[signer]
                                                    .name
                                                : truncStringPortion(
                                                    signer,
                                                    8,
                                                    6,
                                                  )}
                                            </p>
                                          ),
                                        )}
                                      </>
                                    ) : (
                                      multiSig.storage &&
                                      multiSig.storage.operators &&
                                      multiSig.storage.controllers && (
                                        <>
                                          {multiSig.storage.operators.map(
                                            (operator) => (
                                              <p
                                                className="font-size-10 mt-1 mb-0"
                                                key={'op_' + operator}
                                              >
                                                {this.state.aliases[operator] &&
                                                this.state.aliases[operator]
                                                  .name
                                                  ? this.state.aliases[operator]
                                                      .name
                                                  : truncStringPortion(
                                                      operator,
                                                      8,
                                                      6,
                                                    )}{' '}
                                                <span className="float-right">
                                                  (operator)
                                                </span>
                                              </p>
                                            ),
                                          )}
                                          {multiSig.storage.controllers.map(
                                            (controller) => (
                                              <p
                                                className="font-size-10 mt-1 mb-0"
                                                key={'ctrl_' + controller}
                                              >
                                                {this.state.aliases[
                                                  controller
                                                ] &&
                                                this.state.aliases[controller]
                                                  .name
                                                  ? this.state.aliases[
                                                      controller
                                                    ].name
                                                  : truncStringPortion(
                                                      controller,
                                                      8,
                                                      6,
                                                    )}{' '}
                                                <span className="float-right">
                                                  (controller)
                                                </span>
                                              </p>
                                            ),
                                          )}
                                        </>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      as="span"
                                      className="badge badge-primary font-size-11 clickable"
                                      to={`/proposals/${multiSig.name.toLowerCase()}`}
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(FA2Wallets);
