import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { TezosToolkit } from "@taquito/taquito";
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { LedgerSigner, DerivationType } from "@taquito/ledger-signer";
import { char2Bytes } from "@taquito/utils";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  dictAliases,
  financialOps,
  callEntrypoint,
  registerProposal,
  contractStorage,
  registerOperation,
  getEscrowAmount,
  reserveBalance,
} from "../../helpers/api";
import {
  truncStringPortion,
  capitalizeFirstLetter,
  convertErrorCode,
} from "../../helpers/formatter.js";
import SVGLogo from "../../components/Common/SVGLogo.js";
import TezosLogo from "../../components/Common/TezosLogo.js";
import OpModal from "./Modals/OpModal.js";
import SuccessModal from "./Modals/SuccessModal.js";
import { TezosContractIntrospector } from "conseiljs";

const derivationPath = "44'/1729'/0'/0'/0'";
const descLimit = 140;

class FinancialOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 1000000,
      tokenId: 0,
      activeTab: "1",
      isMenu: false,
      passActive: null,
      aliases: [],
      storage: {},
      details: {},
      entrypoint: {},
      signers: {},
      parameters: [],
      operation: null,
      keystore: null,
      connected: false,
      callingPoint: null,
      tezosNode: null,
      lughContract: null,
      feesContract: null,
      error: null,
      selectedWallet: null,
      pending: false,
      descCharLeft: descLimit,
      description: null,
      success: null,
      lughStorage: null,
      escrowAmount: 0,
      jsonParams: null,
      amountOverFlow: false,
      reserveBalance: 0,
      displayResult: null,
      displayAmount: 0,
      requestRef: null,
      requestAmount: 0,
      requestReceiver: 0,
    };
    this.passPhrase = React.createRef();
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changePassActive = (wType, publicKeyHash = null) => {
    this.setState({ passActive: wType });
    if (publicKeyHash) this.setState({ selectedWallet: publicKeyHash });
  };

  genRanHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const requestRef = query.get("ref");
    const requestAmount = query.get("amount")
      ? parseInt(query.get("amount"))
      : 0;
    const requestReceiver = query.get("receiver");
    this.setState({
      requestRef,
      requestAmount,
      requestReceiver,
      description: requestRef ? `Request ${requestRef}` : "",
    });
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === "SUCCESS") {
          this.setState({
            aliases: res.data,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.handleFetch();
  };

  handleChangeInput = (event) => {
    this["ref_cents"].current.value = event.target.value.slice(0, 2);
  };

  handleFetch = () => {
    const contract = this.props.match.params.contract;
    const op = this.props.match.params.op;
    this.setState({ operation: op, proposalId: `${op}${this.genRanHex(6)}` });
    this._asyncRequest = financialOps(contract)
      .then((res) => {
        if (res.status === "SUCCESS") {
          if (op === "reserve_transfer" || op === "burn") {
            this._asyncRequest = reserveBalance()
              .then((result) => {
                if (result.status === "SUCCESS") {
                  this.setState({ reserveBalance: result.data.balance });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          res.data.details.entrypoints.forEach((entry) => {
            if (
              contract !== "administrator" &&
              entry.entrypoint === "createProposal"
            ) {
              this.setState({ callingPoint: "createProposal" });
              this.processEntry(entry);
            } else if (
              op === "force_transfer" &&
              entry.entrypoint === "createTransferProposal"
            ) {
              this.setState({ callingPoint: "createTransferProposal" });
              this.processEntry(entry);
            } else if (
              ["set_pause", "setPause"].includes(op) &&
              entry.entrypoint === "createPauseProposal"
            ) {
              this.setState({ callingPoint: "createPauseProposal" });
              this.processEntry(entry);
            } else if (
              ["set_metadata"].includes(op) &&
              entry.entrypoint === "createSetMetadata"
            ) {
              this.setState({ callingPoint: "createSetMetadata" });
              this.processEntry(entry);
            } else if (
              [
                "set_fees_faucet",
                "set_lock",
                "setLock",
                "setWhiteListing",
                "set_fees_manager",
              ].includes(op) &&
              entry.entrypoint === "createAddressProposal"
            ) {
              this.setState({ callingPoint: "createAddressProposal" });
              this.processEntry(entry);
            } else if (
              [
                "updateThreshold",
                "updateGasFee",
                "updateGaslessFee",
                "updateStorageFee",
              ].includes(op) &&
              entry.entrypoint === "createFeesManagerProposal"
            ) {
              this.setState({ callingPoint: "createFeesManagerProposal" });
              this.processEntry(entry);
            } else if (
              ["set_rights_manager"].includes(op) &&
              entry.entrypoint === "createRemoveOptionProposal"
            ) {
              this.setState({ callingPoint: "createRemoveOptionProposal" });
              this.processEntry(entry);
            }
          });
          console.log(res.data.details.contract);
          this.setState({
            storage: res.data.storage,
            details: res.data.details,
            signers: res.data.signers,
            tezosNode: res.data.tezosNode,
            lughContract: res.data.lughContract,
            feesContract: res.data.feesContract,
          });
          this._asyncRequest = contractStorage("token")
            .then((resp) => {
              if (resp.status === "SUCCESS") {
                this.setState({ lughStorage: resp.data.value });
              } else {
                console.log(resp.error);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this._asyncRequest = getEscrowAmount()
            .then((resp) => {
              if (resp.status === "SUCCESS") {
                this.setState({ escrowAmount: resp.amount });
              } else {
                console.log(resp.error);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  processEntry = (entry) => {
    this.setState({ entrypoint: entry });
    entry.values.forEach((value) => {
      if (
        !["contractAddr", "operation", "proposalId", "tokenId"].includes(
          value.name
        )
      ) {
        if (value.name === "amount") this["ref_cents"] = React.createRef();
        this["ref_" + value.name] = React.createRef();
        this.setState({ parameters: [...this.state.parameters, value] });
      }
    });
  };

  entryParsing = (parameters, entrypoint, params) => {
    const entryPoints =
      TezosContractIntrospector.generateEntryPointsFromParams(parameters);
    var i = 0;
    while (i < entryPoints.length) {
      if (entryPoints[i].name === entrypoint) break;
      i++;
    }
    return entryPoints[i].generateInvocationPair(...params);
  };

  buildParameters = () => {
    let params = [];
    let jsonParams = {};
    this.state.entrypoint.values.forEach((value) => {
      try {
        if (
          "contractAddr" === value.name &&
          [
            "updateThreshold",
            "updateGasFee",
            "updateGaslessFee",
            "updateStorageFee",
          ].includes(this.state.operation)
        ) {
          params.push('"' + this.state.feesContract + '"');
          jsonParams["contractAddr"] = this.state.feesContract;
        } else if ("contractAddr" === value.name) {
          params.push('"' + this.state.lughContract + '"');
          jsonParams["contractAddr"] = this.state.lughContract;
        } else if ("operation" === value.name) {
          params.push('"' + this.state.operation + '"');
          jsonParams["operation"] = this.state.operation;
        } else if ("proposalId" === value.name) {
          params.push('"' + this.state.proposalId + '"');
          jsonParams["proposalId"] = this.state.proposalId;
        } else if ("v" === value.name) {
          params.push(char2Bytes(this["ref_" + value.name].current.value));
          jsonParams["tokenId"] = this["ref_" + value.name].current.value;
        } else if ("tokenId" === value.name) {
          params.push('0');
          jsonParams["tokenId"] = 0;
        } else if ("amount" === value.name) {
          if (this["ref_cents"].current.value) {
            params.push(
              parseFloat(
                this["ref_" + value.name].current.value * this.state.decimals
              ) + parseFloat(this["ref_cents"].current.value * 10000)
            );
            jsonParams["amount"] =
              parseFloat(
                this["ref_" + value.name].current.value * this.state.decimals
              ) + parseFloat(this["ref_cents"].current.value * 10000);
          } else {
            params.push(
              parseFloat(
                this["ref_" + value.name].current.value * this.state.decimals
              )
            );
            jsonParams["amount"] = parseFloat(
              this["ref_" + value.name].current.value * this.state.decimals
            );
          }
          this.setState({
            displayAmount: parseFloat(
              jsonParams["amount"] / this.state.decimals
            ),
          });
        } else {
          if (
            !this["ref_" + value.name].current ||
            !this["ref_" + value.name].current.value ||
            this["ref_" + value.name].current.value.length < 1
          ) {
            this.setState({ error: `No value for ${value.name.toString()}` });
            return;
          } else {
            if (value.type === "bool")
              params.push(this["ref_" + value.name].current.value);
            else if (
              Number.isNaN(parseFloat(this["ref_" + value.name].current.value))
            )
              params.push('"' + this["ref_" + value.name].current.value + '"');
            else
              params.push(parseFloat(this["ref_" + value.name].current.value));
            jsonParams[value.name] = this["ref_" + value.name].current.value;
          }
        }
      } catch (e) {
        alert(value.name);
      }
    });
    this.setState({ jsonParams: jsonParams });
    return this.entryParsing(
      this.state.details.parameters,
      this.state.callingPoint,
      params
    );
  };

  ledgerParameters = () => {
    let params = [];
    let jsonParams = {};
    this.state.entrypoint.values.forEach((value) => {
      if (
        "contractAddr" === value.name &&
        [
          "updateThreshold",
          "updateGasFee",
          "updateGaslessFee",
          "updateStorageFee",
        ].includes(this.state.operation)
      ) {
        params.push(this.state.feesContract);
        jsonParams["contractAddr"] = this.state.feesContract;
      } else if ("contractAddr" === value.name) {
        params.push(this.state.lughContract);
        jsonParams["contractAddr"] = this.state.lughContract;
      } else if ("operation" === value.name) {
        params.push(this.state.operation);
        jsonParams["operation"] = this.state.operation;
      } else if ("proposalId" === value.name) {
        params.push(this.state.proposalId);
        jsonParams["proposalId"] = this.state.proposalId;
      } else if ("tokenId" === value.name) {
        params.push(this.state.tokenId);
        jsonParams["tokenId"] = this.state.tokenId;
      } else if ("v" === value.name) {
        params.push(char2Bytes(this["ref_" + value.name].current.value));
        jsonParams["tokenId"] = this["ref_" + value.name].current.value;
      } else if ("amount" === value.name) {
        if (this["ref_cents"].current.value) {
          params.push(
            parseFloat(
              this["ref_" + value.name].current.value * this.state.decimals
            ) + parseFloat(this["ref_cents"].current.value * 10000)
          );
          jsonParams["amount"] =
            parseFloat(
              this["ref_" + value.name].current.value * this.state.decimals
            ) + parseFloat(this["ref_cents"].current.value * 10000);
        } else {
          params.push(
            parseFloat(
              this["ref_" + value.name].current.value * this.state.decimals
            )
          );
          jsonParams["amount"] = parseFloat(
            this["ref_" + value.name].current.value * this.state.decimals
          );
        }
        this.setState({
          displayAmount: parseFloat(jsonParams["amount"] / this.state.decimals),
        });
      } else {
        if (
          !this["ref_" + value.name].current ||
          !this["ref_" + value.name].current.value ||
          this["ref_" + value.name].current.value.length < 1
        ) {
          this.setState({ error: `No value for ${value.name.toString()}` });
          return;
        } else {
          if (value.type === "bool")
            params.push(this["ref_" + value.name].current.value);
          else if (
            Number.isNaN(parseFloat(this["ref_" + value.name].current.value))
          )
            params.push(this["ref_" + value.name].current.value);
          else params.push(parseFloat(this["ref_" + value.name].current.value));
          jsonParams[value.name] = this["ref_" + value.name].current.value;
        }
      }
    });
    this.setState({ jsonParams: jsonParams });
    return params;
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  ledgerSendOp = async () => {
    try {
      this.setState({
        pending: true,
        info: `Connect your Ledger and approve connection request`,
      });
      const Tezos = new TezosToolkit(this.state.tezosNode);
      const transport = await TransportWebHID.create();
      const ledgerSigner = new LedgerSigner(
        transport, //required
        derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
        true, // prompt optional
        DerivationType.ED25519 // derivationType optional
      );
      Tezos.setProvider({ signer: ledgerSigner });
      const publicKeyHash = await Tezos.signer.publicKeyHash();
      if (this.state.selectedWallet !== publicKeyHash) {
        this.setState({
          error: "Not connected with the right Ledger",
          pending: false,
          success: false,
        });
        return;
      }
      const contract = await Tezos.wallet.at(this.state.details.contract);
      const genParams = this.ledgerParameters();
      this.setState({
        info: `Follow the instruction from your Ledger to send operation`,
      });
      let operation = await contract.methods[this.state.callingPoint](
        ...genParams
      ).send();
      this.setState({
        info: "Awaiting confirmation. It may take up to 1 min...",
      });
      await operation.confirmation();
      this.setState({
        pending: false,
        success: `Operation ${operation.opHash} sent`,
      });
      this._asyncRequest = registerProposal(
        this.state.proposalId,
        this.state.selectedWallet,
        this.props.match.params.contract,
        this.state.description,
        this.state.operation,
        this.state.jsonParams,
        this.state.requestRef
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ displayResult: true });
      this._asyncRequest = registerOperation(
        operation.opHash,
        this.state.operation.split("_").join(" "),
        `Proposal ID ${this.state.proposalId} sent from ${this.props.match.params.contract}`,
        this.state.selectedWallet,
        this.props.match.params.contract,
        this.state.proposalId
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      await transport.close();
    } catch (e) {
      console.log(e);
      this.setState({
        pending: false,
        info: null,
        success: false,
        error: e.toString(),
      });
    }
  };

  platformSendOp = () => {
    const genParams = this.buildParameters();
    this.setState({
      pending: true,
      info: "Processing transaction...",
      error: null,
      success: null,
      displayResult: false,
    });
    this._asyncRequest = callEntrypoint(
      genParams,
      this.state.selectedWallet,
      this.passPhrase.current.value,
      this.props.match.params.contract
    )
      .then((res) => {
        if (res.status === "SUCCESS") {
          this.setState({
            pending: false,
            success: `Operation ${res.data} sent`,
          });
          this._asyncRequest = registerProposal(
            this.state.proposalId,
            this.state.selectedWallet,
            this.props.match.params.contract,
            this.state.description,
            this.state.operation,
            this.state.jsonParams,
            this.state.requestRef
          )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) => {
              console.log(error);
            });
          this.setState({ displayResult: true });
          this._asyncRequest = registerOperation(
            res.data,
            this.state.operation.split("_").join(" "),
            `Proposal ID ${this.state.proposalId} sent from ${this.props.match.params.contract}`,
            this.state.selectedWallet,
            this.props.match.params.contract,
            this.state.proposalId
          )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({ pending: false, error: res.error });
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.toString().split(/[: ]+/).pop() &&
          error.toString().split(/[: ]+/).pop() !== "ciphertext"
        ) {
          const errorCode = convertErrorCode(
            this.props.match.params.contract,
            error.toString().split(/[: ]+/).pop().replace(")", "")
          );
          if (errorCode !== undefined)
            this.setState({ pending: false, error: `Error : ${errorCode}` });
          else this.setState({ pending: false, error: error.toString() });
        } else if (
          error.toString().split(/[: ]+/).pop() &&
          error.toString().split(/[: ]+/).pop() === "ciphertext"
        ) {
          this.setState({ pending: false, error: "Could not load wallet" });
        } else {
          this.setState({ pending: false, error: error.toString() });
        }
      });
  };

  handleAmountValue = (event) => {
    if (this.state.operation === "mint") {
      if (
        parseFloat(event.target.value) +
          parseFloat(
            this.state.lughStorage.totalSupply / this.state.decimals
          ) >
        this.state.escrowAmount
      )
        this.setState({ amountOverFlow: true });
      else this.setState({ amountOverFlow: false });
    } else if (
      this.state.operation === "reserve_transfer" ||
      this.state.operation === "burn"
    ) {
      if (
        parseFloat(event.target.value) >
        parseFloat(this.state.reserveBalance / this.state.decimals)
      )
        this.setState({ amountOverFlow: true });
      else this.setState({ amountOverFlow: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <SuccessModal
              displayResult={this.state.displayResult}
              proposalId={this.state.proposalId}
              op={this.props.match.params.op}
              contract={this.props.match.params.contract}
              displayAmount={this.state.displayAmount}
              aliases={this.state.aliases}
              selectedWallet={this.state.selectedWallet}
            />
            <OpModal
              pending={this.state.pending}
              info={this.state.info}
              passActive={this.state.passActive}
            />
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Financial Operation"
              breadcrumbItem={
                this.state.operation &&
                capitalizeFirstLetter(
                  ["set_rights_manager", "set_fees_manager"].includes(
                    this.state.operation
                  )
                    ? this.state.operation
                        .split("_")
                        .join(" ")
                        .replace("set", "remove")
                    : this.state.operation.split("_").join(" ")
                )
              }
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Send{" "}
                      {this.state.operation &&
                        capitalizeFirstLetter(
                          ["set_rights_manager", "set_fees_manager"].includes(
                            this.state.operation
                          )
                            ? this.state.operation
                                .split("_")
                                .join(" ")
                                .replace("set", "remove")
                            : this.state.operation.split("_").join(" ")
                        )}{" "}
                      proposal
                    </h4>
                    <p>
                      <small>
                        {this.state.tokenId || this.state.tokenId === 0
                          ? "FA2 Contract"
                          : "FA1.2 Contract"}
                      </small>
                    </p>
                    {this.state.lughStorage &&
                      !this.state.lughStorage.paused.toString() === "true" &&
                      ["setPause", "reserve_transfer"].includes(
                        this.state.operation
                      ) && (
                        <p className="badge badge-primary font-size-12 float-right mt-1 mr-1">
                          <i className="bx bx-check-circle"></i> Transfers are
                          currently authorized
                        </p>
                      )}
                    {this.state.lughStorage &&
                      this.state.lughStorage.paused.toString() === "true" &&
                      ["setPause", "reserve_transfer"].includes(
                        this.state.operation
                      ) && (
                        <p className="badge badge-danger font-size-12 float-right mt-1 mr-1">
                          <i className="bx bx-info-circle"></i> Transfers are
                          currently unauthorized
                        </p>
                      )}
                    {this.state.lughStorage &&
                      this.state.operation === "mint" &&
                      parseFloat(
                        this.state.lughStorage.totalSupply /
                          this.state.decimals
                      ) > this.state.escrowAmount && (
                        <p className="badge badge-danger font-size-14 mt-1 mr-2">
                          <i className="bx bx-info-circle"></i> Circulating
                          supply is higher than Reserve bank account balance
                        </p>
                      )}
                    {this.state.lughStorage &&
                      this.state.operation === "mint" &&
                      this.state.escrowAmount >=
                        parseFloat(
                          this.state.lughStorage.totalSupply /
                            this.state.decimals
                        ) && (
                        <p className="badge badge-pink font-size-14 mt-1 mr-2">
                          <i className="bx bx-info-circle"></i> Minting capacity
                          of{" "}
                          {(
                            this.state.escrowAmount -
                            parseFloat(
                              this.state.lughStorage.totalSupply /
                                this.state.decimals
                            )
                          ).toLocaleString("fr")}{" "}
                          <SVGLogo width="16" height="16" />{" "}
                        </p>
                      )}
                    {this.state.lughStorage &&
                      this.state.operation === "mint" &&
                      this.state.escrowAmount >=
                        parseFloat(
                          this.state.lughStorage.totalSupply /
                            this.state.decimals
                        ) && (
                        <p className="badge badge-success font-size-12 mt-1 mr-2">
                          <i className="bx bx-info-circle"></i> Current Reserve
                          Bank account balance of{" "}
                          {this.state.escrowAmount.toLocaleString("fr")} €
                        </p>
                      )}
                    {(this.state.operation === "reserve_transfer" ||
                      this.state.operation === "burn") && (
                      <p className="badge badge-pink font-size-14 mt-1 mr-1">
                        <i className="bx bx-info-circle"></i> Reserve capacity
                        of{" "}
                        {parseFloat(
                          this.state.reserveBalance / 1000000
                        ).toLocaleString("fr")}{" "}
                        <SVGLogo width="16" height="16" />{" "}
                      </p>
                    )}
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="operation">
                          <form>
                            <div className="mb-2">
                              <Label>Select a signer to send proposal</Label>
                              {this.state.storage.value && (
                                <div>
                                  {this.state.storage.value.operators ? (
                                    <Row>
                                      {this.state.storage.value.operators.map(
                                        (operator) => (
                                          <Col xl="3" sm="12" key={operator}>
                                            <div className="mb-3">
                                              <label
                                                className="card-radio-label mb-2"
                                                onClick={() => {
                                                  this.state.aliases[
                                                    operator
                                                  ] &&
                                                    this.state.aliases[operator]
                                                      .walletType !== "auto" &&
                                                    this.changePassActive(
                                                      this.state.aliases[
                                                        operator
                                                      ].walletType,
                                                      operator
                                                    );
                                                }}
                                              >
                                                {this.state.aliases[operator] &&
                                                  this.state.aliases[operator]
                                                    .walletType !== "auto" && (
                                                    <input
                                                      type="radio"
                                                      name="currency"
                                                      id={operator}
                                                      className="card-radio-input"
                                                      readOnly
                                                    />
                                                  )}

                                                <div
                                                  className={
                                                    this.state.aliases[
                                                      operator
                                                    ] &&
                                                    this.state.aliases[operator]
                                                      .walletType !== "auto"
                                                      ? "card-radio"
                                                      : "card"
                                                  }
                                                >
                                                  <div>
                                                    {this.state.aliases[
                                                      operator
                                                    ] &&
                                                    this.state.aliases[operator]
                                                      .walletType ===
                                                      "ledger" ? (
                                                      <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                    ) : this.state.aliases[
                                                        operator
                                                      ] &&
                                                      this.state.aliases[
                                                        operator
                                                      ].walletType ===
                                                        "platform" ? (
                                                      <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                    ) : (
                                                      <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                    )}
                                                    <span>
                                                      {this.state.aliases[
                                                        operator
                                                      ] &&
                                                      this.state.aliases[
                                                        operator
                                                      ].name ? (
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              this.state.aliases[
                                                                operator
                                                              ].name.replace(
                                                                "(",
                                                                "<br/>("
                                                              ),
                                                          }}
                                                        ></span>
                                                      ) : (
                                                        truncStringPortion(
                                                          operator,
                                                          8,
                                                          6
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <p className="text-muted font-size-11 mb-1">
                                                      {truncStringPortion(
                                                        operator,
                                                        8,
                                                        6
                                                      )}
                                                    </p>
                                                    <h5 className="font-size-16 mb-1">
                                                      {this.state.signers[
                                                        operator
                                                      ]
                                                        ? Math.round(
                                                            this.state.signers[
                                                              operator
                                                            ].total_balance *
                                                              100
                                                          ) / 100
                                                        : 0}{" "}
                                                      <TezosLogo
                                                        width="16"
                                                        height="16"
                                                      />
                                                    </h5>
                                                    <span className="text-muted">
                                                      ~
                                                      {this.state.signers[
                                                        operator
                                                      ] &&
                                                      this.state.signers[
                                                        operator
                                                      ].total_balance > 0.12
                                                        ? Math.round(
                                                            this.state.signers[
                                                              operator
                                                            ].total_balance /
                                                              0.12
                                                          )
                                                        : 0}{" "}
                                                      operations
                                                    </span>
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                          </Col>
                                        )
                                      )}
                                      {this.state.storage.value.controllers.map(
                                        (controller) => (
                                          <Col xl="3" sm="12" key={controller}>
                                            <div className="mb-3">
                                              <label
                                                className="card-radio-label mb-2"
                                                onClick={() => {
                                                  this.state.aliases[
                                                    controller
                                                  ] &&
                                                    this.state.aliases[
                                                      controller
                                                    ].walletType !== "auto" &&
                                                    this.changePassActive(
                                                      this.state.aliases[
                                                        controller
                                                      ].walletType,
                                                      controller
                                                    );
                                                }}
                                              >
                                                {this.state.aliases[
                                                  controller
                                                ] &&
                                                  this.state.aliases[controller]
                                                    .walletType !== "auto" && (
                                                    <input
                                                      type="radio"
                                                      name="currency"
                                                      id={controller}
                                                      className="card-radio-input"
                                                      readOnly
                                                    />
                                                  )}

                                                <div
                                                  className={
                                                    this.state.aliases[
                                                      controller
                                                    ] &&
                                                    this.state.aliases[
                                                      controller
                                                    ].walletType !== "auto"
                                                      ? "card-radio"
                                                      : "card"
                                                  }
                                                >
                                                  <div>
                                                    {this.state.aliases[
                                                      controller
                                                    ] &&
                                                    this.state.aliases[
                                                      controller
                                                    ].walletType ===
                                                      "ledger" ? (
                                                      <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                    ) : this.state.aliases[
                                                        controller
                                                      ] &&
                                                      this.state.aliases[
                                                        controller
                                                      ].walletType ===
                                                        "platform" ? (
                                                      <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                    ) : (
                                                      <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                    )}
                                                    <span>
                                                      {this.state.aliases[
                                                        controller
                                                      ] &&
                                                      this.state.aliases[
                                                        controller
                                                      ].name ? (
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              this.state.aliases[
                                                                controller
                                                              ].name.replace(
                                                                "(",
                                                                "<br/>("
                                                              ),
                                                          }}
                                                        ></span>
                                                      ) : (
                                                        truncStringPortion(
                                                          controller,
                                                          8,
                                                          6
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <p className="text-muted font-size-11 mb-1">
                                                      {truncStringPortion(
                                                        controller,
                                                        8,
                                                        6
                                                      )}
                                                    </p>
                                                    <h5 className="font-size-16 mb-1">
                                                      {this.state.signers[
                                                        controller
                                                      ]
                                                        ? Math.round(
                                                            this.state.signers[
                                                              controller
                                                            ].total_balance *
                                                              100
                                                          ) / 100
                                                        : 0}{" "}
                                                      <TezosLogo
                                                        width="16"
                                                        height="16"
                                                      />
                                                    </h5>
                                                    <span className="text-muted">
                                                      ~
                                                      {this.state.signers[
                                                        controller
                                                      ] &&
                                                      this.state.signers[
                                                        controller
                                                      ].total_balance > 0.12
                                                        ? Math.round(
                                                            this.state.signers[
                                                              controller
                                                            ].total_balance /
                                                              0.12
                                                          )
                                                        : 0}{" "}
                                                      operations
                                                    </span>
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  ) : (
                                    <Row>
                                      {this.state.storage.value.signers.map(
                                        (signer) => (
                                          <Col xl="3" sm="12" key={signer}>
                                            <div className="mb-3">
                                              <label
                                                className="card-radio-label mb-2"
                                                onClick={() => {
                                                  this.state.aliases[signer] &&
                                                    this.state.aliases[signer]
                                                      .walletType !== "auto" &&
                                                    this.changePassActive(
                                                      this.state.aliases[signer]
                                                        .walletType,
                                                      signer
                                                    );
                                                }}
                                              >
                                                {this.state.aliases[signer] &&
                                                  this.state.aliases[signer]
                                                    .walletType !== "auto" && (
                                                    <input
                                                      type="radio"
                                                      name="currency"
                                                      id={signer}
                                                      className="card-radio-input"
                                                      readOnly
                                                    />
                                                  )}

                                                <div
                                                  className={
                                                    this.state.aliases[
                                                      signer
                                                    ] &&
                                                    this.state.aliases[signer]
                                                      .walletType !== "auto"
                                                      ? "card-radio"
                                                      : "card"
                                                  }
                                                >
                                                  <div>
                                                    {this.state.aliases[
                                                      signer
                                                    ] &&
                                                    this.state.aliases[signer]
                                                      .walletType ===
                                                      "ledger" ? (
                                                      <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                    ) : this.state.aliases[
                                                        signer
                                                      ] &&
                                                      this.state.aliases[signer]
                                                        .walletType ===
                                                        "platform" ? (
                                                      <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                    ) : (
                                                      <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                    )}
                                                    <span>
                                                      {this.state.aliases[
                                                        signer
                                                      ] &&
                                                      this.state.aliases[signer]
                                                        .name ? (
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              this.state.aliases[
                                                                signer
                                                              ].name.replace(
                                                                "(",
                                                                "<br/>("
                                                              ),
                                                          }}
                                                        ></span>
                                                      ) : (
                                                        truncStringPortion(
                                                          signer,
                                                          8,
                                                          6
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <p className="text-muted font-size-11 mb-1">
                                                      {truncStringPortion(
                                                        signer,
                                                        8,
                                                        6
                                                      )}
                                                    </p>
                                                    <h5 className="font-size-16 mb-1">
                                                      {this.state.signers[
                                                        signer
                                                      ]
                                                        ? Math.round(
                                                            this.state.signers[
                                                              signer
                                                            ].total_balance *
                                                              100
                                                          ) / 100
                                                        : 0}{" "}
                                                      <TezosLogo
                                                        width="16"
                                                        height="16"
                                                      />
                                                    </h5>
                                                    <span className="text-muted">
                                                      ~
                                                      {this.state.signers[
                                                        signer
                                                      ] &&
                                                      this.state.signers[signer]
                                                        .total_balance > 0.12
                                                        ? Math.round(
                                                            this.state.signers[
                                                              signer
                                                            ].total_balance /
                                                              0.12
                                                          )
                                                        : 0}{" "}
                                                      operations
                                                    </span>
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  )}
                                </div>
                              )}
                            </div>
                            <FormGroup>
                              <Label>Set a proposal description</Label>
                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.descCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={descLimit}
                                      onChange={this.updateDescription}
                                      value={this.state.description}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            {this.state.amountOverFlow &&
                              this.state.operation === "mint" && (
                                <p className="badge badge-danger font-size-12 mt-1 mr-1">
                                  <i className="bx bx-bomb"></i> Mint value too
                                  high!
                                </p>
                              )}
                            {this.state.amountOverFlow &&
                              this.state.operation === "reserve_transfer" && (
                                <p className="badge badge-danger font-size-12 mt-1 mr-1">
                                  <i className="bx bx-bomb"></i> Transfer amount
                                  higher than available balance
                                </p>
                              )}
                            {this.state.parameters.map((parameter) => (
                              <FormGroup
                                key={parameter.name}
                                className={
                                  (parameter.name === "amount" &&
                                    this.props.match.params.op ===
                                      "remove_minter") ||
                                  (parameter.name === "state" &&
                                    [
                                      "set_fees_faucet",
                                      "updateThreshold",
                                      "updateGasFee",
                                      "updateGaslessFee",
                                      "updateStorageFee",
                                    ].includes(this.state.operation))
                                    ? "d-none"
                                    : "d-block"
                                }
                              >
                                {(parameter.name !== "f" ||
                                  this.state.operation !==
                                    "reserve_transfer") && (
                                  <Label>
                                    {[
                                      "add_minter",
                                      "update_allowance",
                                    ].includes(this.state.operation) &&
                                    parameter.name === "amount"
                                      ? "Allowance amount in EUR-L authorized to mint"
                                      : [
                                          "add_minter",
                                          "remove_minter",
                                          "update_allowance",
                                        ].includes(this.state.operation) &&
                                        parameter.name === "address"
                                      ? "Minter address"
                                      : parameter.name === "value" &&
                                        [
                                          "updateGasFee",
                                          "updateGaslessFee",
                                        ].includes(this.state.operation)
                                      ? "Fee percentage taken on amount (Set 1 for 0.01%, set 100 for 1%)."
                                      : parameter.name === "value" &&
                                        this.state.operation ===
                                          "updateStorageFee"
                                      ? "Fixed fees taken on new address referencing, in 10^6 EURL (Set 100000 for 0.10EURL)"
                                      : parameter.name === "value" &&
                                        this.state.operation ===
                                          "updateThreshold"
                                      ? "Threshold in 10^6 EURL (Set 100000 for 0.10EURL)"
                                      : `${capitalizeFirstLetter(
                                          this.state.operation
                                            .split("_")
                                            .join(" ")
                                            .replace("set ", "")
                                        )} ${
                                          parameter.name &&
                                          capitalizeFirstLetter(parameter.name)
                                        }`}
                                  </Label>
                                )}
                                <Row>
                                  <Col sm="6">
                                    <InputGroup className="mb-2">
                                      {(parameter.name !== "f" ||
                                        this.state.operation !==
                                          "reserve_transfer") && (
                                        <InputGroupAddon addonType="prepend">
                                          <span className="input-group-text">
                                            {parameter.name &&
                                              capitalizeFirstLetter(
                                                parameter.name
                                              )}
                                          </span>
                                        </InputGroupAddon>
                                      )}
                                      {parameter.type === "number" ? (
                                        <>
                                          {parameter.name === "amount" ? (
                                            <>
                                              <input
                                                type="number"
                                                pattern="\S+"
                                                ref={
                                                  this["ref_" + parameter.name]
                                                }
                                                placeholder="Amount in €"
                                                min="1"
                                                className="form-control text-right w-50 mr-0"
                                                defaultValue={
                                                  parameter.name === "amount" &&
                                                  this.state.requestAmount
                                                    ? this.state.requestAmount
                                                    : null
                                                }
                                                onChange={
                                                  this.handleAmountValue
                                                }
                                              />
                                              <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text">
                                                  .
                                                </span>
                                              </InputGroupAddon>
                                              <input
                                                type="number"
                                                ref={this["ref_cents"]}
                                                placeholder="00"
                                                min="0"
                                                max="99"
                                                className="form-control ml-0"
                                                onChange={
                                                  this.handleChangeInput
                                                }
                                              />
                                            </>
                                          ) : (
                                            <input
                                              type="number"
                                              pattern="\S+"
                                              ref={
                                                this["ref_" + parameter.name]
                                              }
                                              placeholder={
                                                parameter.type &&
                                                capitalizeFirstLetter(
                                                  parameter.type
                                                )
                                              }
                                              min="1"
                                              className="form-control"
                                            />
                                          )}
                                        </>
                                      ) : parameter.type === "bool" ? (
                                        <select
                                          ref={this["ref_" + parameter.name]}
                                          className="form-control"
                                        >
                                          <option value="True">True</option>
                                          <option value="False">False</option>
                                        </select>
                                      ) : (
                                        <>
                                          {parameter.name === "f" &&
                                          this.state.operation ===
                                            "reserve_transfer" ? (
                                            <input
                                              type="text"
                                              ref={
                                                this["ref_" + parameter.name]
                                              }
                                              placeholder={
                                                parameter.type &&
                                                capitalizeFirstLetter(
                                                  parameter.type
                                                )
                                              }
                                              defaultValue={
                                                this.state.details.contract
                                              }
                                              className="form-control"
                                              autoComplete="off"
                                              style={{ display: "none" }}
                                            />
                                          ) : parameter.type === "bytes" &&
                                            this.state.operation ===
                                              "set_metadata" ? (
                                            <input
                                              type="text"
                                              ref={
                                                this["ref_" + parameter.name]
                                              }
                                              placeholder="ipfs pointer e.g. ipfs://QmQRdkxeaaxXoxRMxznF1Hc4CK4vUKH3GdoXzeXXJJmR1A"
                                              className="form-control"
                                              autoComplete="off"
                                            />
                                          ) : (
                                            <input
                                              type={parameter.type}
                                              ref={
                                                this["ref_" + parameter.name]
                                              }
                                              placeholder={
                                                parameter.type &&
                                                capitalizeFirstLetter(
                                                  parameter.type
                                                )
                                              }
                                              defaultValue={
                                                parameter.name.replace(
                                                  "_",
                                                  ""
                                                ) === "t" &&
                                                this.state.requestReceiver
                                                  ? this.state.requestReceiver
                                                  : null
                                              }
                                              className="form-control"
                                              autoComplete="off"
                                            />
                                          )}
                                        </>
                                      )}
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            ))}
                            {this.state.passActive === "platform" && (
                              <FormGroup>
                                <Label>Passphrase :</Label>
                                <input
                                  type="password"
                                  ref={this.passPhrase}
                                  className="form-control"
                                />
                              </FormGroup>
                            )}

                            {this.state.error && (
                              <p className="badge badge-danger font-size-12">
                                {this.state.error}
                              </p>
                            )}
                            {this.state.success && (
                              <p className="badge badge-success font-size-12">
                                {this.state.success}
                              </p>
                            )}
                            {this.state.displayResult && (
                              <>
                                <br />
                                <p className="font-size-14 mt-1">
                                  <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                                  Proposal ID{" "}
                                  <b className="font-size-12 mr-1">
                                    {this.state.proposalId}
                                  </b>
                                  for operation{" "}
                                  <b>{this.props.match.params.op}</b> sent from
                                  <b className="ml-1">
                                    {capitalizeFirstLetter(
                                      this.props.match.params.contract
                                    )}
                                  </b>{" "}
                                  {this.state.displayAmount > 0 && (
                                    <span>
                                      of <SVGLogo width="16" height="16" />{" "}
                                      <b>{this.state.displayAmount}</b>
                                    </span>
                                  )}{" "}
                                  with
                                  <b className="ml-1">
                                    {this.state.aliases[
                                      this.state.selectedWallet
                                    ] &&
                                    this.state.aliases[
                                      this.state.selectedWallet
                                    ].name
                                      ? this.state.aliases[
                                          this.state.selectedWallet
                                        ].name
                                      : truncStringPortion(
                                          this.state.selectedWallet,
                                          8,
                                          6
                                        )}
                                  </b>
                                </p>
                              </>
                            )}

                            {this.state.passActive === "platform" && (
                              <>
                                {this.state.pending ? (
                                  <p>Processing operation, please wait...</p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.platformSendOp();
                                      }}
                                    >
                                      Send{" "}
                                      {[
                                        "set_fees_manager",
                                        "set_rights_manager",
                                      ].includes(this.state.operation)
                                        ? "Removal"
                                        : this.state.operation &&
                                          capitalizeFirstLetter(
                                            this.state.operation
                                              .split("_")
                                              .join(" ")
                                          )}{" "}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                            {this.state.passActive === "ledger" && (
                              <>
                                {this.state.pending ? (
                                  <p>
                                    Follow further instructions on your
                                    ledger...
                                  </p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.ledgerSendOp();
                                      }}
                                    >
                                      Send{" "}
                                      {[
                                        "set_fees_manager",
                                        "set_rights_manager",
                                      ].includes(this.state.operation)
                                        ? "Removal"
                                        : this.state.operation &&
                                          capitalizeFirstLetter(
                                            this.state.operation
                                              .split("_")
                                              .join(" ")
                                          )}{" "}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FinancialOperation;
