import { useState } from "react"
import { createContainer } from "unstated-next"

const ProposalsStore = () => {
  const [proposals, setProposals] = useState({
    minter: 0,
    administrator: 0,
    reserve: 0,
    owner: 0,
    lastGet: 0,
  })

  return { 
    proposals, setProposals
  }
}

export default createContainer(ProposalsStore)