import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { MDBDataTable } from 'mdbreact';
import './datatables.scss';
import { getBankOps } from '../../helpers/api';

import { connect } from 'react-redux';

import CSV from '../../components/Common/CSV';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      transactions: {
        columns: [
          {
            label: 'Reference',
            field: 'reference',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'From',
            field: 'fromAccount',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation date',
            field: 'opDate',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Amount',
            field: 'amount',
            sort: 'asc',
            width: 160,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Receiver',
            field: 'receiver',
            sort: 'asc',
            width: 136,
          },
        ],
        rows: [],
      },
      csvRows: [],
      clients: [],
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this._asyncRequest = getBankOps()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let rows = [],
            csvRows = [];
          res.data.operations.forEach((op) => {
            let idbank = res.data.idbanks.find((idbank) =>
              idbank.iban.includes(op.fromAccount),
            );
            let client = idbank
              ? res.data.clients.find(
                  (client) => idbank.publicKeyHash === client.publicKeyHash,
                )
              : null;
            rows.push({
              reference: op.requestField,
              fromAccount: client
                ? `${client.name} (${op.fromAccount})`
                : op.fromAccount,
              opDate: op.opDate.substring(0, 10),
              amount: op.amount + ' ' + op.currency,
              status: (
                <span
                  className={`badge badge-${
                    op.status === 'SUCCESS'
                      ? 'success'
                      : op.status === 'CREATED'
                      ? 'pink'
                      : op.status === 'UNKNOWN'
                      ? 'dark'
                      : 'danger'
                  } font-size-10`}
                >
                  {op.status}
                </span>
              ),
              receiver:
                op.receiver === res.data.lughIban
                  ? `LUGH ${op.receiver}`
                  : op.receiver,
            });
            csvRows.push({
              reference: op.requestField,
              fromAccount: op.fromAccount,
              opDate: op.opDate.substring(0, 10),
              amount: op.amount + ' ' + op.currency,
              status: op.status,
              receiver: op.receiver,
            });
          });
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Reference',
                  field: 'reference',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'From',
                  field: 'fromAccount',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation date',
                  field: 'opDate',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Amount',
                  field: 'amount',
                  sort: 'asc',
                  width: 160,
                },
                {
                  label: 'Status',
                  field: 'status',
                  sort: 'asc',
                  width: 100,
                },
                {
                  label: 'Receiver',
                  field: 'receiver',
                  sort: 'asc',
                  width: 136,
                },
              ],
              rows: rows,
            },
            loading: false,
            csvRows: csvRows,
            clients: res.data.clients,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Statements"
              breadcrumbItem="Bank account operations"
            />
            {!this.state.loading && (
              <>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="p-3"
                        >
                          <TabPane tabId="1" id="all-order">
                            <MDBDataTable
                              responsive
                              bordered
                              data={this.state.transactions}
                              className="mt-1 font-size-11"
                              striped
                              hover
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            ) : (
              <FormGroup>
                <Row className="mr-3">
                  <Col sm="12">
                    <CSV
                      transactions={this.state.transactions}
                      csvRows={this.state.csvRows}
                      name="transactions"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Bank);
