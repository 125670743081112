import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Media, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { fetchUserTx} from "../../helpers/api";
import SVGLogo from '../../components/Common/SVGLogo.js';

import CSV from '../../components/Common/CSV';

class UserTxs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            transactions: [],
            loading: true,
            csvRows: [],
            fees: 0,
            balance: 0,
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount = () => {
        const clientId = this.props.match.params.clientId;
        const uuid = this.props.match.params.uuid;
        this._asyncRequest = fetchUserTx(clientId, uuid).then(
            res => {
                if (res.status === 'SUCCESS'){
                    this.setState({balance: res.data.balance, fees: res.data.fees});
                    let rows = [], csvRows = [];
                    res.data.transactions.forEach(op => {
                        if(op.action)
                            op.operation = op.action
                        rows.push({
                            operationId: op.op_id,
                            operation: <>
                                        {op.operation === 'sell'? 
                                            <span className="badge badge-warning font-size-10">Sell</span>
                                        :
                                            op.operation === 'buy'? 
                                                <span className="badge badge-success font-size-10">Buy</span>
                                            :
                                                <span className="badge badge-dark font-size-10">Transfer</span>
                                        }
                                    </>,
                            date: new Date(op.timestamp * 1000).toISOString(),
                            change: op.operation === 'sell'? 'From EUR-L to ' + op.pair : 'From ' + op.pair + ' to EUR-L',
                            amount: parseFloat(op.tokens.toString().replace(/,/, '.')),
                            user : op.operation === 'transfer'? `${op.sender_id} transferred to ${op.user_id}` : op.user_id,
                            fees: op.fees? parseFloat(op.fees.toString().replace(/,/, '.')) : 0
                        })

                        csvRows.push({
                            operationId: op.op_id,
                            operation: op.operation,
                            date: new Date(op.timestamp * 1000).toISOString(),
                            change: op.pair,
                            amount: op.tokens,
                            user : op.user_id,
                            fees: op.fees? op.fees : 0
                        })
                    });
                    this.setState({transactions: {
                        columns: [
                          {
                            label: "Operation ID",
                            field: "operationId",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Operation",
                            field: "operation",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Date",
                            field: "date",
                            sort: "asc",
                            width: 136
                          },
                          {
                            label: "Trading pair",
                            field: "change",
                            width: 160
                          },
                          {
                            label: "EUR-L amount",
                            field: "amount",
                            sort: "asc",
                            width: 104
                          },
                          {
                            label: "User",
                            field: "user",
                            sort: "asc",
                            width: 100
                          },
                          {
                            label: "Fees (€)",
                            field: "fees",
                            sort: "asc",
                            width: 100
                          }
                        ],
                        rows: rows
                    }, loading: false, csvRows});
                }else{
                    console.log(res.error);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {!this.state.loading &&
                        <>
                            <Row>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Balance</p>
                                                    <h4 className="mb-0"><SVGLogo/> {(Math.round((this.state.balance) * 100) / 100).toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-coin-stack font-size-24"></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Fees</p>
                                                    <h4 className="mb-0">€ {(Math.round((this.state.fees) * 100) / 100).toLocaleString('fr')}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title font-size-24">
                                                        €
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activeTab} className="p-3">
                                                <TabPane tabId="1" id="all-order">
                                                    <h5>UUID : {this.props.match.params.uuid}</h5>
                                                    <MDBDataTable responsive bordered data={this.state.transactions} order={['date', 'desc' ]} className="mt-1 font-size-11"/>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        }
                        {this.state.loading?
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Link>
                            </div>
                        :
                            <FormGroup>
                                <Row className="mr-3">
                                    <Col sm="12">
                                        <CSV transactions={this.state.transactions} csvRows={this.state.csvRows} name="transactions" />
                                    </Col>
                                </Row>
                            </FormGroup>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UserTxs;
