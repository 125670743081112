import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Container, Card, CardBody, CardTitle } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getSpecificNotificationConfig, updateNotificationConfigEvent, updateNotificationConfigEmail } from '../../helpers/api'

const AlertDetails = ({ match }) => {
    const { params: { id: alertId } } = match

    const [notificationConfig, setNotificationConfig] = useState({
        events: [],
        emails: [],
    })
    const [email, setEmail] = useState('')

    const loadConfig = async () => {
        if (alertId) {
            let res = await getSpecificNotificationConfig(alertId)
            if (res.status === 'SUCCESS') {
                setNotificationConfig({
                    ...res.data
                })
            } else {
                console.log(res.error);
            }
        }
    }

    useEffect(() => { loadConfig() }, []) //eslint-disable-line

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs titleLink="/alert" title="Event / Notifications" breadcrumbItem={notificationConfig.name||""} />
                    {/* Render Breadcrumbs */}
                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Platform actions </CardTitle>
                                    <div className="table-responsive">
                                        <table className="table table-nowrap table-centered mb-0">
                                            <tbody>
                                                { notificationConfig && notificationConfig.events && notificationConfig.events.map(nc => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: "60px" }}>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={`${nc.event}`} defaultChecked={nc.active} onChange={(evt) => {
                                                                        let value = evt.target.checked, event = nc.event
                                                                        updateNotificationConfigEvent(alertId, event, value)
                                                                    }} />
                                                                    <label className="custom-control-label" htmlFor={`${nc.event}`}></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{nc.description}</Link></h5>
                                                            </td>
                                                            <td>
                                                                <div className="text-center">
                                                                    <span className="badge badge-pill badge-soft-primary font-size-11">{nc.level}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-2">Email list </CardTitle>
                                    <div className="mb-3" style={{ textAlign: 'right' }}>
                                        <input type="text" className={`${(email !== '' && email.match(/(.+)@(.+){2,}\.(.+){2,}/i)) ? '' : 'error'}`} style={{ width: '100%' }} value={email} onChange={(evt) => {
                                            let value = evt.target.value
                                            setEmail(value)
                                        }} />
                                        <Link to="#" className="btn btn-primary btn-sm w-md" style={{ marginTop: '10px' }} onClick={async () => {
                                            if (email !== '' && email.match(/(.+)@(.+){2,}\.(.+){2,}/i)) {
                                                await updateNotificationConfigEmail(alertId, email, true)
                                                await loadConfig()
                                                setEmail('')
                                            }
                                        }}>+ Add email</Link>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-nowrap table-centered mb-0">
                                            <tbody>
                                                { notificationConfig && notificationConfig.emails && notificationConfig.emails.map(nc => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{nc.email}</Link></h5>
                                                            </td>
                                                            <td>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id={nc.email} defaultChecked={nc.active}  onChange={(evt) => {
                                                                        let value = evt.target.checked, event = nc.email
                                                                        updateNotificationConfigEmail(alertId, event, value)
                                                                    }} />
                                                                    <label className="custom-control-label" htmlFor={nc.email}></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default AlertDetails;