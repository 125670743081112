import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { restoreWallet } from '../../helpers/api';
import { strongRegex } from '../../constants';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const descLimit = 100;
const nameLimit = 40;

class RestoreWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      passPhrase: null,
      description: null,
      mnemonic: null,
      validPhrase: false,
      descCharLeft: descLimit,
      nameCharLeft: nameLimit,
      pending: false,
      error: null,
      activeTab: '1',
      publicKeyHash: null,
      copied: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateValidPhrase = (event) => {
    this.setState({ passPhrase: event.target.value });
    if (strongRegex.test(event.target.value)) {
      this.setState({ validPhrase: true });
    } else {
      this.setState({ validPhrase: false });
    }
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateName = (event) => {
    this.setState({
      nameCharLeft: nameLimit - event.target.value.length,
      name: event.target.value,
    });
  };

  updateMnemonic = (event) => {
    this.setState({ mnemonic: event.target.value });
  };

  restoreWallet = () => {
    if (!this.state.validPhrase)
      this.setState({ error: 'Invalid passphrase!', pending: false });
    else if (this.state.name.length < 4)
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = restoreWallet(
        this.state.name,
        this.state.passPhrase,
        this.state.description,
        this.state.mnemonic,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            window.location = 'wallets';
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Wallet" breadcrumbItem="Restore new Wallet" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-wallet"></i> Restore & save a new
                      wallet on the platform
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="create">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>
                              Set the backup phrase (Mnemonic) composed of
                              several simple english words and separated by a
                              blank space
                            </Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateMnemonic}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a wallet name</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.nameCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateName}
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.name &&
                                    this.state.name.length > 3 ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-message-alt-check"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-message-alt-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a short description</Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.descCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={descLimit}
                                    onChange={this.updateDescription}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Protect the wallet with a passPhrase (min. 8
                              characters, one upper case and one lower case min.
                              and one special character)
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      Passphrase
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    onChange={this.updateValidPhrase}
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.validPhrase ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-check-shield"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-shield-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <div className="text-center mt-4">
                            {this.state.pending ? (
                              <p>Restoring wallet. please wait...</p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.restoreWallet}
                              >
                                Restore new wallet
                              </Button>
                            )}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default RestoreWallet;
